import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import Select from 'react-select'
import {
  getBookingDetailsDataById, getParentAddressById, getParentDogsListById,
  updateBookingById
} from 'Views/Dashboard/Views/Parents/Api'
import EditHomeVisitData from './EditType/homevisit'
import EditBookingLocationComp from './EditType/EditLocationType'
import EditOvernightData from './EditType/overnight'
import EditDaycareData from './EditType/daycare'
import { formatMultiSelectData } from './EditType/helper'


const EditBookingDetailsData = ({ isOpen, handleClose, parentUserId, bookingId }) => {
  const style = { borderRight: '1px solid #ced4da' }

  const [bookingAssets, setBookingAssets] = useState({
    allDogsList: [],
    allAddressList: []
  })
  const [allBookingData, setAllBookingData] = useState()
  const [basicDetails, setBasicDetails] = useState({
    dogs: [],//ids of dogs
    preferred_sitters: [], // ids
    preferred_sitters_text: '',
    available_for_video_call: '',
    service_type: '', // overnight_boarding daycare home_visit
    other_details: '',
    comfortable_splitting_booking: '',
    sitter_caring_for_another_dog: ''
  })

  const [locationsDetail, setLocationsDetail] = useState({
    location_type: '',
    location_type_details: [],
    comfortable_with_sitter_dog: '',
    comfortable_with_sitter_dog_text: '',
    comfortable_with_sitter_cat: '',
    comfortable_with_sitter_cat_text: '',
    comfortable_with_sitter_children: '',
    comfortable_with_sitter_children_text: '',
    parent_address: '',
    manual_address: {
      street_name: '',
      city: '',
      country: '',
      postal_code: '',
      id: ''
    }
  })

  const [daycareData, setDaycareData] = useState({
    daycare_dates: '',
    daycare_dates_flexible: '',
    other_details: ''
  })

  const [overnightData, setOvernightData] = useState({
    overnight_start_date_time: '',
    overnight_end_date_time: '',
    other_details: ''
  })

  const [homeVistData, setHomeVistData] = useState({
    number_of_home_care_visits: '',
    home_care_activities: '',
  })

  const getDataByUserId = (UserId) => {
    try {
      const { data } = getBookingDetailsDataById(UserId)
    } catch (error) {
      console.log(error)
    }
  }
  const formatArrayForSelectLib = (arr) => {
    let updatedArray = []
    for (let i in arr) {
      updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] })
    }
    return updatedArray
  }

  useEffect(() => {
    bookingId.length > 0 && parentUserId > 0 &&
      getDataByUserId(bookingId)
    getBookingDetailsDataById(bookingId).then((res) => {
      const data = res.data.data
      const dogsArr = []
      setAllBookingData(data)
      data.dogs && data.dogs.map((res) => {
        dogsArr.push({ label: res.name, value: res.dog_id })
      })
      setBasicDetails({
        ...basicDetails,
        dogs: dogsArr,
        preferred_sitters: [], // ids
        preferred_sitters_text: data.preferred_sitters_text,
        available_for_video_call: data.available_for_video_call,
        service_type: data.service_type, // overnight_boarding daycare home_visit
        other_details: data.other_details,
        booking_id: data.booking_id,
        parent_id: data.parent_id,
        parent_mobile: data.parent_mobile,
        comfortable_splitting_booking: data.comfortable_splitting_booking,
        sitter_caring_for_another_dog: data.sitter_caring_for_another_dog
      })
      setLocationsDetail({
        ...locationsDetail,
        location_type: data.location_type,
        location_type_details: formatArrayForSelectLib(data.location_type_details),
        comfortable_with_sitter_dog: data.comfortable_with_sitter_dog,
        comfortable_with_sitter_dog_text: data.comfortable_with_sitter_dog_text,
        comfortable_with_sitter_cat: data.comfortable_with_sitter_cat,
        comfortable_with_sitter_cat_text: data.comfortable_with_sitter_cat_text,
        comfortable_with_sitter_children: data.comfortable_with_sitter_children,
        comfortable_with_sitter_children_text: data.comfortable_with_sitter_children_text,
        parent_address: data.parent_address,
        manual_address: {
          street_name: data?.manual_address?.street_name,
          city: data?.manual_address?.city,
          country: data?.manual_address?.country,
          postal_code: data?.manual_address?.postal_code,
          id: data?.manual_address?.id
        }
      })
      {
        data.service_type == 'Daycare' &&
          setDaycareData({
            ...daycareData,
            daycare_dates: data.daycare_dates,
            daycare_dates_flexible: data.daycare_dates_flexible,
            other_details: data.other_details
          })
      }
      {
        data.service_type == 'Overnight Boarding' &&
          setOvernightData({
            ...overnightData,
            overnight_start_date_time: data.overnight_start_date_time,
            overnight_end_date_time: data.overnight_end_date_time,
            other_details: data.other_details
          })
      }
      {
        data.service_type == 'Home Visit' &&
          setHomeVistData({
            ...homeVistData,
            number_of_home_care_visits: data.number_of_home_care_visits,
            home_care_activities: data.home_care_activities,
          })
      }
      getParentDogsListById(parentUserId).then((res) => {
        const dogs = []
        res.data.data && res.data.data.map((res) => {
          dogs.push({ label: res.name, value: res.dog_id })
        })
        setBookingAssets({ ...bookingAssets, allDogsList: dogs })
      })
    })

  }, [])

  const handleUpdateBooking = async() => {
    try {
      const homeVisitObj = { ...basicDetails, ...homeVistData, ...locationsDetail }
      const daycareObj = { ...basicDetails, ...daycareData, ...locationsDetail }
      const overnightObj = { ...basicDetails, ...overnightData, ...locationsDetail }
      basicDetails.service_type == 'Daycare' && await updateBookingById({
        ...daycareObj,
        dogs: formatMultiSelectData(basicDetails.dogs),
        parent_address: locationsDetail.parent_address.id || locationsDetail.parent_address,
        manual_address: {
          ...locationsDetail.manual_address,
          country: locationsDetail.manual_address.country
        }
      }, bookingId)
      basicDetails.service_type == 'Home Visit' && await updateBookingById({
        ...homeVisitObj,
        dogs: formatMultiSelectData(basicDetails.dogs),
        parent_address: locationsDetail.parent_address.id,
        manual_address: {
          ...locationsDetail.manual_address,
          country: locationsDetail.manual_address.country
        }
      }, bookingId)
      basicDetails.service_type == 'Overnight Boarding' && await updateBookingById({
        ...overnightObj,
        dogs: formatMultiSelectData(basicDetails.dogs),
        overnight_start_date_time: 
          `${overnightData.overnight_start_date_time.date}T${overnightData.overnight_start_date_time.time}`,
        overnight_end_date_time: `${overnightData.overnight_end_date_time.date}T${overnightData.overnight_end_date_time.time}`,
        parent_address: locationsDetail.parent_address.id,
        manual_address: {
          ...locationsDetail.manual_address,
          country: locationsDetail.manual_address.country
        }
      }, bookingId)
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>Edit Booking Details</ModalHeader>
      <ModalBody>
        <Row className='p-2' style={{ background: '#c3cfd9' }}>
          <Col lg='6'>
            <h4 className='mb-2' style={{ color: '#e8833a', fontWeight: 500 }}>Dog Parent Details </h4>
            <p className='m-0'>Name: <span className='font-bold'>{allBookingData?.parent}</span></p>
            <p className='m-0'>Id: <span className='font-bold'>{allBookingData?.parent_id}</span></p>
            <p className='m-0'>Email: <span className='font-bold'>{allBookingData?.parent_details?.email}</span></p>
            <p className='m-0'>Phone Number:
              <span className='font-bold'>{allBookingData?.parent_details?.mobile}</span></p>
            <p className='m-0'>Gender: <span className='font-bold'>{allBookingData?.parent_details?.gender}</span></p>
            <p className='m-0'>Language:
              <span className='font-bold'>{allBookingData?.parent_details?.preferred_languages}</span></p>
            <p className='m-0'>Whatsapp No:
              <span className='font-bold'>{allBookingData?.parent_details?.whatsapp_number}</span></p>
          </Col>

          <Col lg='6'>
            <h4 className='mb-2' style={{ color: '#e8833a', fontWeight: 500 }}>Dog Parent Details </h4>
            {allBookingData?.dogs && allBookingData?.dogs.map((item, index) => {
              return (
                <div className='mb-3' key={index}>
                  <p className='mb-1'>Dog #{index + 1}</p>
                  <p className='m-0'>Name: <span className='font-bold'>{item.name}</span></p>
                  <p className='m-0'>Gender: <span className='font-bold'>{item.gender}</span></p>
                  <p className='m-0'>Breed: <span className='font-bold'>{item.breed}</span></p>
                  <p className='m-0'>DOB: <span className='font-bold'>{item.date_of_birth}</span></p>
                  <p className='m-0'>Size Category: <span className='font-bold'>{item.size_category}</span></p>
                </div>
              )
            })
            }
          </Col>
        </Row>
        <Row>
          <h4 className='mb-2'>Booking Details :-</h4>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Which dog is this request is for?</Label>
              <Select className='select-font' isMulti placeholder='Select' options={bookingAssets.allDogsList}
                value={basicDetails.dogs} onChange={(value) => setBasicDetails({ ...basicDetails, dogs: value })}
              />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>List of dog sitters you have booked with</Label>
              <Input value={basicDetails.preferred_sitters} type="select" className="form-control">
                <option selected disabled>Select</option>
                <option disabled>No sitter</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Sitter Names</Label>
              <Input value={basicDetails.preferred_sitters_text} type="text" className="form-control"
                onChange={(e) => setBasicDetails({ ...basicDetails, preferred_sitters_text: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Would you be comfortable with a video call instead of a meeting?</Label>
              <Input value={basicDetails.available_for_video_call} type="select" className="form-control"
                onChange={(e) => setBasicDetails({ ...basicDetails, available_for_video_call: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>comfortable splitting your booking across two different dog sitters?</Label>
              <Input value={basicDetails.comfortable_splitting_booking} type="select" className="form-control"
                onChange={(e) => setBasicDetails({ ...basicDetails, comfortable_splitting_booking: e.target.value })}>
                <option selected disabled value="">Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is it OK if the dog sitter is caring for another dog on some of your dates?</Label>
              <Input value={basicDetails.sitter_caring_for_another_dog} type="select" className="form-control"
                onChange={(e) => setBasicDetails({ ...basicDetails, sitter_caring_for_another_dog: e.target.value })}>
                <option selected disabled value="">Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <h5 className='mb-4'>Service Type: {basicDetails.service_type}</h5>

        {basicDetails.service_type == 'Daycare' && daycareData.daycare_dates.length > 0 &&
          <EditDaycareData data={daycareData} updatedData={(res) => setDaycareData(res)} />}

        {basicDetails.service_type == 'Home Visit' && homeVistData.number_of_home_care_visits !== '' &&
          <EditHomeVisitData homeVistData={homeVistData} updatedData={(res) => setHomeVistData(res)} />}

        {basicDetails.service_type == 'Overnight Boarding' &&
          <EditOvernightData data={overnightData} updatedData={(res) => setOvernightData(res)} />}


        {locationsDetail.location_type != '' &&
          <EditBookingLocationComp locationsDetails={locationsDetail} parentUserId={parentUserId}
            updatedData={(res) => setLocationsDetail(res)} />}


      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={handleUpdateBooking} color="primary" className="waves-effect">Update</Button>
        <Button type="button" onClick={handleClose} color="light" className="waves-effect">Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditBookingDetailsData
