import { updatePaymentConfigListData } from 'Views/Dashboard/Views/Payments/Api'
import { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

const EditOtherConfigData = ({ isOpen, configDataList, handleClose, type, isViewModeEnable, reRenderList }) => {
  const [configData, setConfigData] = useState(configDataList)
  const [isDisabled, setIsDisabled] = useState(isViewModeEnable)

  const style = {
    borderRight: '1px solid #ced4da',
  }

  const handleChangeInput = (e) => {
    setConfigData({ ...configData, [e.target.name]: e.target.value })
  }

  const handleChangeSwitchInput = (e) => {
    setConfigData({ ...configData, [e.target.name]: e.target.checked })
  }

  const handleUpdate = () => {
    updatePaymentConfigListData(configData).then(() => {
      handleClose()
      reRenderList()
    })
  }

  console.log(configData,'configData')

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>Payment Config</ModalHeader>
      <ModalBody>
        <Row>
          {type == 'commission' && (
            <>
              <Col lg="6">
                <FormGroup>
                  <Label>Commision % on all bookings</Label>
                  <Input
                    value={configData?.commission_percentage}
                    type="number"
                    className="form-control"
                    name="commission_percentage"
                    onChange={handleChangeInput}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
            </>
          )}

          {type == 'automatic discount' && (
            <>
              <Col lg="6" style={style}>
                <FormGroup>
                  <Label>{'Days for Automatic Discount Overnight (> than)'}</Label>
                  <Input
                    value={configData?.min_days_for_auto_discount_overnight}
                    type="number"
                    className="form-control"
                    name="min_days_for_auto_discount_overnight"
                    onChange={handleChangeInput}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" style={style}>
                <FormGroup>
                  <Label>{'Days for Automatic Discount Daycare (> than)'}</Label>
                  <Input
                    value={configData?.min_days_for_auto_discount_daycare}
                    type="number"
                    className="form-control"
                    name="min_days_for_auto_discount_daycare"
                    onChange={handleChangeInput}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" style={style}>
                <FormGroup>
                  <Label>% of Automatic Discount Overnight</Label>
                  <Input
                    value={configData?.auto_discount_overnight}
                    type="number"
                    className="form-control"
                    name="auto_discount_overnight"
                    onChange={handleChangeInput}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
              
              <Col lg="6" style={style}>
                <FormGroup>
                  <Label>% of Automatic Discount Daycare</Label>
                  <Input
                    value={configData?.auto_discount_daycare}
                    type="number"
                    className="form-control"
                    name="auto_discount_daycare"
                    onChange={handleChangeInput}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
            </>
          )}

          {type == 'vat' && (
            <>
              <Col lg="6" style={style}>
                <FormGroup switch>
                  <Label>OFF/ON</Label>
                  <Input
                    checked={configData.vat_enabled}
                    type="switch"
                    className="form-control"
                    name="vat_enabled"
                    onChange={handleChangeSwitchInput}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" style={style}>
                <FormGroup>
                  <Label>% VAT to apply</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name="vat_percentage"
                    onChange={handleChangeInput}
                    value={configData.vat_percentage}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
            </>
          )}

          {type == 'platform fee' && (
            <>
              <Col lg="6" style={style}>
                <FormGroup switch>
                  <Label>OFF/ON</Label>
                  <Input
                    checked={configData.platform_fee_enabled}
                    type="switch"
                    className="form-control"
                    name="platform_fee_enabled"
                    onChange={handleChangeSwitchInput}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" style={style}>
                <FormGroup>
                  <Label>Platform fee per booking</Label>
                  <Input
                    value={configData?.platform_fee_per_booking}
                    type="number"
                    className="form-control"
                    name="platform_fee_per_booking"
                    onChange={handleChangeInput}
                    disabled={isDisabled}
                  />
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        {!isViewModeEnable && (
          <Button
            type="button"
            onClick={handleUpdate}
            color="light"
            className="waves-effect"
          >
            Update
          </Button>
        )}
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditOtherConfigData
