import React from 'react'
import Select from 'react-select'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  NativeSelect,
  Typography,
  FormControl,
  TextField
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import countryList from 'react-select-country-list'
import { Col, FormGroup, Input, Label } from 'reactstrap'

const AddressModule = ({ data, incomingAddressData }) => {
  const [differentAddress, setdifferentAddress] = useState({
    sitterLocation: { location: '', checked: false },
    parentLocation: { location: '', checked: false },
    otherLocation: { location: '', checked: false },
  })
  const { sitterLocation, parentLocation, otherLocation } = differentAddress
  const [selectedAddressData, setSelectedAddressData] = useState({
    location_type: [], //parent_location, sitter_location, other_address,
    parent_address: '', //id of address
    manual_address: {
      street_name: null,
      city: null,
      country: null,
      postal_code: null,
    },
        comfortable_with_sitter_dog: '',
        comfortable_with_sitter_cat: '',
        comfortable_with_sitter_children: '',
        comfortable_with_sitter_dog_text:'',
        comfortable_with_sitter_cat_text:'',
        comfortable_with_sitter_children_text:'',
    
  })
  const countryOptions = useMemo(() => countryList().getData(), [])
  const [showAlertTxt, setShowAlertTxt] = useState(false)
  const [isDisabled, setIsDisabled] = useState({
    homeAddress: false,
    otherAddress: false,
  })

  useEffect(() => {
    data(selectedAddressData)
  }, [selectedAddressData])

  useEffect(() => {
    !sitterLocation.checked && !parentLocation.checked && !otherLocation.checked
      ? setShowAlertTxt(true)
      : setShowAlertTxt(false)
  }, [differentAddress])

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <Typography htmlFor="hear_from_source">
            At which location would you like your dog to be cared for?{' '}
            <span style={{ color: 'red', fontSize: '20px' }}>*</span>
          </Typography>
          <Box className="location-options">
            <FormControlLabel
              label="At dog sitter`s home"
              control={
                <Checkbox
                  checked={differentAddress.sitterLocation.checked}
                  onChange={() => {
                    const isChecked = !differentAddress.sitterLocation.checked;
                  
                    setdifferentAddress({
                      ...differentAddress,
                      sitterLocation: {
                        location: 'sitter_location',
                        checked: isChecked,
                      },
                    });
                  
                    setSelectedAddressData((prev) => ({
                      ...prev,
                      location_type: isChecked
                        ? [...prev.location_type, 'sitter_location']
                        : prev.location_type.filter((value) => value !== 'sitter_location'),
                      // Reset the related field when unchecked
                      address: isChecked ? prev.address : '', 
                    }));
                  }}
                  
                  sx={{
                    color: '#fe7648',
                    '&.Mui-checked': { color: '#fe7648' },
                  }}
                />
              }
            />
            <FormControlLabel
              label="At my home address"
              control={
                <Checkbox
                  checked={differentAddress.parentLocation.checked}
                  disabled={isDisabled.homeAddress}
                  onChange={() => {
                    const isChecked = !differentAddress.parentLocation.checked;

                    setdifferentAddress({
                      ...differentAddress,
                      parentLocation: {
                        location: 'parent_location',
                        checked: isChecked,
                      },
                    });

                    setIsDisabled({
    ...isDisabled,
    otherAddress: isChecked,
  });

  setSelectedAddressData((prev) => ({
    ...prev,
    location_type: isChecked
      ? [...prev.location_type, 'parent_location']
      : prev.location_type.filter((value) => value !== 'parent_location'),
    parent_address: isChecked ? prev.parent_address : '', // Reset parent_address if unchecked
  }));
                  }}
                  sx={{
                    color: '#fe7648',
                    '&.Mui-checked': { color: '#fe7648' },
                  }}
                />
              }
            />
            <FormControlLabel
              label="At a different address"
              control={
                <Checkbox
                  checked={differentAddress.otherLocation.checked}
                  disabled={isDisabled.otherAddress}
                  onChange={() => {
                    const isChecked = !differentAddress.otherLocation.checked;
                  
                    setdifferentAddress({
                      ...differentAddress,
                      otherLocation: {
                        location: 'other_address',
                        checked: isChecked,
                      },
                    });
                  
                    setIsDisabled({
                      ...isDisabled,
                      homeAddress: isChecked,
                    });
                  
                    setSelectedAddressData((prev) => ({
                      ...prev,
                      location_type: isChecked
                        ? [...prev.location_type, 'other_address']
                        : prev.location_type.filter((value) => value !== 'other_address'),
                      // Reset manual_address instead of address when unchecked
                      manual_address: isChecked ? prev.manual_address : '', 
                    }));
                  }}
                  
                  sx={{
                    color: '#fe7648',
                    '&.Mui-checked': { color: '#fe7648' },
                  }}
                />
              }
            />
          </Box>
          {selectedAddressData.location_type.includes('sitter_location') && (
            <Col >
              <Col  >
                <FormGroup>     
                  <Label>Is it OK if the sitter&apos;s home has a dog?</Label>  
                            <span style={{ color: 'red', fontSize: '20px' }}>*</span>

                  <Input type="select" className='form-control'
                  value={selectedAddressData.comfortable_with_sitter_dog}
                  onChange={(e) =>
                        setSelectedAddressData((prev) => ({
                          ...prev,
                          comfortable_with_sitter_dog: e.target.value,
                        }))
                      }
                  >
                    <option hidden>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Maybe'>Maybe</option>
                  </Input>
                </FormGroup>
              </Col>
              {selectedAddressData.comfortable_with_sitter_dog == 'Maybe' &&(
              <Col >
            <FormGroup>
              <Label>Please share additional details here:</Label>
              <Input
                type="text"
                placeholder="Enter Details"
                value={selectedAddressData.comfortable_with_sitter_dog_text}
                onChange={(e) => {setSelectedAddressData((prev) => ({
                        ...prev,
                        comfortable_with_sitter_dog_text: e.target.value,
                      }))}}
                className="form-control"
              />
            </FormGroup>
          </Col>)}
              <Col  >
                <FormGroup>     
                  <Label>Is it OK if the sitter&apos;s home has a cat?</Label>
                  <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                  <Input type="select" className='form-control'   
                  value={selectedAddressData.comfortable_with_sitter_cat}
                  onChange={(e) =>
                        setSelectedAddressData((prev) => ({
                          ...prev,
                          comfortable_with_sitter_cat: e.target.value,
                        }))
                      }>
                    <option hidden>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Maybe'>Maybe</option>
                  </Input>
                </FormGroup>
              </Col>
              {selectedAddressData.comfortable_with_sitter_cat == 'Maybe' &&(
              <Col >
            <FormGroup>
              <Label>Please share additional details here:</Label>
              <Input
                type="text"
                placeholder="Enter Details"
                value={selectedAddressData.comfortable_with_sitter_cat_text}
                onChange={(e) => {setSelectedAddressData((prev) => ({
                        ...prev,
                        comfortable_with_sitter_cat_text: e.target.value,
                      }))}}
                className="form-control"
              />
            </FormGroup>
          </Col>)}
              <Col>
                <FormGroup>
                  <Label>Is it OK if the sitter&apos;s home has a children?</Label>
                  <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                  <Input type="select" className='form-control'
                    value={selectedAddressData.comfortable_with_sitter_children}
                    onChange={(e) =>
                          setSelectedAddressData((prev) => ({
                            ...prev,
                            comfortable_with_sitter_children: e.target.value,
                          }))
                        }
                  >
                    <option hidden>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Maybe'>Maybe</option>
                  </Input>
                </FormGroup>
              </Col>
              {selectedAddressData.comfortable_with_sitter_children == 'Maybe' &&(
              <Col >
            <FormGroup>
              <Label>Please share additional details here:</Label>
              <Input
                type="text"
                placeholder="Enter Details"
                value={selectedAddressData.comfortable_with_sitter_children_text}
                onChange={(e) => {setSelectedAddressData((prev) => ({
                        ...prev,
                        comfortable_with_sitter_children_text: e.target.value,
                      }))}}
                className="form-control"
              />
            </FormGroup>
          </Col>)}
            </Col>
          )
          }
          {showAlertTxt && (
            <p
              style={{
                fontFamily: 'Brandon',
                fontSize: '14px',
                color: 'red',
                margin: 0,
              }}
            >
              Please select the location type
            </p>
          )}
        </FormControl>
      </Grid>
      {differentAddress.parentLocation.checked &&
        differentAddress.parentLocation.location == 'parent_location' && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <Typography shrink="true">Parent addresses                     
              <span style={{ color: 'red', fontSize: '20px' }}>*</span>
            </Typography>    
            <NativeSelect
              disableUnderline
              defaultValue=""
              inputProps={{
                style: { fontFamily: 'Brandon', padding: '8.5px 14px' },
              }}
              onChange={(e) => {
                setSelectedAddressData({
                  ...selectedAddressData,
                  parent_address: e.target.value,
                })
              }}
              sx={{
                border: '1px solid #ced4d9',
                borderRadius: '3px',
                marginTop: '5px',
              }}
            >
              <option selected disabled value="">
                Select
              </option>
              {incomingAddressData && incomingAddressData.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.street_name +
                      ' ' +
                      item.city +
                      ' ' +
                      item.country +
                      ' ' +
                      item.postal_code}
                  </option>
                )
              })}
            </NativeSelect>
          </FormControl>
        </Grid>
      )}
      {differentAddress.otherLocation.checked &&
        differentAddress.otherLocation.location == 'other_address' && (
        <Grid container xs={12} sx={{ gap: '10px' }}>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth variant="standard">
              <Typography shrink="true" htmlFor="date_of_birth">
                Street Name                         <span style={{ color: 'red', fontSize: '20px' }}>*</span>

              </Typography>  

              <TextField
                type="text"
                size="small"
                value={selectedAddressData.manual_address.street_name}
                onChange={(e) =>
                  setSelectedAddressData({
                    ...selectedAddressData,
                    manual_address: {
                      ...selectedAddressData.manual_address,
                      street_name: e.target.value,
                    },
                  })
                }
                placeholder="Enter Street Name"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth variant="standard">
              <Typography shrink="true" htmlFor="date_of_birth">
                City               <span style={{ color: 'red', fontSize: '20px' }}>*</span>

              </Typography>

              <TextField
                type="text"
                size="small"
                value={selectedAddressData.manual_address.city}
                onChange={(e) =>
                  setSelectedAddressData({
                    ...selectedAddressData,
                    manual_address: {
                      ...selectedAddressData.manual_address,
                      city: e.target.value,
                    },
                  })
                }
                placeholder="Enter City"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth variant="standard">
              <Typography shrink="true" htmlFor="date_of_birth">
                Postal Code               <span style={{ color: 'red', fontSize: '20px' }}>*</span>

              </Typography>
              <TextField
                type="text"
                size="small"
                value={selectedAddressData.manual_address.postal_code}
                onChange={(e) =>
                  setSelectedAddressData({
                    ...selectedAddressData,
                    manual_address: {
                      ...selectedAddressData.manual_address,
                      postal_code: e.target.value,
                    },
                  })
                }
                placeholder="Enter Postal Code"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth variant="standard">
              <Typography
                shrink="true"
                htmlFor="date_of_birth"
                sx={{ marginBottom: '5px' }}
              >
                Country              <span style={{ color: 'red', fontSize: '20px' }}>*</span>

              </Typography>
              <Select
                className="select-font"
                options={countryOptions}
                // value={selectedAddressData.manual_address.country}
                onChange={(e) =>
                  setSelectedAddressData({
                    ...selectedAddressData,
                    manual_address: {
                      ...selectedAddressData.manual_address,
                      country: e.label,
                    },
                  })
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default AddressModule
