import { useEffect, useState } from 'react'
import MeetingList from 'Components/MeetingList/index.jsx'
import { cancelMeetingData, getUsersMeetingsListData, updateMeetingData } from '../../Api'
import { meetingListTableHeading } from '../../tableCol'
import EditMeetingData from 'Components/EditMeetingDetails'
import SweetAlert from 'react-bootstrap-sweetalert'
import SitterStatus from 'Components/statusNote'


const UsersMeetingList = () => {
  const [usersList, setUsersList] = useState([])
  const [searchData, setSearchData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [bookingId, setBookingId] = useState({ id: '', bookingIntId: '', bookingStatus: '' })
  const [meetingData, setMeetingData] = useState([])
  const [isCsvLoaded, setIsCsvLoaded] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const [deleteMeetingDialog, setDeleteMeetingDialog] = useState(false)
  const [meetingId, setMeetingId] = useState('')
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [status, setStatus] = useState('')
  const [upadtedStatus, setUpadtedStatus] = useState('')
  const { currentPage, totalSize } = paginationValue
  const [filteredData, setFilteredData] = useState({
    count: 0,
    pageSize: ''
  })
  const { count, pageSize } = filteredData

  const handleGetMeetingData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUsersMeetingsListData(queryParams)
      setUsersList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })
      setFilteredData({ ...filteredData, count: data.data.count })
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetMeetingData({ page: 1, search: '', status: '', page_size: '' })
  }, [])



  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    handleGetMeetingData({
      page: page,
      search: searchData,
      page_size: pageSize,
      status: status
    })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  
  const handleSearch = (e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleGetMeetingData({
      page: 1,
      search: val,
      page_size: pageSize,
      status: status
    })
    setIsCsvLoaded(false)
  }
  const handleEditMeetingingData = (id, bookingIntId, status, data) => {
    setMeetingData(data)
    setBookingId({ ...bookingId, id: id, bookingIntId: bookingIntId, bookingStatus: status  })
    setIsEditMode(true)
  }

  const handleCancelMeetingingData = () => {
    cancelMeetingData(meetingId).then((res) => {
      res.data.response_code === 80 && (
        handleGetMeetingData({ page: 1, search: '', status: '' }),
        setDeleteMeetingDialog(false)
      )
    })
  }

  const handleChangeStatus = (e) => {
    setStatus(e.target.value)
    handleGetMeetingData({ page: 1, search: '', status: e.target.value })
  }

  const handleClear = () => {
    handleGetMeetingData({ page: 1, search: '', status: '' })
    setStatus('')
    setSearchData('')
  }

  const handleUpdateStatusReq = () => {
    updateMeetingData({ 'notes': upadtedStatus }, bookingId).then((res) => {
      res.data.response_code == 80 && setIsNotesModalOpen(false), setUpadtedStatus(''),
      handleGetMeetingData({ page: 1, search: '', status: '' })
    })
  }

  const handleUpdateStatus = (list) => {
    setBookingId(list.id)
    setUpadtedStatus(list.notes)
    setIsNotesModalOpen(true)
  }

  const filterCsvData = (csvData) => {
    const data = csvData.map((obj) => ({
      BookingID: obj.booking_id, ParentName: obj.parent,
      SitterName: obj.sitter, MeetingDate: obj.meeting_date, MeetingTime: obj.meeting_time,
      location: obj.location_type, MeetingAddress: obj.meeting_location,
      ServiceType: obj.service_type, Status: obj.status }))
    return data
  }

  const handleDownloadCsvData = () => {
    getUsersMeetingsListData({
      page:1 ,
      search: searchData,
      status: status,
      page_size: 200
    }).then((res) => {if(res.data.response_code == 80){
      // setCsvData(res.data.results)
      setIsCsvLoaded(true)
    }})
  }
 
  return(
    <>
      <MeetingList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={usersList}
        tableHeading={meetingListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        searchData={searchData}
        handleSearch={handleSearch}
        handleEditMeetingingData={handleEditMeetingingData}
        handleUpdateStatus={handleUpdateStatus}
        handleCancelMeetingingData={(id, bookingId) => {
          setMeetingId(id)
          setDeleteMeetingDialog(true)
        }}
        handleChangeStatus={handleChangeStatus}
        handleClear={handleClear}
        csvData={filterCsvData(usersList)}
        isCsvLoaded={isCsvLoaded}
        handleCallDataForCsv={handleDownloadCsvData}
        statusValue={status}
      />

      {isEditMode && <EditMeetingData
        data={meetingData}
        isOpen={isEditMode}
        handleClose={() => setIsEditMode(false)}
        bookingIntId={bookingId.bookingIntId}
        id={bookingId.id}
        bookingStatus={bookingId.bookingStatus}
        reRenderPage={() => handleGetMeetingData({ page: 1, search: '', status: '' })}
      />}

      {isNotesModalOpen && <SitterStatus
        title={'Meeting Notes'}
        isOpen={isNotesModalOpen}
        sitterStatus={upadtedStatus}
        handleChangeStatus={(e) => setUpadtedStatus(e.target.value)}
        handleUpdateStatusReq={handleUpdateStatusReq}
        handleClose={() => {setIsNotesModalOpen(false), setUpadtedStatus('')}}
      />}
      
      {deleteMeetingDialog &&
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={handleCancelMeetingingData}
          onCancel={() => setDeleteMeetingDialog(false)}
        ></SweetAlert>}
    </>
  )

}

export default UsersMeetingList