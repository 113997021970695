import { useEffect, useRef, useState } from 'react'
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import DatePicker from 'react-multi-date-picker'
import ConfirmationBookingModal from '../ConfirmModal'
import ConfirmDaycareBookingModal from '../ConfirmModal/daycare'

const UpdateDaycareData = ({ data, updatedData, handleBookingDetailsDataById, closeModal }) => {
  console.log(data, 'datadatadatadatadata')
  const style = { borderRight: '1px solid #ced4da' }
  const ref = useRef()
  const [daycareData, setDaycareData] = useState({
    daycare_dates: data.daycare_dates,
  })
  const [dayCareSelectDates, setDayCareSelectDates] = useState([])
  const [modifiedDaycareData, setModifiedDaycareData] = useState([...data.daycare_dates])
  const [daycareDataDaysTime, setDaycareDaysTime] = useState(0)
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    type: ''
  })

  useEffect(() => {
    const convertDate = data.daycare_dates.map((value) => value.date)
    setDayCareSelectDates([ ...dayCareSelectDates, ...convertDate ])
    // setModifiedDaycareData([ ...modifiedDaycareData, ...data.daycare_dates ])
  }, [])


  const calculateTimeDifference = (startTime, endTime) => {
    if (startTime && endTime) {
      const [hours1, minutes1] = startTime.split(':').map(Number)
      const [hours2, minutes2] = endTime.split(':').map(Number)
      const totalMinutes1 = hours1 * 60 + minutes1
      const totalMinutes2 = hours2 * 60 + minutes2
      let timeDifference = totalMinutes2 - totalMinutes1
      const hoursDifference = Math.floor(timeDifference / 60)
      const minutesDifference = timeDifference % 60
      return `${hoursDifference}:${minutesDifference >= 10 ? minutesDifference : `0${minutesDifference}`}`
    } else return
  }

  useEffect(() => {
    setDaycareData({ ...daycareData, daycare_dates: modifiedDaycareData })
  }, [modifiedDaycareData])

  useEffect(() => {
    updatedData(daycareData)
  }, [daycareData])

  const dayType = []


  useEffect(() => {
    modifiedDaycareData.map((item) => {
      if(item.day_type !== ''){
        dayType.push(item.day_type)
      }
      const convertedArray = dayType.map(day => {
        if (day === 'Half Day') {return 0.5}
        else if (day === 'Full Day') {return 1}
        else if (day === 'One Half') {return 1.5}
        return day
      })
      let totalDaysSum = 0
      convertedArray.forEach( num => {totalDaysSum += num})
      setDaycareDaysTime(totalDaysSum)
      return
    })
  }, [modifiedDaycareData])


  const handledDayCareMultipleDates = (data) => {
    let mappedData = []
    const convertDate = data.map(value => 
        `${value.year}-${String(value.month).padStart(2, '0')}-${String(value.day).padStart(2, '0')}`
    );
    const newDateObject = convertDate.filter(date => !modifiedDaycareData.some(item => item.date === date));
    const filteredArr = modifiedDaycareData.filter((item) => convertDate.includes(item.date));
    
    newDateObject.forEach((item) => {
        mappedData.push({
            date: item,
            startTime: '',
            endTime: '',
            totalHours: '',
            day_type: ''
        });
    });
    
    setDayCareSelectDates(convertDate);
    setModifiedDaycareData(mappedData.length > 0 
        ? [...modifiedDaycareData, ...mappedData] 
        : filteredArr);
}


  const calculateHoursToMins = (hours) => {
    if (hours) {
      const [hour, minutes] = hours.split(':').map(Number)
      const totalMinutes = hour * 60 + minutes
      return totalMinutes
    }
  }  

  return (
    <>
      <Col lg='4'>
        <FormGroup>
          <Label>Select new dates</Label>
          <DatePicker ref={ref} className='d-block' multiple dateFormat="YYYY-MM-DD" shouldCloseOnSelect={true}
            value={dayCareSelectDates}
            onChange={handledDayCareMultipleDates}
            minDate={new Date()}
          >
            <Button className='py-1' style={{ position: 'relative', bottom: '5px' }}
              color='primary' onClick={() => ref.current.closeCalendar()}>
              close
            </Button>
          </DatePicker>
        </FormGroup>
      </Col>

      {modifiedDaycareData.map((item, index) => {
        return (
          <Row key={index} className='align-items-center border rounded m-1 p-1 bg-light'>
            <Col lg='3'>
              <FormGroup>
                <Label>Date</Label>
                <Input type="date" className="form-control"
                  disabled
                  value={item.date}
                />
              </FormGroup>
            </Col>

            <Col lg='2'>
              <FormGroup>
                <Label>Start Time</Label>
                <Input type="time" className="form-control"
                  onChange={(time) => {
                    const updatedData = {
                      ...item, start_time: time.target.value,
                      total_hours: calculateTimeDifference(time.target.value, item.end_time)
                    }
                    setModifiedDaycareData((prevData) => {
                      const newData = [...prevData]
                      newData[index] = updatedData
                      return newData
                    })
                  }}
                  value={item.start_time}
                />
              </FormGroup>
            </Col>

            <Col lg='2'>
              <FormGroup>
                <Label>End Time</Label>
                <Input type="time" className="form-control"
                  onChange={(time) => {
                    const updatedData = {
                      ...item, end_time: time.target.value,
                      total_hours: calculateTimeDifference(item.start_time, time.target.value),
                      day_type: calculateHoursToMins(calculateTimeDifference(item.start_time, time.target.value)) == 0 ? '' :
                        calculateHoursToMins(calculateTimeDifference(item.start_time, time.target.value)) > 240 ?
                          'Full Day' : 'Half Day'
                    }
                    setModifiedDaycareData((prevData) => {
                      const newData = [...prevData]
                      newData[index] = updatedData
                      return newData
                    })
                  }}
                  value={item.end_time}
                />
              </FormGroup>
            </Col>

            <Col lg='2'>
              <FormGroup>
                <Label>Time Diff</Label>
                <Input type="text" className="form-control"
                  value={item.total_hours}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col lg='2'>
              <FormGroup>
                <Label>Day Type</Label>
                <Input type="text" className="form-control"
                  value={item.day_type}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col lg='1'>
              <i className="ri-delete-bin-3-fill text-danger h4 cursor-pointer me-2"
                onClick={() => {
                  const calenderItems = [...dayCareSelectDates]
                  const modifyItems = [...modifiedDaycareData]
                  calenderItems.splice(index, 1)
                  modifyItems.splice(index, 1)
                  setModifiedDaycareData(modifyItems)
                  setDayCareSelectDates(calenderItems)
                }}
              />
            </Col>
          </Row>
        )
      })}
      <p className='font-bold text-end'>Total days: {daycareDataDaysTime}</p>

      <Row className='mb-5'>
        <Col lg='3'>
          <p className='m-0'>No. of days:</p>
          <p className='font-bold m-0'>{data.total_day_count || '-'}</p>
        </Col>
        <Col lg='3'>
          <p className='m-0'>Change(In days):</p>
          <p className='font-bold m-0'>{daycareDataDaysTime - data.total_day_count || '-'}</p>
        </Col>
      </Row>

      <Row className='mb-5'>
        <Col lg='2'>
          <Button type="button" color="primary" className="waves-effect"
            onClick={() =>setConfirmationModal({ ...confirmationModal, isOpen: !confirmationModal.isOpen,
              type: 'Additional Invoice' })}
          >Additional Invoice</Button> 
        </Col>
        <Col lg='2'>
          <Button type="button" color="primary" className="waves-effect"
            onClick={() =>setConfirmationModal({ ...confirmationModal, isOpen: !confirmationModal.isOpen, type: 'Refund' })}
          >Issue Refund</Button>
        </Col>
      </Row>

      <ConfirmDaycareBookingModal
        isOpen={confirmationModal.isOpen}
        type={confirmationModal.type}
        bookingId={data.id}
        closeModal={() => closeModal()}
        handleBookingDetailsDataById={() => handleBookingDetailsDataById()}
        handleClose={() => setConfirmationModal({ ...confirmationModal, isOpen: !confirmationModal.isOpen })}
      />
    </>
  )
}

export default UpdateDaycareData