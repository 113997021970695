import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input, FormGroup, Button } from 'reactstrap'
import { updateSitterInforeq } from 'Views/Dashboard/Views/Users/Users.Apis'
import CsvDownloadButton from 'react-json-to-csv'
import Table from '../Table'
import { userRole } from 'Utils/helper'

const RegisteredSitterList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleViewSitterData,
  handleEditSitterData,
  handleUpdloadSitterDoc,
  handleRejectSitter,
  handleViewSitterStatus,
  ViewCalender,
  sendNotification,
  handleSearch,
  searchData,
  totalCount,
  handleFirstCalenderBooked,
  is_first_calender_booked,
  is_inactive,
  handleIsActive,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  csvData,
  isCsvLoaded,
  handleCallDataForCsv
}) => {
  const [rowData, setRowData] = useState([])

  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row.user_id}>
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <i
          className="ri-eye-line text-info h4 cursor-pointer me-2"
          onClick={() => handleViewSitterData(row.user_id)}
          title='View Sitter'
        />}
        {['super_admin'].includes(userRole()) && <i
          className="ri-upload-cloud-2-line  text-info h4 cursor-pointer me-2"
          onClick={() => handleUpdloadSitterDoc(row.user_id)}
          title='Upload Sitter Doc'
        />}
      </div>
    )
  }

  const viewUsersFormatter = (cell, row) => {
    return (
      <div className="d-flex justify-content-evenly">
        <div style={{ textAlign: 'center' }} key={row.user_id}>
          {['super_admin'].includes(userRole()) && <i
            className=" ri-edit-2-fill text-warning h4 cursor-pointer me-2"
            onClick={() => handleEditSitterData(row.user_id)}
            title='Edit Sitter'
          />}
          {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <i
            className="ri-calendar-2-fill text-success h4 cursor-pointer mr-2"
            onClick={() => ViewCalender(row.user_id)}
            title='View Sitter Calender'
          />}
          {['super_admin'].includes(userRole()) && <i
            className=" ri-notification-fill text-warning h4 cursor-pointer mx-2"
            onClick={() => sendNotification(row.user_id, row.first_name)}
            title='Send Booking Information'
          />}
          {['super_admin'].includes(userRole()) && <i
            className="dripicons-cross text-danger h4 cursor-pointer mx-2"
            onClick={() => handleRejectSitter(row.user_id)}
            title='Reject Sitter'
          />}
          {['super_admin'].includes(userRole()) && <i
            className="ri-message-3-fill text-primary h4 cursor-pointer mx-2"
            onClick={() => handleViewSitterStatus(row.user_id)}
            title='View Sitter Status'
          />}
        </div>
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'View / Update',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%', cursor: 'pointer' },
      formatter: viewContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewUsersFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row className="mb-2">
              <Col xs={4}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Approved Sitters({totalCount})</h3>
                </div>
              </Col>
              <Col xs={8}>
                <Row className="justify-content-end">
                  <Col xs={4} className="">
                    <Label htmlFor="searchSitter">User Search</Label>
                    <Input
                      style={{ height: '35px' }}
                      name="searchSitter"
                      value={searchData}
                      onChange={handleSearch}
                      type="text"
                      className="form-control"
                      id="searchSitter"
                    />
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <Label htmlFor="is_first_calender_booked">First Login Filter</Label>
                      <Input
                        name="is_first_calender_booked"
                        value={is_first_calender_booked}
                        onChange={handleFirstCalenderBooked}
                        type="select"
                        className="form-control"
                        id="is_first_calender_booked"
                      >
                        <option value="">ALL</option>
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <Label htmlFor="is_inactive">Inactive Filter</Label>
                      <Input
                        name="is_inactive"
                        value={is_inactive}
                        onChange={handleIsActive}
                        type="select"
                        className="form-control"
                        id="is_inactive"
                      >
                        <option value="">ALL</option>
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  {isCsvLoaded ? (['super_admin', 'admin'].includes(userRole()) && <Col xs={2}>
                    <CsvDownloadButton data={csvData} /></Col>) : 
                    <Col xs={1}><Button onClick={handleCallDataForCsv}><i className='ri-download-2-line' 
                    title='Download'></i></Button></Col>}
                </Row>
              </Col>
            </Row>
            {isLoading ? (
                              <div className="text-center py-4">
      <h6 className='text-muted' >Loading...</h6>
      </div>
    ) : rowData.length > 0 ? (                 <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />) 
            : (
             <div className="text-center py-4">
               <h6 className="text-muted">No Approved Sitters Available</h6>
             </div>
           )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default RegisteredSitterList
