import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { useEffect, useState } from 'react'

const UpdateOvernightData = ({ updatedData, timeDiff }) => {
  const [overnightTimeDiff, setOvernightTimeDiff] = useState(0)
  const [overnightData, setOvernightData] = useState({
    overnight_start_date_time: '',
    overnight_end_date_time: '',
  })
  const { overnight_start_date_time, overnight_end_date_time } = overnightData

  const calculateOvernightTime = () => {
    if (!overnightData?.overnight_start_date_time || !overnightData?.overnight_end_date_time) {
        return "0 Days";
    }

    const dateA = new Date(`${overnightData.overnight_start_date_time}`);
    const dateB = new Date(`${overnightData.overnight_end_date_time}`);

    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        return "0 Days";
    }

    const timeDifferenceInMilliseconds = dateB.getTime() - dateA.getTime();
    let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convert to hours

    if (timeDifferenceInHours <= 24) {
        return "1 Day";
    }

    let fullDays = Math.floor(timeDifferenceInHours / 24);
    let remainingHours = timeDifferenceInHours % 24;

    if (remainingHours > 1) {
        return remainingHours <= 6 ? `${fullDays + 0.5} Days` : `${fullDays + 1} Days`;
    }
    
    return `${fullDays} Days`;
};


  // useEffect(() => {
  //   updatedData(overnightData)
  // }, [overnightData])

  useEffect(() => {
    const time = overnightData.overnight_end_date_time && calculateOvernightTime()
    setOvernightTimeDiff(time)
    updatedData(overnightData)
    timeDiff(time)
  }, [overnightData])
  
  return (
    <>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label>Start Date Time</Label>
            <Input
              type="datetime-local"
              className="form-control"
              onChange={(e) => {setOvernightData({ ...overnightData, overnight_start_date_time: e.target.value })}}
              value={overnight_start_date_time}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>End Date Time</Label>
            <Input
              type="datetime-local"
              className="form-control"
              onChange={(e) => {setOvernightData({ ...overnightData,
                overnight_end_date_time: e.target.value, timeDiff: calculateOvernightTime() })}}
              value={overnight_end_date_time}
            />
          </FormGroup>
        </Col>
        <p className='font-bold text-end'>Total days: {overnightTimeDiff}</p>
      </Row>
    </>
  )
}

export default UpdateOvernightData