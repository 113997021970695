import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const ConfirmationBookingModal = ({ isOpen, handleConfirm, handleClose, type, bookingType }) => {

  return(
    <Modal size="lg" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>Confirmation</ModalHeader>
      <ModalBody>
        <Row className='mb-5'>
          <p>Update booking and send <span className='font-bold'>{type}</span></p>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={handleConfirm} color="primary" className="waves-effect">Confirm</Button>
        <Button type="button" onClick={handleClose} color="light" className="waves-effect">Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationBookingModal