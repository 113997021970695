import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const ViewParentData = ({ isOpen, parentData = [], handleClose }) => {

  const dataArray = parentData
  const addresses = parentData?.address
  const style = {
    borderRight: '1px solid #ced4da'
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>View Parent Details</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label htmlFor="hear_from_source">
                How did you hear about DogStays?
              </Label>
              <Input
                name="hear_from_source"
                value={dataArray.hear_from_source}
                type="text"
                className="form-control"
                id="hear_from_source"
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="first_name">First Name</Label>
              <Input
                name="first_name"
                value={dataArray.first_name}
                type="text"
                className="form-control"
                id="first_name"
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="last_name">Last Name</Label>
              <Input
                name="last_name"
                value={dataArray.last_name}
                type="text"
                className="form-control"
                id="last_name"
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="gender">Gender</Label>
              <Input
                name="gender"
                value={dataArray.gender}
                type="text"
                className="form-control"
                id="gender"
                disabled={true}
              >
              </Input>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="email">Email address</Label>
              <Input
                name="email"
                value={dataArray.email}
                type="email"
                className="form-control"
                id="email"
                disabled={true}
              />
            </FormGroup>

          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label htmlFor="date_of_birth">Date of birth</Label>
              <Input
                name="date_of_birth"
                value={dataArray.date_of_birth}
                type="text"
                className="form-control"
                id="date_of_birth"
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="mobile">Phone</Label>
              <Input
                name="mobile"
                value={dataArray.mobile}
                type="text"
                className="form-control"
                id="mobile"
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="preferred_languages">Which languages do you speak?</Label>
              <Input
                name="preferred_languages"
                value={dataArray.preferred_languages}
                type="text"
                className="form-control"
                id="preferred_languages"
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="using_whatsapp_messenger">
                Do you use WhatsApp Messenger?
              </Label>
              <Input
                name="using_whatsapp_messenger"
                value={dataArray.using_whatsapp_messenger}
                type="text"
                className="form-control"
                id="using_whatsapp_messenger"
                disabled={true}
              >
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="another_whatsapp_mobile">
                If the number you use on WhatsApp is different please share that
                with us here
              </Label>
              <Input
                name="another_whatsapp_mobile"
                value={dataArray.another_whatsapp_mobile}
                type="text"
                className="form-control"
                id="another_whatsapp_mobile"
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <h3>Addresses</h3>
          {addresses && addresses.map((item, index) => {
            return (
              <>
                <h6 className='mt-3'>Address: {index + 1}</h6>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="street_name">Street Name and Number</Label>
                    <Input
                      name="street_name"
                      value={item.street_name}
                      type="text"
                      className="form-control"
                      id="street_name"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="city">City</Label>
                    <Input
                      name="city"
                      value={item.city}
                      type="text"
                      className="form-control"
                      id="city"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="country">Country</Label>
                    <Input
                      name="country"
                      value={item.country}
                      type="text"
                      className="form-control"
                      id="country"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="postal_code">Postal Code</Label>
                    <Input
                      name="postal_code"
                      value={item.postal_code}
                      type="text"
                      className="form-control"
                      id="postal_code"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              </>
            )
          })}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewParentData
