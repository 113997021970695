import { getMeetingLocationByBookingId } from 'Views/Dashboard/Views/Parents/Api'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { updateMeetingData, updatePendingMeetingData } from 'Views/Dashboard/Views/Meetings/Api'
import dayjs from 'dayjs'


const EditMeetingData = ({ isOpen, handleClose, bookingIntId, id, reRenderPage, bookingStatus, data }) => {
  const [locations, setLocations] = useState([])
  const [meetingUpdateData, setMeetingUpdateData] = useState({ date: '', time: '', location: '' })
  const defalutDate = data.raw_meeting_datetime.split('T')[0]
  const { date, time, location } = meetingUpdateData
  let setTime = data.raw_meeting_datetime.split('T')[1].split('Z')[0].split(':')
  let formatedTime =`${setTime[0]}:${setTime[1]}`

  useEffect(() => {
    getMeetingLocationByBookingId(bookingIntId).then((res) => {
      setLocations(res.data.data)
    })
  },[])

  useEffect(() => {
    setMeetingUpdateData({ ...meetingUpdateData, date: data.raw_meeting_datetime.split('T')[0],
      time: formatedTime, location: data.raw_meeting_location })
  },[data])

  const handleConfirm = () => {
    const statusType = bookingStatus == 'date_change_request' ? updatePendingMeetingData : updateMeetingData
    statusType({
      'meeting_time': `${date}T${time}`,
      'meeting_location': location
    }, id).then((res) => {
      if(res.data.response_code === 80){
        reRenderPage()
        handleClose()
      }
    })
  }
 
  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}> 
      <ModalHeader>Edit meeting</ModalHeader>
      <ModalBody>

        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label>Meeting Date</Label>
              <Input
                onChange={(e) => {setMeetingUpdateData({ ...meetingUpdateData, date: e.target.value })}}
                type="date"
                className="form-control"
                min={new Date().toISOString().split('T')[0]}
                defaultValue={defalutDate}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label>Meeting Time</Label>
              <br/>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  defaultValue={dayjs(data.raw_meeting_datetime.split('Z')[0])} 
                  ampm={false}
                  onChange={(e) => {setMeetingUpdateData({ ...meetingUpdateData, time: e.format('HH:mm') })}}
                />
              </LocalizationProvider>
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label>Meeting Location</Label>
              <Input type="select" className="form-control"
                onChange={(e) => {setMeetingUpdateData({ ...meetingUpdateData, location: e.target.value })}}
                value={meetingUpdateData.location}
              >
                <option selected disabled value=''>Select</option>
                {locations.length > 0 && locations.map((item, index) => {
                  return(
                    <option key={index} value={item}>{item}</option>
                  )})
                }
              </Input>
              
            </FormGroup>
          </Col>
        </Row>
       
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleConfirm}
          color="primary"
          className="waves-effect"
        >
          Confirm
        </Button>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditMeetingData
