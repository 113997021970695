import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import PhoneInput from 'react-phone-input-2'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { languageOptions } from '../EditSitterprofile/helper'
import { updateAddressInfoReq, updateParentInfoReq } from 'Views/Dashboard/Views/Parents/Api'

const EditParentProfileData = ({ isOpen, parentData = [], handleClose, userId }) => {

  const dataArray = parentData
  const countryOptions = useMemo(() => countryList().getData(), [])
  const parentUserId = userId

  const [editUserData, setEditUserData] = useState({
    hear_from_source: '',
    other_hear_from_source: '',
    first_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    mobile: '',
    using_whatsapp_messenger: '',
    another_whatsapp_mobile: '',
    preferred_languages: [],
    email: '',
    address: []
  })
  const [addressInfo, setAddressInfo] = useState([])

  const { hear_from_source,
    other_hear_from_source,
    first_name,
    last_name,
    gender,
    date_of_birth,
    mobile,
    using_whatsapp_messenger,
    another_whatsapp_mobile,
    preferred_languages,
    email,
    address
  } = editUserData

  const modifyArray = (arr) => {
    let updatedArray = []
    for (let i in arr) {
      updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] })
    }
    return updatedArray
  }

  const formatArrayForCountry = (arr) => {
    let updatedArray = []
    for (let i in arr) {
      updatedArray.push({ key: arr, label: arr, value: arr })
    }
    return updatedArray
  }


  useEffect(() => {
    setEditUserData({
      ...editUserData,
      hear_from_source: dataArray.hear_from_source,
      other_hear_from_source: dataArray.other_hear_from_source,
      first_name: dataArray.first_name,
      last_name: dataArray.last_name,
      gender: dataArray.gender,
      date_of_birth: dataArray.date_of_birth,
      mobile: dataArray.mobile,
      using_whatsapp_messenger: dataArray.using_whatsapp_messenger,
      another_whatsapp_mobile: dataArray.another_whatsapp_mobile,
      preferred_languages: modifyArray(dataArray.preferred_languages),
      email: dataArray.email,
      address: dataArray.address
    })
    setAddressInfo(dataArray?.address)
  }, [dataArray])

  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value)
    return formatedData
  }

  const formatCountrySelectData = (data) => {
    const formatedData = data.label
    return formatedData
  }

  const updateSitterDetails = async() => {
    try {
      await updateParentInfoReq(
        {
          hear_from_source: hear_from_source,
          other_hear_from_source: other_hear_from_source,
          first_name: first_name,
          last_name: last_name,
          gender: gender,
          date_of_birth: date_of_birth,
          mobile: mobile,
          using_whatsapp_messenger: using_whatsapp_messenger,
          another_whatsapp_mobile: another_whatsapp_mobile,
          preferred_languages: formatMultiSelectData(preferred_languages),
          email: email,
        },
        parentUserId
      )
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  const style = {
    borderRight: '1px solid #ced4da'
  }

  const updateAddressDetailsById = (addressId, index) => {
    updateAddressInfoReq(addressInfo[index], addressId).then((res) => {
      if (res.data.response_code === 80) {
        handleClose()
      }
    })
  }

  console.log(dataArray, 'addressInfo')

  return (
    <Modal size='xl' isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>Edit Parent application</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label htmlFor='hear_from_source'>
                How did you hear about DogStays?
              </Label>
              <Input
                name='hear_from_source'
                placeholder='Enter Hear From Source'
                value={hear_from_source}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  hear_from_source: e.target.value
                })}
                type='select'
                errorMessage='hear_from_source is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='hear_from_source'
              >
                <option hidden>Select</option>
                <option value='Google Search'>
                  Google Search
                </option>
                <option value='Facebook group'>
                  Facebook group
                </option>
                <option value=' Through a friend'>
                  {' '}
                  Through a friend
                </option>
                <option value='Booked With DogStays before'>
                  Booked With DogStays before
                </option>
                <option value='Facebook Ad'>
                  Facebook Ad
                </option>
                <option value='Google Ad'>Google Ad</option>
                <option value='Other'>Other</option>
              </Input>
            </FormGroup>

            {hear_from_source === 'Other' && (
              <FormGroup>
                <Label htmlFor='other_hear_from_source'>
                  {' '}
                  Please tell us how you heard of DogStays
                </Label>
                <Input
                  name='other_hear_from_source'
                  placeholder='Please Explain'
                  value={other_hear_from_source}
                  onChange={(e) => setEditUserData({
                    ...editUserData,
                    other_hear_from_source: e.target.value
                  })}
                  type='textarea'
                  errorMessage='other_hear_from_source is required'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  id='other_hear_from_source'
                  required
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label htmlFor='first_name'>First Name</Label>
              <Input
                name='first_name'
                placeholder='Enter Name'
                value={first_name}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  first_name: e.target.value
                })}
                type='text'
                errorMessage='first_name is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='first_name'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='last_name'>Last Name</Label>
              <Input
                name='last_name'
                placeholder='Enter Family Name'
                value={last_name}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  last_name: e.target.value
                })}
                type='text'
                errorMessage='last_name is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='last_name'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='gender'>Gender</Label>
              <Input
                name='gender'
                placeholder='Enter gender'
                value={gender}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  gender: e.target.value
                })}
                type='select'
                errorMessage='gender is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='gender'
              >
                <option hidden>Select Gender</option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
                <option value='other'>Other</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label htmlFor='date_of_birth'>
                Date of birth
              </Label>
              <Input
                name='date_of_birth'
                placeholder='Enter DOB'
                value={date_of_birth}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  date_of_birth: e.target.value
                })}
                max={new Date().toISOString().split('T')[0]}
                type='date'
                errorMessage='date_of_birth is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='date_of_birth'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='mobile'>Phone</Label>
              <PhoneInput
                country={'lu'}
                specialLabel={''}
                value={mobile}
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  mobile: value
                })}
              />
            </FormGroup>

          </Col>
          <Col lg='6' style={style}>


            <FormGroup>
              <Label htmlFor='preferred_languages'>
                Which languages do you speak?
              </Label>
              <Select
                className='select-font'
                options={languageOptions}
                value={preferred_languages}
                isMulti
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  preferred_languages: value
                })}
                name='preferred_languages'
                placeholder='Please select multiple languages'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='using_whatsapp_messenger'>
                Do you use WhatsApp Messenger?
              </Label>
              <Input
                name='using_whatsapp_messenger'
                placeholder='Enter '
                value={using_whatsapp_messenger}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  using_whatsapp_messenger: e.target.value
                })}
                type='select'
                errorMessage='using_whatsapp_messenger is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='using_whatsapp_messenger'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='another_whatsapp_mobile'>
                If the number you use on WhatsApp is
                different please share that with us here
              </Label>
              <PhoneInput
                country={'lu'}
                specialLabel={''}
                value={another_whatsapp_mobile}
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  another_whatsapp_mobile: value
                })}
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='email'>Email address</Label>
              <Input
                name='email'
                placeholder='Enter Email'
                value={email}
                disabled
                type='email'
                errorMessage='email is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='email'
              />
            </FormGroup>
          </Col>
        </Row>
        {addressInfo && addressInfo.map((item, index) => {
          return <Row className='justify-content-center' key={index}>
            <h4>Address: {index + 1}</h4>
            <Col lg='6' style={style}>
              <FormGroup>
                <Label htmlFor='street_name'>
                  Street Name and Number
                </Label>
                <Input
                  name='street_name'
                  placeholder='Enter Street Name'
                  value={item.street_name}
                  onChange={(e) => {
                    const updatedData = {
                      ...item, street_name: e.target.value
                    }
                    setAddressInfo((prevData) => {
                      const newData = [...prevData]
                      newData[index] = updatedData
                      return newData
                    })
                  }}
                  type='text'
                  errorMessage='street_name is required'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  id='street_name'
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor='city'>
                  Town/Village/City
                </Label>
                <Input
                  name='city'
                  placeholder='Enter City'
                  value={item.city}
                  onChange={(e) => {
                    const updatedData = {
                      ...item, city: e.target.value
                    }
                    setAddressInfo((prevData) => {
                      const newData = [...prevData]
                      newData[index] = updatedData
                      return newData
                    })
                  }}
                  type='text'
                  errorMessage='city is required'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  id='city'
                />
              </FormGroup>
            </Col>
            <Col lg='6' style={style}>
              <FormGroup>
                <Label htmlFor='country'>Country</Label>
                <Select
                  className='select-font'
                  options={countryOptions}
                  value={formatArrayForCountry(item.country)}
                  onChange={(value) => {
                    const updatedData = {
                      ...item, country: formatCountrySelectData(value)
                    }
                    setAddressInfo((prevData) => {
                      const newData = [...prevData]
                      newData[index] = updatedData
                      return newData
                    })
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor='postal_code'>Post Code</Label>
                <Input
                  name='postal_code'
                  placeholder='Enter Postal Code'
                  value={item.postal_code}
                  onChange={(e) => {
                    const updatedData = {
                      ...item, postal_code: e.target.value
                    }
                    setAddressInfo((prevData) => {
                      const newData = [...prevData]
                      newData[index] = updatedData
                      return newData
                    })
                  }}
                  type='text'
                  errorMessage='postal_code is required'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  id='postal_code'
                />
              </FormGroup>
            </Col>
            <Button type='button' onClick={() => updateAddressDetailsById(item.id, index)} color='success'
              className='waves-effect w-25 my-4'>Update</Button>
          </Row>
        })}
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          onClick={updateSitterDetails}
          color='primary'
          className='waves-effect'
        >
          Update
        </Button>
        <Button
          type='button'
          onClick={handleClose}
          color='light'
          className='waves-effect'
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditParentProfileData
