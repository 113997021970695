import { userRole } from 'Utils/helper'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const ViewSitterProfile = ({ isOpen, sitterData = [], handleClose }) => {

  const dataArray = sitterData

  const style ={
    borderRight: '1px solid #ced4da'
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>View sitter application</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label htmlFor="hear_from_source">
                How did you hear about DogStays?
              </Label>
              <p className='border rounded p-2'>{dataArray.hear_from_source}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="first_name">Name</Label>
              <p className='border rounded p-2'>{dataArray.first_name}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="last_name">Family Name</Label>
              <p className='border rounded p-2'>{dataArray.last_name}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="gender">Gender</Label>
              <p className='border rounded p-2'>{dataArray.gender}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="date_of_birth">Date of birth</Label>
              <p className='border rounded p-2'>{dataArray.date_of_birth}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="mobile">Phone</Label>
              <p className='border rounded p-2'>{dataArray.mobile}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="preferred_languages">Which languages do you speak?</Label>
              <p className='border rounded p-2'>{dataArray.preferred_languages}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="using_whatsapp_messenger">
                Do you use WhatsApp Messenger?
              </Label>
              <p className='border rounded p-2'>{dataArray.using_whatsapp_messenger}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="another_whatsapp_mobile">
                If the number you use on WhatsApp is different please share that
                with us here
              </Label>
              <p className='border rounded p-2'>{dataArray.another_whatsapp_mobile}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email address</Label>
              <p className='border rounded p-2'>{dataArray.email}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="street_name">Street Name and Number</Label>
              <p className='border rounded p-2'>{dataArray.street_name}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="city">City</Label>
              <p className='border rounded p-2'>{dataArray.city}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="country">Country</Label>
              <p className='border rounded p-2'>{dataArray.country}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="postal_code">Postal Code</Label>
              <p className='border rounded p-2'>{dataArray.postal_code}</p>
            </FormGroup>
          </Col>

          <Col lg='6'>
            <FormGroup>
              <Label htmlFor="reason_of_joining">
                Why do you want to join our network?
              </Label>
              <p className='border rounded p-2'>{dataArray.reason_of_joining}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="have_your_own_dog">
                Do you have your own dog here in Luxembourg?
              </Label>
              <p className='border rounded p-2'>{dataArray.have_your_own_dog}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="sitter_dog_info">
                If YES then please tell us about your dog (Name, girl or boy?
                Neutered or not? what breed? how old he/she is? Is your dog good
                with other dogs? )
              </Label>
              <p className='border rounded p-2'>{dataArray.sitter_dog_info}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="have_your_own_cat">
                Do you have a cat at home here in Luxembourg?
              </Label>
              <p className='border rounded p-2'>{dataArray.have_your_own_cat}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="has_previous_experience_for_own_dog">
                Do you have previous experience caring for your own dog(s)?
              </Label>
              <p className='border rounded p-2'>{dataArray.has_previous_experience_for_own_dog}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="own_dog_experience_details">
                If YES, please tell us about this experience in detail
              </Label>
              <p className='border rounded p-2'>{dataArray.own_dog_experience_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="has_previous_experience_as_dog_sitter">
                Do you have previous experience as a dog sitter for someone
                else?
              </Label>
              <p className='border rounded p-2'>{dataArray.has_previous_experience_as_dog_sitter}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="as_sitter_experience_details">
                If YES, please tell us about this experience in detail
              </Label>
              <p className='border rounded p-2'>{dataArray.as_sitter_experience_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="has_formal_training">
                Do you have any formal training related to dogs or other
                animals?
              </Label>
              <p className='border rounded p-2'>{dataArray.has_formal_training}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="formal_training_details">
                If YES, please tell us about this training in detail
              </Label>
              <p className='border rounded p-2'>{dataArray.formal_training_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="cared_for_other_than_dog">
                Have you cared for other types of animals (besides dogs)?
              </Label>
              <p className='border rounded p-2'>{dataArray.cared_for_other_than_dog}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="caring_details_for_other_than_dog">
                If YES, please tell us about this experience in detail
              </Label>
              <p className='border rounded p-2'>{dataArray.caring_details_for_other_than_dog}</p>
            </FormGroup>
          </Col>
        </Row>

        <Row className='text-center my-3'>
          <h3>Booking Details Data</h3>
        </Row>
        <Row>
          <Col lg="6" style={style}>
            <FormGroup>
              <Label htmlFor="daily_schedule">
              What is your work/daily schedule?
              </Label>
              <p className='border rounded p-2'>{dataArray.daily_schedule}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="daily_schedule_details">
              Daily Schedule Details 
              </Label>
              <p className='border rounded p-2'>{dataArray.daily_schedule_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="home_type">Do you live in a House or Apartment?</Label>
              <p className='border rounded p-2'>{dataArray.home_type}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="home_size"> Please tell us the approximate size of your home in m2</Label>
              <p className='border rounded p-2'>{dataArray.home_size}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="living_situation">Please choose the one that matches your living situation:</Label>
              <p className='border rounded p-2'>{dataArray.living_situation}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="floor">Which floor do you live on?</Label>
              <p className='border rounded p-2'>{dataArray.floor}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="home_has_elevator">Does your building have an elevator?</Label>
              <p className='border rounded p-2'>{dataArray.home_has_elevator}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="outdoor_spaces">
              What outdoor spaces do you have in your home? (choose all that apply)
              </Label>
              <p className='border rounded p-2'>{dataArray.outdoor_spaces}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="nearby_places">
              Which of these do you have near your home?
              </Label>
              <p className='border rounded p-2'>{dataArray.nearby_places}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="family_details">
              Please tell who lives in your home with you. Who else would interact with the
               dogs you look after and how do they feel about dogs?
              </Label>
              <p className='border rounded p-2'>{dataArray.family_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="have_children">Do you have children at home and would 
                they be interacting with the dog as well?</Label>
              <p className='border rounded p-2'>{dataArray.have_children}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="children_details">If YES, please tell us about the children 
              (how old are they? are they comfortable around dogs? Do they have experience with dogs? Will 
              they interact with the dogs only under supervision?)</Label>
              <p className='border rounded p-2'>{dataArray.children_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="has_driving_license">
              Do you have a driving license?
              </Label>
              <p className='border rounded p-2'>{dataArray.has_driving_license}</p>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label htmlFor="has_car">
              Do you have access to your own or a family car?
              </Label>
              <p className='border rounded p-2'>{dataArray.has_car}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="dog_emergency_details">
              In the case of an emergency situation would you be able to take a dog to an emergency vet?
               Please explain how you would manage the situation.
              </Label>
              <p className='border rounded p-2'>{dataArray.dog_emergency_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="offered_services">
              What services would you like to offer with DogStays?
              </Label>
              <p className='border rounded p-2'>{dataArray.offered_services}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="size_of_dog">
              What all sizes of dogs are you comfortable caring for?
               (don`t worry, you will always meet the dog before accepting a booking!)
              </Label>
              <p className='border rounded p-2'>{dataArray.size_of_dog}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="age_of_dog">
              Your are comfortable caring for dogs of what ages?
              </Label>
              <p className='border rounded p-2'>{dataArray.age_of_dog}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="comfortable_with_non_toilet_trained">
              Are you comfortabl caring for young dogs who are still being toilet trained?
              </Label>
              <p className='border rounded p-2'>{dataArray.comfortable_with_non_toilet_trained}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="comfortable_with_two_dogs">
              Are you comfortable caring for 2 dogs from the same house?
              </Label>
              <p className='border rounded p-2'>{dataArray.comfortable_with_two_dogs}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="comfortable_with_cat_or_other">
              Are you comfortable caring for cats or other small animals?
              </Label>
              <p className='border rounded p-2'>{dataArray.comfortable_with_cat_or_other}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="has_formal_training">
              Would you be open to caring for dogs with separation anxiety or other problems?
              (don`t worry, you will always meet the dog before deciding to accept a particular booking)
              </Label>
              <p className='border rounded p-2'>{dataArray.comfortable_with_separation_anxiety}</p>
            </FormGroup>
          </Col>
        </Row>
 

        {['super_admin', 'admin'].includes(userRole()) &&  <Row className='mt-5'>
          <h4>Documents Uploaded</h4>
          <Col lg="6">
            <FormGroup>
              <Label htmlFor="passport_image">
              Identification Doucment (EU ID/Passport)
              </Label>
              <br/>
              <a className="btn btn-info" href={dataArray.passport_image} alt='View Image' 
                target='_blank' rel="noreferrer">View Document</a>
              {/* <img className='w-100' src={dataArray.passport_image}/> */}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label htmlFor="address_proof_image">
              Address Proof
              </Label>
              <br/>
              <a className="btn btn-info" href={dataArray.address_proof_image} alt='View Image' 
                target='_blank' rel="noreferrer">View Document</a>
              {/* <img className='w-100' src={dataArray.address_proof_image}/> */}
            </FormGroup>
          </Col>
        </Row>}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewSitterProfile
