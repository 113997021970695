import { useEffect, useRef, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import Select from 'react-select'
import BookingFilters from 'Views/Dashboard/Views/Parents/Views/BookingDetails/Filters'
import BookingFilteredSitterList from 'Components/BookingFilterList'
import { getBookingSearchResults, sendNotificationReqViaPlatform } from 'Views/Dashboard/Views/Parents/Api'
import * as moment from 'moment'
import DatePicker from 'react-multi-date-picker'
import { createCalenderEventreq, createDaycareBookingCalenderEventreq, getCalenderEventByIdreq,
  getCalenderEventreq } from 'Views/Dashboard/Views/Filters/Filter.Apis'
import { clearUserActiveStatusDataByIdReq, getUserDataByIdReq,
  upadteUserActiveStatusDataByIdReq } from 'Views/Dashboard/Views/Users/Users.Apis'
import Calender from 'Components/FullCalender'
import ViewSitterProfile from 'Components/ViewSitterProfile'
import { BookingSitterFilteredListTableHeading } from 'Views/Dashboard/Views/Parents/Constants'



const BookingSitterSearch = ({ isOpen, handleClose, bookingId, bookingData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  const otherAddress = bookingData?.manual_address ? bookingData?.manual_address?.street_name + ' ' +
    bookingData?.manual_address?.postal_code + ' ' +
    bookingData?.manual_address?.city + ' ' +
    bookingData?.manual_address?.country : '-'

  const parentAddress = bookingData?.parent_address ? bookingData?.parent_address?.street_name + ' ' +
    bookingData?.parent_address?.postal_code + ' ' +
    bookingData?.parent_address?.city + ' ' +
    bookingData?.parent_address?.country : '-'

  const [usersList, setUsersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [sitter, setSitter] = useState([])
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  })
  const [isCalenderOpen, setIsCalenderOpen] = useState(false)
  const [isViewSitterModal, setIsViewSitterModal] = useState(false)
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false)
  const [daycareBookingModal, setDaycareBookingModal] = useState(false)
  const [eventsData, setEventsData] = useState([])
  const [sitterNameByEvent, setSitterNameByEvent] = useState('')
  const [eventTypeErrorMsg, setEventTypeErrorMsg] = useState()
  const [inActiveData, setInActiveData] = useState({
    statusEndDate: '',
    userName: ''
  })
  const [calenderEventData, setCalenderEventData] = useState({
    title: '',
    description: '',
    event_type: '',
    start_time: '',
    end_time: '',
    startDateTime: '',
    endDateTime: '',
    multipleDates: []
  })
  const [userInfoModal, setUserInfoModal] = useState(false)
  const [eventId, setEventId] = useState('')
  const [id, setId] = useState('') // sitter Id
  const [eventData, setEventData] = useState({
    eventTitle: '',
    eventType: '',
    eventDescription: '',
    eventStartDateTime: '',
    eventEndDateTime: ''
  })
  const { statusEndDate, userName } = inActiveData
  const { title, description, event_type, startDateTime, endDateTime, multipleDates  } = calenderEventData

  const ref = useRef()


  const { currentPage, totalSize } = paginationValue


  const getBookingSearchResultsData = async(bookingId, queryParams, sortField, sortOrder) => {
    try {
      setIsLoading(true)
      let distance_sort = null
      if (sortField == 'distance'){
        distance_sort = sortOrder
      }
      const { data } = await getBookingSearchResults(bookingId, distance_sort, queryParams)
      console.log(queryParams,'queryParams')
      setUsersList(data.data.results)
      setPaginationValue({
        ...paginationValue,
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleTablePageChangeTags = (type, { page, sizePerPage, sortField, sortOrder }) => {
    setPaginationValue({ ...paginationValue, currentPage: page })
    getBookingSearchResultsData(bookingId, {
      page : page,
      search: searchData
    }, sortField, sortOrder)
  }

  const handleSendReqViaEmail = (sitterId) => {
    if (window.confirm('Are you sure you want to send email?')) {
      sendNotificationReqViaPlatform({
        'sitter_id': sitterId,
        'action' : 'email'
      }, bookingId)
    }
  }
  
  const handleSendReqViaWhatsapp = (sitterId) => {
    if (window.confirm('Are you sure you want to send via Whatsapp?')) {
      sendNotificationReqViaPlatform({
        sitter_id: sitterId,
        action : 'whatsapp'
      }, bookingId)
    }
  }
  const handleChangeTickMark = (sitterId, tick_mark) => {
    if (window.confirm('Are you sure you want to select the dog sitter?')){
      sendNotificationReqViaPlatform({
        sitter_id: sitterId,
        action : tick_mark ? 'untick' : 'tick'
      }, bookingId).then(() => {
        getBookingSearchResultsData(bookingId, { page: currentPage, search: searchData })
      })
    }
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    // Check if sorting is applied
    if (sortField && sortOrder) {
      getBookingSearchResults(bookingId, sortOrder)
    }
  }

  const handleViewSitterData = async(sitterId) => {
    try {
      const { data } = await getUserDataByIdReq(sitterId)
      setSitter(data.data)
      setIsViewSitterModal(true)
    } catch (error) {
      console.log(error)
    }
  }


  const handleSitterCalender = (sitterId) => {
    getCalenderDataByUserId(sitterId)
    setId(sitterId)
    setIsCalenderOpen(true)
  }

  // calendar function starts

  const handleInputChange = (e) => {
    setCalenderEventData({
      ...calenderEventData,
      [e.target.name]: e.target.value,
    })
  }

  const getCalenderDataByUserId = async(UserId) => {
    try {
      const { data } = await getCalenderEventreq(UserId)
      setEventsData(data.data)
      setSitterNameByEvent(data.sitter_name)
      setInActiveData({
        ...inActiveData,
        userName: data.in_active_status_text
      })
      console.log(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const select = async(start) => {
    setCalenderEventData({
      ...calenderEventData,
      startDateTime : moment(new Date(start.startStr)).format('YYYY-MM-DDT00:00'),
      endDateTime : moment(new Date(start.endStr)).subtract(1, 'days').format('YYYY-MM-DDT23:59')
    })  
    setIsCreateEventModalOpen(true)
  }

  const enableEventAlert = async(e) => {
    setEventId(e.event._def.extendedProps.event_id)
    try {
      const { data } = await getCalenderEventByIdreq(e.event._def.extendedProps.event_id)
      setEventData({
        eventTitle : data.data.title,
        eventType: data.data.event_type,
        eventDescription: data.data.description,
        eventStartDateTime: (data.data.start).slice(0, 16),
        eventEndDateTime: (data.data.end).slice(0, 16)
      })
      setUserInfoModal(true)
    } catch (error) {
      console.error(error)
    }
  }

  const submitInActiveStatus = async() => {
    const { data } = await upadteUserActiveStatusDataByIdReq({ 
      title: 'INACTIVE',
      description: 'INACTIVE',
      end_time: statusEndDate
    },
    id
    )
    getCalenderDataByUserId(id)
  }

  const clearInActiveStatus = async() => {
    const { data } = await clearUserActiveStatusDataByIdReq(id)
    getCalenderDataByUserId(id)
  }

  const formatMultiSelectDate = (data) => {
    const formatedData = data.map((value) => value.year + '-' + value.month + '-' + value.day)
    return formatedData
  }

  const createDaycareBookingEvent = async() => {
    if(event_type == ''){
      setEventTypeErrorMsg('Please select the event type')
    }else{
      const { data } = await createDaycareBookingCalenderEventreq(
        {
          title,
          description: description,
          multiple_dates: formatMultiSelectDate(multipleDates),
          event_type,
        },
        id
      )
      setDaycareBookingModal(false)
      setCalenderEventData({
        title: '',
        description: '',
        event_type: '',
        multipleDates: []
      })
      setEventTypeErrorMsg('')
      getCalenderDataByUserId(id)
    }
  }

  const createFilterEvent = async() => {
    if(event_type == '' ){
      setEventTypeErrorMsg('Please select the event Type')
    }else{
      const { data } = await createCalenderEventreq(
        {
          title,
          description: description,
          start_time: startDateTime,
          end_time: endDateTime,
          event_type,
        },
        id
      )
      setIsCreateEventModalOpen(false)
      setCalenderEventData({
        title: '',
        description: '',
        event_type: '',
        startDateTime: '',
        endDateTime: ''
      })
      setEventTypeErrorMsg('')
      getCalenderDataByUserId(id)
    }
  }
  // calendar functions Ends

  useEffect(() => {
    getBookingSearchResultsData(bookingId, { page: 1, search: searchData })
  }, [])

  const handleSearch = (e) => {
    setSearchData(e.target.value)
    getBookingSearchResultsData(bookingId, { page: currentPage, search: e.target.value },)
  }
  return (
    <>
      <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}>
        <ModalHeader>Sitter search against booking</ModalHeader>
        <ModalBody>
          <Row className='p-2' style={{ background: '#c3cfd9' }}>
            <Col lg='4'>
              <h6 className='mb-2' style={{ color: '#e8833a', fontWeight: 500 }}>Booking Details - Booking Id:
                {bookingData.booking_id}</h6>
              <p className='m-0'>Service Type: <span className='font-bold'>{bookingData.service_type}</span></p>
              <p className='m-0'>Preferred Sitter: <span className='font-bold'>
                {bookingData.preferred_sitters_display}</span></p>
              <p className='m-0'>Service Locations: <span className='font-bold'>{
                bookingData.location_type_display_name}</span></p>
              {bookingData.location_type && bookingData.location_type.includes('parent_location') &&
               <p className='m-0'>Parents Home: <span className='font-bold'>{parentAddress}</span></p>}
              {bookingData.location_type && bookingData.location_type.includes('other_address') &&
               <p className='m-0'>Other Address: <span className='font-bold'>{otherAddress}</span></p>}
              {bookingData.location_type && bookingData.location_type.includes('sitter_location') && <div className='mt-2'>
                

                <p className='m-0'>Ok if sitter&apos;s home has dog:
                  <span className='font-bold'>{bookingData?.comfortable_with_sitter_dog}</span></p>
                <p className='m-0'>Additional details:
                  <span className='font-bold'>{bookingData?.comfortable_with_sitter_dog_text}</span></p>

                <p className='m-0'>Ok if sitter&apos;s home has cat:
                  <span className='font-bold'>{bookingData?.comfortable_with_sitter_cat}</span></p>
                <p className='m-0'>Additional details:
                  <span className='font-bold'>{bookingData?.comfortable_with_sitter_cat_text}</span></p>

                <p className='m-0'>Ok if sitter&apos;s home has children:
                  <span className='font-bold'>{bookingData?.comfortable_with_sitter_children}</span></p>
                <p className='m-0'>Additional details:
                  <span className='font-bold'>{bookingData?.comfortable_with_sitter_children_text}</span></p></div>}
            </Col>

            <Col lg='4'>
              <p className='m-0'>Request Dates & Times: <span className='font-bold'>{bookingData.dates}</span></p>
              <p className='m-0'>Total Day Count: <span className='font-bold'>{bookingData.total_day_count}</span></p>
              {/* <p className='m-0'>Date Flexibility: 
                <span className='font-bold'>{bookingData.daycare_dates_flexible}</span></p> */}
              <p className='m-0'>Any Preffered Dog sitter: 
                <span className='font-bold'>{bookingData.preferred_sitters_text}</span></p>
              <p className='m-0'>comfortable splitting  booking: 
                <span className='font-bold'>{bookingData.comfortable_splitting_booking}</span></p>
              <p className='m-0'>comfortable with overlap: 
                <span className='font-bold'>{bookingData.sitter_caring_for_another_dog}</span></p>
              <p className='m-0'>Available for video call: 
                <span className='font-bold'>{bookingData.available_for_video_call}</span></p>
            </Col>

            <Col lg='4'>
              <p className='m-0'>Dog Parent Name: <span className='font-bold'>{bookingData.parent}</span></p>
              <p className='m-0'>Dog Parent Email:
                <span className='font-bold'>{bookingData.parent_details?.email}</span></p>
              <p className='m-0'>Dog Parent Phone Number:
                <span className='font-bold'>{bookingData.parent_details?.mobile}</span></p>
              <p className='m-0'>Gender: <span className='font-bold'>{bookingData.parent_details?.gender}</span></p>
              <p className='m-0'>Language:
                <span className='font-bold'>{bookingData.parent_details?.preferred_languages}</span></p>

              <h5 className='mt-3 font-bold'>Dog&apos;s Details </h5>
              {bookingData?.dogs && bookingData?.dogs.map((item, index) => {
                return (
                  <div className='mb-3' key={index}>
                    <p className='mb-1'>Dog #{index + 1}</p>
                    <p className='m-0'>Name: <span className='font-bold'>{item.name}</span></p>
                    <p className='m-0'>Gender: <span className='font-bold'>{item.gender}</span></p>
                    <p className='m-0'>Breed: <span className='font-bold'>{item.breed}</span></p>
                    <p className='m-0'>DOB: <span className='font-bold'>{item.date_of_birth}</span></p>
                    <p className='m-0'>Age: <span className='font-bold'>{item?.age}</span></p>
                    <p className='m-0'>Size Category: <span className='font-bold'>{item.size_category}</span></p>
                  </div>
                )
              })
              }
            </Col>
          </Row>
          <BookingFilters filterDataBasedOnAction={(res) => {setUsersList(res)}}/>
          <BookingFilteredSitterList
            handleTablePageChange={handleTablePageChangeTags}
            tableData={usersList}
            tableHeading={BookingSitterFilteredListTableHeading}
            page={currentPage}
            sizePerPage={10}
            totalSize={totalSize}
            isLoading={isLoading}
            handleSendReqViaEmail={handleSendReqViaEmail}
            handleSendReqViaWhatsapp={handleSendReqViaWhatsapp}
            handleSitterCalender={handleSitterCalender}
            handleViewSitterData={handleViewSitterData}
            handleChangeTickMark={handleChangeTickMark}
            handleSearch={handleSearch}
            searchData={searchData}
          />
          {isCalenderOpen && <Calender
            isOpen={isCalenderOpen}
            toggleModal={() => setIsCalenderOpen(true)}
            handleClose={() => setIsCalenderOpen(false)}
            eventsData={eventsData}
            selectable={true}
            select={select}
            editable={true}
            timeZone="UTC"
            eventClick={enableEventAlert}
            sitterName={sitterNameByEvent}
            inActiveEndDate={(e) => setInActiveData({
              ...inActiveData,
              statusEndDate: e.target.value
            })}
            statusEndDate={statusEndDate}
            submitInActiveStatus={submitInActiveStatus}
            clearInActiveStatus={clearInActiveStatus}
            userActiveStatusText={userName}
            handleCreateDaycareModal={() => {setDaycareBookingModal(true)}}
          />}

          <ViewSitterProfile
            isOpen={isViewSitterModal}
            toggleModal={() => setIsViewSitterModal(true)}
            sitterData={sitter}
            handleClose={() => {setIsViewSitterModal(false); setSitter([])}}
          />

        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={handleClose} color="light" className="waves-effect">Close</Button>
        </ModalFooter>
      </Modal>

      {daycareBookingModal && (
        <div>
          <Modal size="lg" isOpen={daycareBookingModal} backdrop="static">
            <ModalHeader>Block Sitter`s Calendar</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Select Dates</Label>
                    <DatePicker 
                      ref={ref}
                      className='d-block'
                      multiple
                      onChange={(e) => {setCalenderEventData({
                        ...calenderEventData,
                        multipleDates : e
                      })}}
                      value={multipleDates}
                      dateFormat="YYYY-MM-DD"
                      shouldCloseOnSelect={true}
                    >
                      <Button className='py-1' style={{ position: 'relative', bottom: '5px' }}
                        color='primary' onClick={() => ref.current.closeCalendar()}>
                      close
                      </Button>
                    </DatePicker>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="title"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <Input
                  name="description"
                  value={description}
                  onChange={handleInputChange}
                  type="textarea"
                  className="form-control"
                  id="description"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={event_type}
                  onChange={handleInputChange}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
              </FormGroup>
              <p className='text-danger font-weight-bold'>{eventTypeErrorMsg}</p>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={createDaycareBookingEvent}
                    >
                      Create
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setDaycareBookingModal(false)
                        setCalenderEventData({
                          ...calenderEventData,
                          title: '',
                          description: '',
                          event_type: '',
                          multipleDates: []
                        })
                        setEventTypeErrorMsg('')
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}
      {isCreateEventModalOpen && (
        <div>
          <Modal size="lg" isOpen={isCreateEventModalOpen} backdrop="static">
            <ModalHeader>Block Sitter`s Calendar</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="startDateTime">Start Date Time</Label>
                    <Input
                      name="startDateTime"
                      value={startDateTime}
                      onChange={handleInputChange}
                      type="datetime-local" 
                      className="form-control"
                      id="startDateTime"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="endDateTime">End Date Time</Label>
                    <Input
                      name="endDateTime"
                      value={endDateTime}
                      onChange={handleInputChange}
                      type="datetime-local"
                      className="form-control"
                      id="endDateTime"
                      min={startDateTime}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="title"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <Input
                  name="description"
                  value={description}
                  onChange={handleInputChange}
                  type="textarea"
                  className="form-control"
                  id="description"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={event_type}
                  onChange={handleInputChange}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
              </FormGroup>
              <p className='text-danger font-weight-bold'>{eventTypeErrorMsg}</p>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={createFilterEvent}
                    >
                      Create
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setIsCreateEventModalOpen(false)
                        setCalenderEventData({
                          ...calenderEventData,
                          title: '',
                          description: '',
                          event_type: '',
                          startDateTime: '',
                          endDateTime:''
                        })
                        setEventTypeErrorMsg('')
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {/* {userInfoModal && (
        <div>
          <Modal size="lg" isOpen={userInfoModal} backdrop="static">
            <ModalHeader>Sitter`s Calendar Events Data</ModalHeader>
            <ModalBody>
              <Row className='justify-content-end pe-3'>
                {updateEventDisabled && eventType === 'in_active' ? null : (
                  <Button onClick={() => {setUpdateEventDisabled(false)}} style={{ width:'auto' }} color='primary'>Edit</Button>
                )}
                <Button 
                  onClick={() => {setShowEventAlert(true)}} 
                  style={{ width:'auto', marginLeft: '10px' }} 
                  color='danger'>Delete</Button>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="eventStartDateTime">Start Date Time</Label>
                    <Input
                      name="eventStartDateTime"
                      value={eventStartDateTime}
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          eventStartDateTime: e.target.value
                          
                        })
                      }}
                      type="datetime-local"
                      className="form-control"
                      id="eventStartDateTime"
                      disabled={updateEventDisabled}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="eventEndDateTime">End Date Time</Label>
                    <Input
                      name="eventEndDateTime"
                      value={eventEndDateTime}
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          eventEndDateTime: e.target.value
                        })
                      }}
                      type="datetime-local"
                      className="form-control"
                      id="eventEndDateTime"
                      min={startDateTime}
                      disabled={updateEventDisabled}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="updatedTitle">Title</Label>
                <Input
                  name="updatedTitle"
                  value={eventTitle}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventTitle: e.target.value
                    })
                  }}
                  type="text"
                  className="form-control"
                  id="updatedTitle"
                  disabled={updateEventDisabled}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="updatedDescription">Description</Label>
                <Input
                  name="updatedDescription"
                  value={eventDescription}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventDescription: e.target.value
                    })
                  }}
                  type="textarea"
                  className="form-control"
                  id="updatedDescription"
                  disabled={updateEventDisabled}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={eventType}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventType: e.target.value
                    })
                  }}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                  disabled={updateEventDisabled}
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    {!updateEventDisabled && (
                      <Button
                        className="btn btn-info w-75"
                        type="button"
                        onClick={updateCalenderDataByUserId}
                      >
                         Update
                      </Button>
                    )}
                    
                  </div>
                  <div className="col-6">
                    
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setEventData({
                          eventTitle: '',
                          eventType: '',
                          eventDescription: '',
                          eventStartDateTime: '',
                          eventEndDateTime: ''
                        })
                        setUserInfoModal(false)
                        setUpdateEventDisabled(true)
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )} */}
    </>
  )
}

export default BookingSitterSearch
