import { useEffect, useState } from 'react'
import PaymentList from 'Components/PaymentList'
import { paymentListTableHeading } from '../../tableCol.js'
import { getUsersPaymentListData, resendInvoice, updatePaymentNotes, expireInvoice} from '../../Api'
import SweetAlert from 'react-bootstrap-sweetalert'
import SitterStatus from 'Components/statusNote/index.jsx'


const UsersPaymentList = () => {
  const [usersList, setUsersList] = useState([])
  const [paymentId, setPaymentId] = useState('')
  const [searchData, setSearchData] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showExpireAlert, setShowExpireAlert] = useState(false)
  const [upadtedStatus, setUpadtedStatus] = useState('')
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [isCsvLoaded, setIsCsvLoaded] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const handleGetPaymentData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUsersPaymentListData(queryParams)
      setUsersList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    handleGetPaymentData({ page, search: searchData, status: statusFilter })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleGetPaymentData({ page:1 , search: val, status: statusFilter })
  }

  const handleChangeFilter = (e) => {
    setStatusFilter(e.target.value)
    handleGetPaymentData({ page:1 , search: searchData, status: e.target.value })
  }

  const handleResendInvoice = () => {
    resendInvoice(paymentId).then((res) => {
      res.data.response_code == 80 && setShowAlert(false)
    })
  }

  const handleExpireInvoice = () => {
    expireInvoice(paymentId).then((res) => {
      res.data.response_code == 80 && setShowExpireAlert(false)
      handleGetPaymentData({ page: 1, search: '', status: '' })
    })
  }

  const handleUpdateStatusData = (list, id) => {
    setUpadtedStatus(list.notes)
    setPaymentId(id)
    setIsNotesModalOpen(true)
  }

  const handleUpdateStatusReq = () => {
    updatePaymentNotes({ 'notes': upadtedStatus }, paymentId).then((res) => {
      res.data.response_code == 80 && setIsNotesModalOpen(false), setUpadtedStatus(''),
      handleGetPaymentData({ page: 1, search: '', status: '' })
    })
  }

  const filterCsvData = (csvData) => {
    const data = csvData.map((obj) => ({
      BookingId: obj.booking_id, ServiceType: obj.service_type,
      ParentName: obj.parent_fullname, Email: obj.parent_email, DogsName: obj.dogs,
      Date: obj.date, Amount: obj.amount, Status: obj.status }))
    return data
  }

  const handleDownloadCsvData = () => {
    getUsersPaymentListData({
      page:1 ,
      search: searchData,
      status: statusFilter,
      page_size: 200
    }).then((res) => {
      console.log(res,'fffff')
      if(res.data.response_code == 80){
      // setCsvData(res.data.results)
        setIsCsvLoaded(true)
      }})
  }

  useEffect(() => {
    handleGetPaymentData({ page: 1, search: '', status: '', page_size: '' })
  }, [])

  return(
    <>
      <PaymentList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={usersList}
        tableHeading={paymentListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        searchData={searchData}
        handleSearch={handleSearch}
        handleChangeFilter={handleChangeFilter}
        filterValue={statusFilter}
        handleResendInvoice={(id) => {setPaymentId(id), setShowAlert(true)}}
        handleUpdateStatus={handleUpdateStatusData}
        handleExpireInvoice={(id) => {setPaymentId(id), setShowExpireAlert(true)}}
        csvData={filterCsvData(usersList)}
        isCsvLoaded={isCsvLoaded}
        handleCallDataForCsv={handleDownloadCsvData}
        handleClearFilter={() => {
          setStatusFilter('')
          setSearchData('')
          handleGetPaymentData({ page: 1, search: '', status: '' })
        }}
      />

      {isNotesModalOpen && <SitterStatus
        isOpen={isNotesModalOpen}
        sitterStatus={upadtedStatus}
        handleChangeStatus={(e) => setUpadtedStatus(e.target.value)}
        handleUpdateStatusReq={handleUpdateStatusReq}
        handleClose={() => {setIsNotesModalOpen(false), setUpadtedStatus('')}}
      />}

      {showAlert &&
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          reverseButtons={true}
          onConfirm={handleResendInvoice}
          onCancel={() => setShowAlert(false)}
        ></SweetAlert>
      }
      {showExpireAlert &&
        <SweetAlert
          title="Are you sure, you want to expire this link?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          reverseButtons={true}
          onConfirm={handleExpireInvoice}
          onCancel={() => setShowExpireAlert(false)}
        ></SweetAlert>}

    </>
  )

}

export default UsersPaymentList