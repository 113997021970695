import axios from 'axios'
import Calender from 'Components/FullCalender'
import RegisteredSitterList from 'Components/RegisteredUserList'
import ViewSitterProfile from 'Components/ViewSitterProfile'
import { useEffect, useMemo, useState, useRef } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import {
  Button,
  FormGroup,
  Input,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import {
  createCalenderEventreq,
  createDaycareBookingCalenderEventreq,
  deleteCalenderEventreq,
  editCalenderEventreq,
  getCalenderEventByIdreq,
  getCalenderEventreq,
  sendNotificationReq,
} from 'Views/Dashboard/Views/Filters/Filter.Apis'
import { SitterUsersApprovedListTableHeading, SitterUsersKycListTableHeading } from '../../Constants'
import { toast } from 'react-toastify'
import {
  approveApplication2,
  clearUserActiveStatusDataByIdReq,
  deleteRejectedSitterListreq,
  getEditUserDataByIdReq,
  getRegisteredSitterListreq,
  getUserDataByIdReq,
  upadteUserActiveStatusDataByIdReq,
  updateSitterDoc1req,
  updateSitterDoc2req,
  updateSitterInforeq,
} from '../../Users.Apis'
import * as moment from 'moment'
import EditSitterProfileData from 'Components/EditSitterprofile'
import SitterStatus from 'Components/statusNote'
import DatePicker from 'react-multi-date-picker'
import NewDateTimePicker from 'Components/NewDateTimeComp'

const RegisteredUsersList = () => {
  const [usersList, setUsersList] = useState([])
  const [totalUserCount, setTotalUserCount] = useState(0)
  const [searchData, setSearchData] = useState('')
  const [userId, setUserId] = useState('')
  const [id, setId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isUploadModal, setIsUploadModal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)
  const [isCreateCalenderModalOpen, setIsCreateCalenderModalOpen] = useState(false)
  const [isSitterStatusModalOpen, setIsSitterStatusModalOpen] = useState(false)
  const [daycareBookingModal, setDaycareBookingModal] = useState(false)
  const [reasonforRejection, setReasonforRejection] = useState('')
  const [isRejcetModal, setIsRejcetModal] = useState(false)
  const [eventsData, setEventsData] = useState([])
  const [sitter, setSitter] = useState([])
  const [firstCalenderBooked, setFirstCalenderBooked] = useState('')
  const [is_inactive, setIsInactive] = useState('')
  const [notificationUserName, setNotificationUserName] = useState('')
  const [eventId, setEventId] = useState('')
  const [eventTypeErrorMsg, setEventTypeErrorMsg] = useState()
  const [showEventAlert, setShowEventAlert] = useState(false)
  const [showEventConfirm, setShowEventConfirm] = useState(false)
  const [isUploadAlertModal, setIsUploadAlertModal] = useState(false)
  const [calenderEventData, setCalenderEventData] = useState({
    title: '',
    description: '',
    event_type: '',
    start_time: '',
    end_time: '',
    startDateTime: '',
    endDateTime: '',
    multipleDates: []
  })
  const { title, description, event_type, startDateTime, endDateTime, multipleDates  } = calenderEventData

  const [imageUrl1, setImageUrl1] = useState(null)
  const [imageUrl2, setImageUrl2] = useState('')
  const [selectedDates, setSelectedDate] = useState()
  const [userInfoModal, setUserInfoModal] = useState(false)
  const [eventData, setEventData] = useState({
    eventTitle: '',
    eventType: '',
    eventDescription: '',
    eventStartDateTime: '',
    eventEndDateTime: ''
  })
  const { eventTitle, eventType, eventDescription, eventStartDateTime, eventEndDateTime } = eventData
  const [updateEventDisabled, setUpdateEventDisabled] = useState(true)
  const [sitterNameByEvent, setSitterNameByEvent] = useState('')
  const [inActiveData, setInActiveData] = useState({
    statusEndDate: '',
    userName: ''
  })
  const { statusEndDate, userName } = inActiveData
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  })
  const { currentPage, totalSize } = paginationValue

  const [upadtedStatus, setUpadtedStatus] = useState('')


  const [notifictionUserId, setNotifictionUserId] = useState('')
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [notificationFormData, setNotificationFormData] = useState({
    wix_id: '', service_type: ''
  })
  const { wix_id,  service_type } = notificationFormData

  const [sortOrderParam, setSortOrder] = useState('')
  const [sortFieldParam, setSortField] = useState('')
  const [isCsvLoaded, setIsCsvLoaded] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [updatedDates, setUpdatedDates] =useState()

  const handleSitterUsersKycData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getRegisteredSitterListreq(queryParams)
      setUsersList(data.data.results)
      setTotalUserCount(data.data.count)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize : data.data.count
      })
      setIsLoading(false)
      return data
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleSitterUsersKycData(
      { page: 1, search: '' , event_updated: '', is_inactive: '', sort_by: '', sort_order: '', page_size: '' }
    )
  }, [])

  const getDataByUserId = async(UserId) => {
    try {
      const { data } = await getUserDataByIdReq(UserId)
      setSitter(data.data)
      setUpadtedStatus(data.data.admin_notes)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleCreateModel = () => {
    setIsCreateModalOpen(true)
  }

  const handleCloseModal = () => {
    setSitter([])
    setIsCreateModalOpen(false)
  }

  const handleViewSitterData = (UserId) => {
    getDataByUserId(UserId)
    toggleCreateModel()
  }

  const handleEditSitterData = async(UserId) => {
    try {
      const { data } = await getEditUserDataByIdReq(UserId)
      setSitter(data.data)
      setUserId(UserId)
      setIsLoading(false)
      setIsEditModal(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdloadSitterDoc = (UserId) => {
    setUserId(UserId)
    setIsUploadModal(true)
  }

  const UploadImage = async(image) => {
    return await axios
      .post('https://api.dogstays.lu/basic/upload/image', image)
      .then((res) => {
        setImageUrl1(res.data.data.url)
      })
  }

  const handleImage = (e) => {
    const uploadFile = e.target.files[0]
    const fileSize = e.target.files[0].size
    const imageFormData = new FormData()
    imageFormData.append('image', uploadFile)
    console.log(imageFormData)
    fileSize < '5000000'
      ? UploadImage(imageFormData)
      : setIsUploadAlertModal(true)
  }

  const UploadImage2 = async(image) => {
    return await axios
      .post('https://api.dogstays.lu/basic/upload/image', image)
      .then((res) => {
        setImageUrl2(res.data.data.url)
      })
  }

  const handleImage2 = (e) => {
    const uploadFile = e.target.files[0]
    const fileSize = e.target.files[0].size
    const imageFormData = new FormData()
    imageFormData.append('image', uploadFile)
    fileSize < '5000000'
      ? UploadImage2(imageFormData)
      : setIsUploadAlertModal(true)
  }

  const toggleCreateCalenderModel = () => {
    setIsCreateCalenderModalOpen(true)
  }

  const handleCloseCalenderModal = () => {
    setIsCreateCalenderModalOpen(false)
  }

  const getCalenderDataByUserId = async(UserId) => {
    try {
      const { data } = await getCalenderEventreq(UserId)
      setEventsData(data.data)
      setSitterNameByEvent(data.sitter_name)
      setInActiveData({
        ...inActiveData,
        userName: data.in_active_status_text
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const ViewCalender = (UserId) => {
    getCalenderDataByUserId(UserId)
    setId(UserId)
    toggleCreateCalenderModel()
  }

  const handleInputChange = (e) => {
    setCalenderEventData({
      ...calenderEventData,
      [e.target.name]: e.target.value,
    })
  }

  const createFilterEvent = async() => {
    if(event_type == '' ){
      setEventTypeErrorMsg('Please select the event Type')
    }else{
      const { data } = await createCalenderEventreq(
        {
          title,
          description: description,
          start_time: `${updatedDates.startDate}T${updatedDates.startTime}`,
          end_time: `${updatedDates.endDate}T${updatedDates.endTime}`,
          event_type,
        },
        id
      )
      setIsOpen(false)
      setCalenderEventData({
        title: '',
        description: '',
        event_type: '',
        startDateTime: '',
        endDateTime: ''
      })
      setUpdatedDates()
      setEventTypeErrorMsg('')
      getCalenderDataByUserId(id)
    }
  }
  const formatMultiSelectDate = (data) => {
    const formatedData = data.map((value) => value.year + '-' + value.month + '-' + value.day)
    return formatedData
  }
  const createDaycareBookingEvent = async() => {
    if(event_type == ''){
      setEventTypeErrorMsg('Please select the event type')
    }else{
      const { data } = await createDaycareBookingCalenderEventreq(
        {
          title,
          description: description,
          multiple_dates: formatMultiSelectDate(multipleDates),
          event_type,
        },
        id
      )
      setDaycareBookingModal(false)
      setCalenderEventData({
        title: '',
        description: '',
        event_type: '',
        multipleDates: []
      })
      setEventTypeErrorMsg('')
      getCalenderDataByUserId(id)
    }
  }

  const select = async(start) => {
    setSelectedDate({
      ...selectedDates, 
      startDate:  start.startStr,
      endDate: moment(new Date(start.endStr)).subtract(1, 'days').format('YYYY-MM-DD'),
      startTime: '00:00',
      endTime: '23:59'
    })  
    setCalenderEventData({
      ...calenderEventData,
      startDateTime : moment(new Date(start.startStr)).format('YYYY-MM-DDT00:00'),
      endDateTime : moment(new Date(start.endStr)).subtract(1, 'days').format('YYYY-MM-DDT23:59')
    })  
    
    setIsOpen(true)
  }

  const enableEventAlert = async(e) => {
    setEventId(e.event._def.extendedProps.event_id)
    try {
      const { data } = await getCalenderEventByIdreq(e.event._def.extendedProps.event_id)
      setEventData({
        eventTitle : data.data.title,
        eventType: data.data.event_type,
        eventDescription: data.data.description,
        eventStartDateTime: (data.data.start).slice(0, 16),
        eventEndDateTime: (data.data.end).slice(0, 16)
      })
      setSelectedDate({
        ...selectedDates, 
        startDate:  (data.data.start).split('T')[0],
        endDate: (data.data.end).split('T')[0],
        startTime: (data.data.start).split('T')[1].slice(0, 5),
        endTime: (data.data.end).split('T')[1].slice(0, 5)
      }) 
      setUserInfoModal(true)
    } catch (error) {
      console.error(error)
    }
  }

  const removeEvent = async() => {
    console.log(eventId)
    await deleteCalenderEventreq(eventId)
    getCalenderDataByUserId(id)
    setShowEventAlert(false)
    setShowEventConfirm(true)
  }

  const updateCalenderDataByUserId = async() => {
    try {
      const { data } = await editCalenderEventreq(eventId,{
        title: eventTitle,
        description: eventDescription,
        event_type: eventType,
        start_time: `${updatedDates.startDate}T${updatedDates.startTime}`,
        end_time: `${updatedDates.endDate}T${updatedDates.endTime}`
      })
      setUserInfoModal(false)
      setUpdatedDates()
      getCalenderDataByUserId(id)
      setUpdateEventDisabled(true)
    } catch (error) {
      console.log(error)
    }
  }


  const UploadDoc1 = async() => {
    const { data } = await updateSitterDoc1req(
      {
        passport_image: imageUrl1,
      },
      userId
    )
    data.response_code === 80 ? alert('Document Uploaded') : null
  }

  const UploadDoc2 = async() => {
    const { data } = await updateSitterDoc2req(
      {
        address_proof_image: imageUrl2,
      },
      userId
    )
    data.response_code === 80 ? alert('Document Uploaded') : null
  }



  const handleRejectSitter = (UserId) => {
    setUserId(UserId)
    setIsRejcetModal(true)
  }

  const rejectApplication = async() => {
    try {
      await approveApplication2( { 'is_approved': false, 'reason_of_rejection': reasonforRejection  } , userId )
    } catch (error) {
      console.log(error)
    }
  }


  const handleRejectReq = () => {
    rejectApplication()
    handleSitterUsersKycData({ page: 1, search: '' , event_updated: '', is_inactive: '', page_size: '' })
    setIsRejcetModal(false)
  }

  const handleTablePageChangeTags = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type == 'sort'){
      setSortOrder(sortOrder)
      setSortField(sortField)
    }
    handleSitterUsersKycData({ page, search: searchData,event_updated: firstCalenderBooked,
      is_inactive: is_inactive, sort_by: sortField, sort_order: sortOrder, page_size: '' })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleSitterUsersKycData({ page:1 , search: val, event_updated: firstCalenderBooked,
      is_inactive: is_inactive, sort_by: sortFieldParam, sort_order: sortOrderParam, page_size: '' })
    setIsCsvLoaded(false)
  }

  const handleFirstCalenderBooked = async(e) => {
    try{
      setIsLoading(true)
      const val = e.target.value
      setFirstCalenderBooked(val)
      await handleSitterUsersKycData({ page:1 , event_updated: val, search: searchData,
        is_inactive: is_inactive, sort_by: sortFieldParam, sort_order: sortOrderParam, page_size: '' })
      setIsCsvLoaded(false)
      setIsLoading(false)
    }catch(err){
      console.log(err)
    }
  }

  const handleIsActive = async(e) => {
    try{
      setIsLoading(true)
      const val = e.target.value
      setIsInactive(val)
      await handleSitterUsersKycData({ page:1 , event_updated: firstCalenderBooked, search: searchData, is_inactive: val, 
        sort_by: sortFieldParam, sort_order: sortOrderParam, page_size: '' })
      setIsCsvLoaded(false)
      setIsLoading(false)
    }catch(err){
      console.log(err)
    }
  }

  const sendNotification = (UserId, userName) => {
    setNotifictionUserId(UserId)
    setNotificationUserName(userName)
    setShowNotificationModal(true)
  }
  const handleSendNoticationById = async() => {
    const response = await sendNotificationReq(
      {
        wix_id,
        user_id: notifictionUserId,
        service_type
      }
    )
    if (response.data.response_code === 90) {
      toast.error(response.data.developer_message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
    else{
    
      setShowNotificationModal(false)
      setNotifictionUserId('')
      setNotificationFormData({ ...notificationFormData, wix_id: '', service_type: '' })
    }
  }

  const filterCsvData = (usersList) => {
    const data = usersList.map((obj) => ({
      FirstName: obj.first_name, LastName: obj.last_name, FullName: `${obj.first_name} ${obj.last_name}`, 
      Email: obj.email, FullAddress: obj.full_address, Mobile: obj.mobile }))
    return data
  }

  const getInActiveuserStatus = (e) => {
    setInActiveData({
      ...inActiveData,
      statusEndDate: e.target.value
    })
  }

  const submitInActiveStatus = async() => {
    const { data } = await upadteUserActiveStatusDataByIdReq({ 
      title: 'INACTIVE',
      description: 'INACTIVE',
      end_time: statusEndDate
    },
    id
    )
    getCalenderDataByUserId(id)
  }

  const clearInActiveStatus = async() => {
    const { data } = await clearUserActiveStatusDataByIdReq(id)
    getCalenderDataByUserId(id)
  }

  const handleViewSitterStatus = async(UserId) => {
    await getDataByUserId(UserId)
    setUserId(UserId)
    setIsSitterStatusModalOpen(true)
  }

  const handleChangeStatus = (e) => {
    setUpadtedStatus(e.target.value)
  }

  const handleUpdateStatusReq = async() => {
    try {
      await updateSitterInforeq({
        admin_notes: upadtedStatus
      }, userId)
      setIsSitterStatusModalOpen(false)
    } catch (error) {
      console.log(error)
    }
  }
  const ref = useRef()

  const handleDownloadCsvData = () => {
    handleSitterUsersKycData(
      { page: 1, event_updated: firstCalenderBooked, search: searchData, is_inactive: is_inactive, 
        sort_by: sortFieldParam, sort_order: sortOrderParam, page_size: 200 }
    ).then((res) => {
      console.log(res)
      if(res.response_code == 80){
        setCsvData(res.data.results)
        setIsCsvLoaded(true)
      }
    })
  }
  return (
    <>
      <RegisteredSitterList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={usersList}
        totalCount={totalUserCount}
        is_first_calender_booked={firstCalenderBooked}
        handleFirstCalenderBooked={handleFirstCalenderBooked}
        is_inactive={is_inactive}
        handleIsActive={handleIsActive}
        tableHeading={SitterUsersApprovedListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        handleViewSitterData={handleViewSitterData}
        handleEditSitterData={handleEditSitterData}
        handleUpdloadSitterDoc={handleUpdloadSitterDoc}
        handleRejectSitter={handleRejectSitter}
        handleViewSitterStatus={handleViewSitterStatus}
        ViewCalender={ViewCalender}
        searchData={searchData}
        handleSearch={handleSearch}
        csvData={filterCsvData(usersList)}
        isCsvLoaded={isCsvLoaded}
        handleCallDataForCsv={handleDownloadCsvData}
        sendNotification={sendNotification}
      />

      <ViewSitterProfile
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        sitterData={sitter}
        handleClose={handleCloseModal}
      />

      <EditSitterProfileData
        isOpen={isEditModal}
        handleClose={() => setIsEditModal(false)}
        sitterData={sitter}
        userId={userId}
      />

      <SitterStatus
        isOpen={isSitterStatusModalOpen}
        sitterStatus={upadtedStatus}
        handleChangeStatus={handleChangeStatus}
        handleUpdateStatusReq={handleUpdateStatusReq}
        handleClose={() => setIsSitterStatusModalOpen(false)}
      />
      <Calender
        isOpen={isCreateCalenderModalOpen}
        toggleModal={toggleCreateCalenderModel}
        handleClose={handleCloseCalenderModal}
        eventsData={eventsData}
        selectable={true}
        select={select}
        editable={true}
        timeZone="UTC"
        eventClick={enableEventAlert}
        sitterName={sitterNameByEvent}
        inActiveEndDate={getInActiveuserStatus}
        statusEndDate={statusEndDate}
        submitInActiveStatus={submitInActiveStatus}
        clearInActiveStatus={clearInActiveStatus}
        userActiveStatusText={userName}
        handleCreateDaycareModal={() => {setDaycareBookingModal(true)}}
      />
      {showEventAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={removeEvent}
          onCancel={() => setShowEventAlert(false)}
        ></SweetAlert>
      ) : null}

      {showEventConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowEventAlert(false)
            setShowEventConfirm(false)
            setUserInfoModal(false)
          }}
        >
          Event has been Deleted
        </SweetAlert>
      ) : null}

      {daycareBookingModal && (
        <div>
          <Modal size="lg" isOpen={daycareBookingModal} backdrop={true} toggle={()=>   
                               setDaycareBookingModal(false)}>
            <ModalHeader>Block Sitter`s Calendar</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Select Dates</Label>
                    <DatePicker 
                      ref={ref}
                      className='d-block'
                      multiple
                      onChange={(e) => {setCalenderEventData({
                        ...calenderEventData,
                        multipleDates : e
                      })}}
                      value={multipleDates}
                      dateFormat="YYYY-MM-DD"
                      shouldCloseOnSelect={true}
                    >
                      <Button className='py-1' style={{ position: 'relative', bottom: '5px' }}
                        color='primary' onClick={() => ref.current.closeCalendar()}>
                      close
                      </Button>
                    </DatePicker>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="title"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <Input
                  name="description"
                  value={description}
                  onChange={handleInputChange}
                  type="textarea"
                  className="form-control"
                  id="description"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={event_type}
                  onChange={handleInputChange}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
              </FormGroup>
              <p className='text-danger font-weight-bold'>{eventTypeErrorMsg}</p>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={createDaycareBookingEvent}
                    >
                      Create
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setDaycareBookingModal(false)
                        setCalenderEventData({
                          ...calenderEventData,
                          title: '',
                          description: '',
                          event_type: '',
                          multipleDates: []
                        })
                        setEventTypeErrorMsg('')
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {isRejcetModal && (
        <div>
          <Modal size="lg" isOpen={isRejcetModal} backdrop="static">
            <ModalHeader>Delete Action</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="reason_of_reject">Reason For Rejection</Label>
                <Input
                  name="reason_of_reject"
                  onChange={(e) => {
                    setReasonforRejection(e.target.value)
                  }}
                  type="textarea"
                  className="form-control"
                  id="reason_of_reject"
                />
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={handleRejectReq}
                    >
                      Reject
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setIsRejcetModal(false)
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {userInfoModal && (
        <div>
          <Modal size="lg" isOpen={userInfoModal} backdrop="static">
            <ModalHeader>Sitter`s Calendar Events Data</ModalHeader>
            <ModalBody>
              <Row className='justify-content-end pe-3'>
                {updateEventDisabled && eventType === 'in_active' ? null : (
                  <Button onClick={() => {setUpdateEventDisabled(false)}} style={{ width:'auto' }} color='primary'>Edit</Button>
                )}
                <Button 
                  onClick={() => {setShowEventAlert(true)}} 
                  style={{ width:'auto', marginLeft: '10px' }} 
                  color='danger'>Delete</Button>
              </Row>
              <NewDateTimePicker selectedDates={selectedDates} isEditMode={!updateEventDisabled}
                updatedDateTime={(data) => setUpdatedDates(data)}/>
              {/* <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="eventStartDateTime">Start Date Time</Label>
                    <Input
                      name="eventStartDateTime"
                      value={eventStartDateTime}
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          eventStartDateTime: e.target.value
                          
                        })
                      }}
                      type="datetime-local"
                      className="form-control"
                      id="eventStartDateTime"
                      disabled={updateEventDisabled}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="eventEndDateTime">End Date Time</Label>
                    <Input
                      name="eventEndDateTime"
                      value={eventEndDateTime}
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          eventEndDateTime: e.target.value
                        })
                      }}
                      type="datetime-local"
                      className="form-control"
                      id="eventEndDateTime"
                      min={startDateTime}
                      disabled={updateEventDisabled}
                    />
                  </FormGroup>
                </Col>
              </Row> */}
              <FormGroup>
                <Label htmlFor="updatedTitle">Title</Label>
                <Input
                  name="updatedTitle"
                  value={eventTitle}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventTitle: e.target.value
                    })
                  }}
                  type="text"
                  className="form-control"
                  id="updatedTitle"
                  disabled={updateEventDisabled}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="updatedDescription">Description</Label>
                <Input
                  name="updatedDescription"
                  value={eventDescription}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventDescription: e.target.value
                    })
                  }}
                  type="textarea"
                  className="form-control"
                  id="updatedDescription"
                  disabled={updateEventDisabled}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={eventType}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventType: e.target.value
                    })
                  }}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                  disabled={updateEventDisabled}
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    {!updateEventDisabled && (
                      <Button
                        className="btn btn-info w-75"
                        type="button"
                        onClick={updateCalenderDataByUserId}
                      >
                         Update
                      </Button>
                    )}
                    
                  </div>
                  <div className="col-6">
                    
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setEventData({
                          eventTitle: '',
                          eventType: '',
                          eventDescription: '',
                          eventStartDateTime: '',
                          eventEndDateTime: ''
                        })
                        setUserInfoModal(false)
                        setUpdateEventDisabled(true)
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {isOpen && (
        <div>
          <Modal size="lg" isOpen={isOpen} backdrop="static">
            <ModalHeader>Block Sitter`s Calendar</ModalHeader>
            <ModalBody>
              <NewDateTimePicker selectedDates={selectedDates} isEditMode={true}
                updatedDateTime={(data) => setUpdatedDates(data)}/>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control"
                  id="title"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <Input
                  name="description"
                  value={description}
                  onChange={handleInputChange}
                  type="textarea"
                  className="form-control"
                  id="description"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={event_type}
                  onChange={handleInputChange}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
              </FormGroup>
              <p className='text-danger font-weight-bold'>{eventTypeErrorMsg}</p>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={createFilterEvent}
                    >
                      Create
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setIsOpen(false)
                        setCalenderEventData({
                          ...calenderEventData,
                          title: '',
                          description: '',
                          event_type: '',
                          startDateTime: '',
                          endDateTime:''
                        })
                        setEventTypeErrorMsg('')
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {isUploadModal && (
        <div>
          <Modal size="lg" isOpen={isUploadModal} backdrop="static">
            <ModalHeader>Upload Docs for Sitter</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="comfortable_with_cat_or_other">
                  Identification Doucment (EU ID/Passport)
                </Label>
                <Input
                  type="file"
                  accept=".jpg,.jpeg,.png,.pdf,.webp,.doc"
                  onChange={(e) => handleImage(e)}
                />
                <p className="text-end text-size">Max Size: 5MB</p>
              </FormGroup>

              {imageUrl1 && (
                <div className="text-center">
                  <h4 className="mt-4">
                    {' '}
                    Document Uploaded :{' '}
                    <img
                      src="https://s8.gifyu.com/images/successful.gif"
                      alt="successful.gif"
                      border="0"
                      style={{ width: '33px' }}
                    />
                  </h4>
                  <a
                    className="btn btn-primary"
                    href={imageUrl1}
                    alt="View Image"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Document
                  </a>
                  <button className="btn btn-primary ms-2" onClick={UploadDoc1}>
                    Upload
                  </button>
                  <p className="text-center text-size mt-2">
                    To upload new file click on choose file
                  </p>
                </div>
              )}

              <FormGroup>
                <Label htmlFor="comfortable_with_cat_or_other">
                  Address Proof
                </Label>
                <Input
                  type="file"
                  accept=".jpg,.jpeg,.png,.pdf,.webp,.doc"
                  onChange={(e) => handleImage2(e)}
                />
                <p className="text-end text-size">Max Size: 5MB</p>
              </FormGroup>
              {imageUrl2 && (
                <div className="text-center">
                  <h4 className="mt-4">
                    {' '}
                    Document Uploaded :{' '}
                    <img
                      src="https://s8.gifyu.com/images/successful.gif"
                      alt="successful.gif"
                      border="0"
                      style={{ width: '33px' }}
                    />
                  </h4>
                  <a
                    className="btn btn-primary"
                    href={imageUrl2}
                    alt="View Image"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Document
                  </a>
                  <button className="btn btn-primary ms-2" onClick={UploadDoc2}>
                    Upload
                  </button>
                  <p className="text-center text-size mt-2">
                    To upload new file click on choose file
                  </p>
                </div>
              )}
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    {/* <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={createFilterEvent}
                    >
                      Create
                    </Button> */}
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setIsUploadModal(false)
                        window.location.reload()
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {isUploadAlertModal && (
        <div>
          <Modal
            size="lg"
            isOpen={isUploadAlertModal}
            centered
            backdrop="static"
          >
            <ModalBody>
              <h4 className="alert-msg text-center">
                This file can`t be uploaded
              </h4>
              <h6 className="alert-msg text-center">
                Only Max size upto 5Mb can be uploaded
              </h6>
              <h6 className="alert-msg text-center">
                Click on choose file to select image again
              </h6>
              <div className="col-12 text-center">
                <Button
                  className="btn btn-danger"
                  onClick={() => {
                    setIsUploadAlertModal(false)
                  }}
                >
                  Close
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
      {showNotificationModal && 
        <div>
          <Modal size="lg" isOpen={showNotificationModal} backdrop="static">
            <ModalHeader>Send Booking Information - {notificationUserName}</ModalHeader>
            <ModalBody>
              <Row className='justify-content-center'>
                <div className='w-50'>
                  <FormGroup>
                    <Label htmlFor="wix_id">Dog parent email</Label>
                    <Input
                      name="wix_id"
                      value={wix_id}
                      onChange={(e) => {setNotificationFormData({ ...notificationFormData, wix_id: e.target.value })}}
                      type="text"
                      className="form-control"
                      id="wix_id"
                    />
                    <Label htmlFor="wix_id">Service Type</Label>
                    <Input
                      name="service_type"
                      value={service_type}
                      onChange={(e) => {setNotificationFormData({ ...notificationFormData, service_type: e.target.value })}}
                      type="select"
                      className="form-control"
                      id="service_type"
                    >
                      <option hidden>Select</option>
                      <option value="overnight_sitter_home">
                      Overnight Boarding Sitter`s Home
                      </option>
                      <option value="overnight_parent_home">
                      Overnight Boarding Dog Parent`s Home
                      </option>
                      <option value="daycare_sitter_home">Daycare Sitter`s Home</option>
                      <option value="daycare_parent_home">
                      Daycare Dog Parent`s Home
                      </option>
                    </Input>
                  </FormGroup>
                </div>
              </Row>
              <ModalFooter>
                <div className='row w-100 justify-content-center'>
                  <div className='col-6 text-center'>
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={handleSendNoticationById}
                    >
                      Send
                    </Button>
                  </div>
                  <div className='col-6 text-center'>
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => { 
                        setShowNotificationModal(false)
                        setNotificationFormData({ ...notificationFormData, wix_id: '', service_type: '' })
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      }
    </>
    
  )
}

export default RegisteredUsersList
