import { useEffect, useState } from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { updateSitterInforeq } from 'Views/Dashboard/Views/Users/Users.Apis'

const SitterStatus = (
  {
    isOpen,
    title,
    sitterStatus,
    handleChangeStatus,
    handleUpdateStatusReq,
    handleClose,
    alertMsg
  }
) => {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>{title || 'Sitter Last Status'}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="admin_notes">Status <span style={{ color: 'red' }} >*</span></Label>
          <Input
            name="admin_notes"
            value={sitterStatus}
            onChange={handleChangeStatus}
            type="textarea"
            className="form-control"
            id="admin_notes"
          />
        
        </FormGroup>
        <p style={{ color:'red' , position:'absolute' ,bottom:'4.5rem ',left:'1.2rem' , fontSize:'12px' }}>
        {alertMsg}</p>
        <ModalFooter>
          <div className="row w-100">
            <div className="col-6">
              <Button
                className="btn btn-info w-75"
                type="button"
                onClick={handleUpdateStatusReq}
              >
                Update
              </Button>
            </div>
            <div className="col-6">
              <Button
                className="btn btn-danger w-75"
                type="button"
                onClick={handleClose}
              >
                close
              </Button>
            </div>
          </div>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}

export default SitterStatus