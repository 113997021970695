import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'

const PendingInvoiceList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleSearch,
  searchData,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleRegenerateAction,
  handleAcceptAction,
  handleRejectAction
}) => {
  const [rowData, setRowData] = useState([])

  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row.user_id}>
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <a href={row.invoice_link} 
          rel='noreferrer' download target='_blank'>
          <button className='btn btn-primary'>Download</button></a>}
      </div>
    )
  }

  const actionContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row.id}>
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && (!row.is_additional) && <i
          className="ri-refresh-line text-primary h5 cursor-pointer mr-2"
          onClick={() => handleRegenerateAction(row.id)}
          title='Refresh'
        />}
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <i
          className="dripicons-checkmark text-success h4 cursor-pointer mr-2"
          onClick={() => handleAcceptAction(row.id)}
          title='Accept'
        />}
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <i
          className="dripicons-cross text-danger h4 cursor-pointer mr-2"
          onClick={() => handleRejectAction(row.id)}
          title='Reject'
        />}
      </div>
    )
  }
  

  useEffect(() => {
    tableHeading.push({
      text: 'Download',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%', cursor: 'pointer' },
      formatter: viewContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    tableHeading.push({
      text: 'Action',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%', cursor: 'pointer' },
      formatter: actionContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={6}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Pending Invoice</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="searchSitter">Search: </Label>
                <Input
                  style={{ height:'35px', marginLeft: '5px' }}
                  name="searchSitter"
                  value={searchData}
                  onChange={handleSearch}
                  type="text"
                  className="form-control"
                  id="searchSitter"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            {isLoading ? (
                              <div className="text-center py-4">
      <h6 className='text-muted' >Loading...</h6>
      </div>
    ) : rowData.length > 0 ? (  
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            /> ) 
            : (
             <div className="text-center py-4">
               <h6 className="text-muted">No Pending Invoice Available</h6>
             </div>
           )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PendingInvoiceList
