import { getBookingDetailsDataById, getDogDetailsById, submitDoginfo } from 'Views/Dashboard/Views/Parents/Api'
import { useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import Daycare from './BookingType/daycare'
import Overnight from './BookingType/overnight'
import DogCareForm from 'Components/DogCareForm'

const ParentBookingConfirmation = ({ id, handleClose, renderTable }) => {

  const [bookingData, setBookingData] = useState([])
  const [isCareFormModal, setIsCareFormModal] = useState(false)
  const [dogCareFormData, setDogCareFormData] = useState()
  const [dogId, setDogId] = useState('')
  const [step, setStep] = useState(0)
  const [isError, setIsError] = useState('')
  const acceptedConsentDogs = JSON.parse(localStorage.getItem('dogsAcceptConsent'))
  const [locationType, setLocationType] = useState({ sitterLocation: false, parentLocation: false, otherLocation: false })
  const [selectedLocation, setSelectedLocation] = useState('')

  useEffect(() => {
    getBookingDetailsDataById(id).then((res) => {
      setBookingData(res.data.data)
    })
  }, [])


  const handleViewCareForm = (dog_id) => {
    getDogDetailsById(dog_id).then((res) => {
      setDogCareFormData(res.data.data.dog_info_form)
      setDogId(res.data.data.dog_id)
      setIsCareFormModal(true)
    })
  }

  const handleSaveConsentForm = () => {
    if (!selectedLocation) {
      setIsError('Please select a location type');
      return;
    }
  
    if(acceptedConsentDogs?.length == bookingData?.dogs?.length){
      submitDoginfo({
        'final_booking_location': selectedLocation
      }, bookingData.booking_id).then((res) => {
        res.data.response_code == 80 && handleClose(), renderTable(), localStorage.removeItem('dogsAcceptConsent')
      })
    }else setIsError('Please verify all dogs')
  }

  return (
    <>
      <Row className='p-2' style={{ background: '#c3cfd9' }}>
        <Col lg='6'>
          <h4 className='mb-2' style={{ color: '#e8833a', fontWeight: 500 }}>Dog Parent Details </h4>
          <p className='m-0'>Name: <span className='font-bold'>{bookingData?.parent}</span></p>
          <p className='m-0'>Id: <span className='font-bold'>{bookingData?.parent_id}</span></p>
          <p className='m-0'>Email: <span className='font-bold'>{bookingData?.parent_details?.email}</span></p>
          <p className='m-0'>Phone Number:
            <span className='font-bold'>{bookingData?.parent_details?.mobile}</span></p>
          <p className='m-0'>Gender: <span className='font-bold'>{bookingData?.parent_details?.gender}</span></p>
          <p className='m-0'>Language:
            <span className='font-bold'>{bookingData?.parent_details?.preferred_languages}</span></p>
          <p className='m-0'>Whatsapp No:
            <span className='font-bold'>{bookingData?.parent_details?.whatsapp_number}</span></p>
        </Col>

        <Col lg='6'>
          <h4 className='mb-2' style={{ color: '#e8833a', fontWeight: 500 }}>Dog Parent Details </h4>
          {bookingData?.dogs && bookingData?.dogs.map((item, index) => {
            return (
              <div className='mb-3' key={index}>
                <p className='mb-1'>Dog #{index + 1}</p>
                <p className='m-0'>Name: <span className='font-bold'>{item.name}</span></p>
                <p className='m-0'>Gender: <span className='font-bold'>{item.gender}</span></p>
                <p className='m-0'>Breed: <span className='font-bold'>{item.breed}</span></p>
                <p className='m-0'>DOB: <span className='font-bold'>{item.date_of_birth}</span></p>
                <p className='m-0'>Size Category: <span className='font-bold'>{item.size_category}</span></p>
              </div>
            )
          })
          }
        </Col>
      </Row>
      {step == 0 && <>
        {bookingData.service_type == 'Overnight Boarding' && 
        <Overnight bookingData={bookingData} setStep={(e) => setStep(e)}/>}
        {bookingData.service_type == 'Daycare' && 
        <Daycare bookingData={bookingData} setStep={(e) => setStep(e)}/>}
      </>}

      {step == 1 && <> <div className='d-flex gap-3 justify-content-center mt-4'>
        {bookingData && bookingData.dogs.map((item) => {
          const acceptedConsentDogs = JSON.parse(localStorage.getItem('dogsAcceptConsent'))
         
          return (
            <div  key={item.dog_id} style={{ cursor: 'pointer' }} onClick={() => handleViewCareForm(item.dog_id)}>
              <img style={{ width:'150px', height: '150px', borderRadius: '50%', border: '4px solid #f78121' }}
                src={item?.photos?.[0]}/>
              <p className='text-center mb-1'>{item.name}</p>
              {acceptedConsentDogs?.includes(item.dog_id) && 
                <p className='m-0 d-flex align-items-center gap-2 justify-content-center'>
                  <i className='ri-thumb-up-fill h4 text-success'></i> Verified</p>}
            </div>
          )
        })}
      </div>
      <Row>
        <Col xs={4} className='text-center'>
          <div>
            <Label className='text-start w-100'>Where will the service take place? 
              You can only choose one option   
                    <span style={{ color: 'red', fontSize: '20px' }}>*</span>
              </Label>
            <div style={{ display:"grid",justifyContent:"flex-start",gap:"1rem" }}>
              {bookingData && bookingData.location_type.includes('sitter_location') && <div>
                <Input type='checkbox' checked={locationType.sitterLocation}
                  disabled={locationType.parentLocation || locationType.otherLocation} value="sitter" onChange={(e) => {
                    setSelectedLocation(e.target.value)
                    setLocationType({ ...locationType, sitterLocation: !locationType.sitterLocation })
                  }}/> <label>At dog sitter`s home</label>  
              </div>}
              {bookingData && bookingData.location_type.includes('parent_location') && <div>
                <Input type='checkbox' checked={locationType.parentLocation}
                  disabled={locationType.sitterLocation || locationType.otherLocation} value="parent" onChange={(e) => {
                    setSelectedLocation(e.target.value)
                    setLocationType({ ...locationType, parentLocation: !locationType.parentLocation })
                  }}/> <label>At my home address</label>  
              </div>}
              {bookingData && bookingData.location_type.includes('other_address') && <div>
                <Input type='checkbox' checked={locationType.otherLocation}
                  disabled={locationType.parentLocation || locationType.sitterLocation} value="parent" onChange={(e) => {
                    setSelectedLocation(e.target.value)
                    setLocationType({ ...locationType, otherLocation: !locationType.otherLocation })
                  }}/> <label>At a different address</label>  
              </div>}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Button
          type="button"
          color="primary w-25"
          onClick={handleSaveConsentForm}
          className="waves-effect mt-5"
        >
          Submit
        </Button>
        {isError.length > 0 && <p className='text-danger text-center font-bold mt-3'>{isError}</p>}
      </Row>
      </>
      }

      {isCareFormModal && <DogCareForm
        isOpen={isCareFormModal}
        handleClose={() => setIsCareFormModal(false)}
        dogId={dogId}
        careFormData={dogCareFormData}
      />}
      
    </>
  )
}

export default ParentBookingConfirmation
