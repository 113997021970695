import ViewConfigData from 'Components/ViewConfigData'
import { useEffect, useState } from 'react'
import { getPaymentConfigListData } from '../Payments/Api'
import EditOtherConfigData from 'Components/EditConfigOtherData'

const MyConfig = () => {

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenOtherModal, setIsOpenOtherModal] = useState(false)
  const [viewType, setViewType] = useState(false)
  const [viewTypeModal, setViewTypeModal] = useState(false)
  const [configData, setConfigData] = useState([])
  const [isViewModeEnable, setIsViewModeEnable] = useState(false)



  const getConfigListData = () => {
    getPaymentConfigListData().then((res) => {
      setConfigData(res.data.data)
    })
  }

  const toggleOpenEditMode = () => {
    setViewType(false)
    setIsOpenModal(true)
  }

  const toggleOpenViewMode = () => {
    setViewType(true)
    setIsOpenModal(true)
  }

  const toggleOpenOtherConfigEditMode = (type, disabled) => {
    setIsViewModeEnable(disabled)
    setViewTypeModal(type)
    setIsOpenOtherModal(true)
  }

  useEffect(() => {
    getConfigListData()
  },[])

  return(
    <>
      <div className="config-container">
        <div className="config-wrapper">
          <div className="d-flex justify-content-between">
            <h5>Prices of services</h5>
            <span>
              <i className="ri-eye-line text-info h4 cursor-pointer mr-2" onClick={toggleOpenViewMode} title='View'/>
              <i className="ri-edit-2-fill text-warning h4 cursor-pointer me-2" onClick={toggleOpenEditMode} title='Edit'/>
            </span>
          </div>
          <hr style={{ margin: '5px 0px' }}/>
          <div className="d-flex justify-content-between">
            <h5>Automatic Booking Discount</h5>
            <span>
              <i className="ri-eye-line text-info h4 cursor-pointer mr-2" 
                onClick={() => toggleOpenOtherConfigEditMode('automatic discount', true)}/>
              <i className="ri-edit-2-fill text-warning h4 cursor-pointer me-2" 
                onClick={() => toggleOpenOtherConfigEditMode('automatic discount', false)}/>
            </span>
          </div>
          <hr style={{ margin: '5px 0px' }}/>
          <div className="d-flex justify-content-between">
            <h5>Commission %</h5>
            <span>
              <i className="ri-eye-line text-info h4 cursor-pointer mr-2" 
                onClick={() => toggleOpenOtherConfigEditMode('commission', true)}/>
              <i className="ri-edit-2-fill text-warning h4 cursor-pointer me-2" 
                onClick={() => toggleOpenOtherConfigEditMode('commission', false)}/>
            </span>
          </div>
          <hr style={{ margin: '5px 0px' }}/>
          <div className="d-flex justify-content-between">
            <h5>VAT %</h5>
            <span>
              <i className="ri-eye-line text-info h4 cursor-pointer mr-2" 
                onClick={() => toggleOpenOtherConfigEditMode('vat', true)}/>
              <i className="ri-edit-2-fill text-warning h4 cursor-pointer me-2" 
                onClick={() => toggleOpenOtherConfigEditMode('vat', false)}/>
            </span>
          </div>
          <hr style={{ margin: '5px 0px' }}/>
          <div className="d-flex justify-content-between">
            <h5>Platform Fee</h5>
            <span>
              <i className="ri-eye-line text-info h4 cursor-pointer mr-2"
                onClick={() => toggleOpenOtherConfigEditMode('platform fee', true)}/>
              <i className="ri-edit-2-fill text-warning h4 cursor-pointer me-2" 
                onClick={() => toggleOpenOtherConfigEditMode('platform fee', false)}/>
            </span>
          </div>
        </div>
      </div>

      {isOpenModal && <ViewConfigData
        isOpen={isOpenModal}
        configDataList={configData}
        type={viewType}
        reRenderList={() => getConfigListData()}
        handleClose={() => {
          setIsOpenModal(false)
          setViewType(false)
        }}/>}

      {isOpenOtherModal && <EditOtherConfigData
        isOpen={isOpenOtherModal}
        type={viewTypeModal}
        configDataList={configData}
        isViewModeEnable={isViewModeEnable}
        reRenderList={() => getConfigListData()}
        handleClose={() => {
          setIsOpenOtherModal(false)
        }}/>}
    </>
  )

}

export default MyConfig