import { Checkbox, FormControlLabel } from '@mui/material'
import { createBooking, getParentsList, getSittersList } from 'Views/Dashboard/Views/Parents/Api'
import { useEffect, useRef, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddressModule from './address'
import { ToggleButton, ToggleButtonGroup, Typography, Grid, FormControl } from '@mui/material'
import { weekDaysArr } from 'Utils/helper'
import dayjs from 'dayjs'
import DatePicker from 'react-multi-date-picker'


const CreateNewBooking = ({ isOpen, handleClose, handleBookingDetailList }) => {
  
  const [formDetails, setFormsDetails] = useState({ parentName: '', sitterName: '' })
  const [newParentList, setNewParentList] = useState([])
  const [newSittersList, setNewSittersList] = useState([])
  const [dogsData, setDogsData] = useState([])
  const [incomingAddressData, setIncomingAddressData] = useState([])
  const [addressData, setAddressData] = useState()
  const [selectedWeekdays, setSelectedWeekdays] = useState([])
  const [modifiedDaycareData, setModifiedDaycareData] = useState([])
  const [daycareDataDaysTime, setDaycareDaysTime] = useState(0)
  const [dayCareSelectDates, setDayCareSelectDates] = useState([])
  const [selectedDaycareAction, setSelectedDaycareAction] = useState(0)
  const [overnightTimeDiff, setOvernightTimeDiff] = useState('')
  const [alertMsg, setAlertMsg] = useState('')
  const [basicDetails, setBasicDetails] = useState({
    dogs: [], //ids of dogs
    available_for_video_call: '',
    service_type: '', // overnight_boarding daycare home_visit
    other_details: '',
    comfortable_splitting_booking: '',
    sitter_caring_for_another_dog: ''
  })
  const[otherData, setOtherData] = useState({
    parent_id: '',
    sitter_id: []
  })
  const [overnightData, setOvernightData] = useState({
    overnight_start_date_time: {
      date: '',
      time: ''
    },
    overnight_end_date_time: {
      date: '',
      time: ''
    }
  })

  const [daycareData, setDaycareData] = useState({
    daycare_dates: modifiedDaycareData,
    daycare_dates_flexible: '',
    ideal_start_date: null
  })

  const { dogs, available_for_video_call, service_type } = basicDetails
  const ref = useRef()
  let newParentListArr = []
  let newSittersListArr = []

  const getParentListData = (e, value) => {
    getParentsList(e.target.value).then((res) => {
      setIncomingAddressData([])
      newParentListArr = []
      res.data.data.results.map((item) => {
        return(
          newParentListArr.push({ 'label': `${item.first_name} - ${item.email}`, id: item.user_id,
            dogsList: item.dogs, address: item.address })
        )
      })
      setNewParentList(newParentListArr)
      setFormsDetails({ ...formDetails, parentName: e.target.value })
    })
  }

  const getSitterListData = (e) => {
    getSittersList(e.target.value).then((res) => {
      newSittersListArr = []
      res.data.data.results.map((item) => {
        return(
          newSittersListArr.push({ 'label': `${item.first_name} - ${item.email}`, id: item.user_id })
        )
      })
      setNewSittersList(newSittersListArr)
      setFormsDetails({ ...formDetails, sitterName: e.target.value })
    })
  }
  const createNewBookingReq = () => {
    const isLocationTypeEmpty = addressData.location_type.length === 0;

    const isSitterLocationInvalid =
        addressData.location_type.includes('sitter_location') &&
        (!addressData.comfortable_with_sitter_dog ||
         !addressData.comfortable_with_sitter_cat ||
         !addressData.comfortable_with_sitter_children);

    const isParentLocationInvalid =
        addressData.location_type.includes('parent_location') &&
        !addressData.parent_address;

    const isOtherAddressInvalid =
        addressData.location_type.includes('other_address') &&
        (!addressData.manual_address?.street_name ||
         !addressData.manual_address?.city ||
         !addressData.manual_address?.country ||
         !addressData.manual_address?.postal_code);

    const isDaycareDatesInvalid = 
        basicDetails?.service_type === "daycare" && 
        daycareData?.daycare_dates_flexible === "";
       
       const isDaycareSelectDaysValid = 
       basicDetails?.service_type === "daycare" && 
       dayCareSelectDates.length > 0 &&  
       (modifiedDaycareData.length === 0 || 
        modifiedDaycareData.some(({ startTime, endTime }) => !startTime || !endTime));
   
       const checkDayCareOftenValid = basicDetails?.service_type === "daycare" && !selectedDaycareAction
    
    const isOverNightDateValid =
        basicDetails?.service_type === "overnight_boarding" &&
        Object.values(overnightData).every(
            ({ date, time }) => date === "" && time === ""
        );

        const missingFields = [];

        if (isLocationTypeEmpty) missingFields.push("Location Type");
        if (isSitterLocationInvalid) missingFields.push("Sitter Location");
        if (isParentLocationInvalid) missingFields.push("Parent Location");
        if (isOtherAddressInvalid) missingFields.push("Other Address");
        if (isDaycareDatesInvalid) missingFields.push("Daycare Dates");
        if (isOverNightDateValid) missingFields.push("Overnight Date");
        if (isDaycareSelectDaysValid) missingFields.push("Daycare Selected Days");
        if (otherData.parent_id === '') missingFields.push("Parent ID");
        if (otherData.sitter_id === '') missingFields.push("Sitter ID");
        if (basicDetails.dogs.length === 0) missingFields.push("Dogs");
        if (basicDetails.available_for_video_call === '') missingFields.push("Available for Video Call");
        if (basicDetails.comfortable_splitting_booking === '') missingFields.push("Comfortable Splitting Booking");
        if (basicDetails.service_type === '') missingFields.push("Service Type");
        if (checkDayCareOftenValid) missingFields.push("Daycare Frequency");
        
        if (missingFields.length > 0) {
            setAlertMsg(`Please fill required fields: ${missingFields.join(", ")}`);
            return; // Stop execution if validation fails
        }
        
    const overnightPayload = { ...basicDetails, ...overnightData, ...otherData, ...addressData }
    const daycarePayload = selectedDaycareAction == 1 ? 
    { ...basicDetails, ...daycareData, ...otherData, ...addressData, 'daycare_dates':modifiedDaycareData } : 
    { ...basicDetails, ...daycareData, ...otherData, ...addressData, 'week_days': selectedWeekdays }
    {service_type == 'overnight_boarding' && createBooking(overnightPayload).then((res) => {
      if(res.data.response_code === 80){
        handleClose()
        handleBookingDetailList()
      }
    })}
    {service_type == 'daycare' && createBooking(daycarePayload).then((res) => {
      if(res.data.response_code === 80){
        handleClose()
        handleBookingDetailList()}})}
  }
  function calculateTimeDifference(startTime, endTime) {
    if(startTime && endTime){
      const [hours1, minutes1] = startTime.split(':').map(Number)
      const [hours2, minutes2] = endTime.split(':').map(Number)
      const totalMinutes1 = hours1 * 60 + minutes1
      const totalMinutes2 = hours2 * 60 + minutes2
      let timeDifference = totalMinutes2 - totalMinutes1
      const hoursDifference = Math.floor(timeDifference / 60)
      const minutesDifference = timeDifference % 60
      return `${hoursDifference}:${minutesDifference >= 10 ? minutesDifference : `0${minutesDifference}`}`
    } else return
  }

  const calculateHoursToMins = (hours) => {
    if(hours){
      const [hour, minutes] = hours.split(':').map(Number)
      const totalMinutes = hour * 60 + minutes
      return totalMinutes
    }
  }

  const dayType = []

  useEffect(() => {
    modifiedDaycareData.map((item) => {
      if(item.day_type !== ''){
        dayType.push(item.day_type)
      }
      const convertedArray = dayType.map(day => {
        if (day === 'Half Day') {return 0.5}
        else if (day === 'Full Day') {return 1}
        else if (day === 'One Half') {return 1.5}
        return day
      })
      let totalDaysSum = 0
      convertedArray.forEach( num => {totalDaysSum += num})
      setDaycareDaysTime(totalDaysSum)
      return
    })
  }, [modifiedDaycareData])

  useEffect(() => {
    if (!overnightData?.overnight_start_date_time || !overnightData?.overnight_end_date_time) {
        setOvernightTimeDiff("0 Days");
        return;
    }

    const { overnight_start_date_time, overnight_end_date_time } = overnightData;

    if (!overnight_start_date_time.date || !overnight_start_date_time.time ||
        !overnight_end_date_time.date || !overnight_end_date_time.time) {
        setOvernightTimeDiff("0 Days");
        return;
    }

    const dateA = new Date(`${overnight_start_date_time.date}T${overnight_start_date_time.time}`);
    const dateB = new Date(`${overnight_end_date_time.date}T${overnight_end_date_time.time}`);

    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        setOvernightTimeDiff("0 Days");
        return;
    }

    const timeDifferenceInMilliseconds = dateB.getTime() - dateA.getTime();
    let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convert to hours

    let time = "0 Days";

    if (timeDifferenceInHours <= 24) {
        time = "1 Day";
    } else {
        let fullDays = Math.floor(timeDifferenceInHours / 24); // Count full 24-hour periods
        let remainingHours = timeDifferenceInHours % 24; // Get the leftover hours

        if (remainingHours > 1) { // Ignore the first hour after a full day
            if (remainingHours <= 6) {
                time = `${fullDays + 0.5} Days`; // If remaining hours are 2-6, count as half a day
            } else {
                time = `${fullDays + 1} Days`; // If more than 6 hours, count as a full day
            }
        } else {
            time = `${fullDays} Days`; // If only 1 hour remains after full days, ignore it
        }
    }

    setOvernightTimeDiff(time);
}, [overnightData]);



  const handledDayCareMultipleDates = (data) => {
    let mappedData = []
    const convertDate = data.map((value) => value.year + '-' + value.month + '-' + value.day)
    const newDateObject = convertDate.filter(date => !modifiedDaycareData.some(item => item.date === date))
    const filteredArr = modifiedDaycareData.filter((item) => convertDate.includes(item.date))
    const modifiedJson = newDateObject.map((item) => {
      const data = {
        date: item,
        startTime: '',
        endTime: '',
        totalHours: '',
        day_type: '' // Full or half day
      }
      mappedData.push(data)
    })
    setDayCareSelectDates(convertDate)
    // setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ])
    console.log(mappedData,'mappedData')
    mappedData.length > 0 ? setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ]) 
      : setModifiedDaycareData(filteredArr)
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>Create New Booking</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={newParentList}
                sx={{ width: '100%' }}
                onChange={(e, val) => {
                  setDogsData(val?.dogsList)
                  setIncomingAddressData(val?.address)
                  setOtherData({ ...otherData, parent_id: val?.id })
                }}
                renderInput={(params) => <TextField {...params} onChange={getParentListData}
                label={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    Parent Name <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </span>
                }                 
                />}
              />        
                 

            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={newSittersList}
                multiple
                onChange={(e, val) => { 
                  const sitterIds = []
                  val.map((item) => {
                    sitterIds.push(item?.id)
                    setOtherData({ ...otherData, sitter_id: sitterIds })
                  })}}
                sx={{ width: '100%' ,position:"relative"  }}
                renderInput={(params) => <TextField {...params} 
                label={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    Sitter Name <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </span>
                }                  onChange={getSitterListData}/>}
              />
            </FormGroup>

          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Service Type</Label>
              <Input
                value={basicDetails.service_type}
                onChange={(e) => {setBasicDetails({ ...basicDetails, service_type: e.target.value })}}
                type="select"
                className="form-control"
              >
                <option hidden>Select</option>
                <option value="overnight_boarding">Overnight Boarding</option>
                <option value="daycare">Daycare</option>
              </Input>
            </FormGroup>
          </Col>
          {basicDetails.service_type != '' && <h4>{`Selected Service Type: ${basicDetails.service_type || ''}`}</h4>}
          {basicDetails.service_type == 'overnight_boarding' && <Row>
            <Col xs={6}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Start Date          
                           <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        const nextDay = new Date(selectedDate);
                        nextDay.setDate(selectedDate.getDate() + 1);
                  
                        setOvernightData({
                          ...overnightData,
                          overnight_start_date_time: {
                            ...overnightData.overnight_start_date_time,
                            date: e.target.value,
                          },
                          overnight_end_date_time: {
                            ...overnightData.overnight_end_date_time,
                            date: nextDay.toISOString().split("T")[0], 
                          },
                        });}}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Start Time
                    <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </Label>
                    <br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        ampm={false}
                        value={overnightData.overnight_start_date_time.time}
                        onChange={(e) => setOvernightData({ ...overnightData, overnight_start_date_time:
                          {  ...overnightData.overnight_start_date_time, time: e.format('HH:mm') } })}
                      />
                    </LocalizationProvider>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>End Date 
                    <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      value={overnightData.overnight_end_date_time.date}
                      onChange={(e) => setOvernightData({ ...overnightData, overnight_end_date_time:
                        { ...overnightData.overnight_end_date_time, date: e.target.value } })}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label>End Time
                    <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                      </Label>
                    <br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        ampm={false}
                        value={overnightData.overnight_end_date_time.time}
                        onChange={ (e) => setOvernightData({ ...overnightData,
                          overnight_end_date_time: { ...overnightData.overnight_end_date_time, time: e.format('HH:mm') } })}
                      />
                    </LocalizationProvider>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Row>
              <h6 className='text-end'>No of days: {overnightTimeDiff}</h6>
            </Row>
          </Row>}

          {basicDetails.service_type == 'daycare' && <Row>
            <FormGroup>
              <Label>How often do you need this service?
              <span style={{ color: 'red', fontSize: '20px' }}>*</span>
              </Label>
              <Input
                value={selectedDaycareAction}
                onChange={(e) => {setSelectedDaycareAction(e.target.value)}}
                type="select"
                className="form-control"
              >
                <option hidden>Select</option>
                <option value='1'>One Time</option>
                <option value="2">Repeat Weekly</option>
              </Input>
            </FormGroup>

            {selectedDaycareAction == 2 && <><Col xs={6}>
              <Label>Selcet Days</Label><br/>
              <ToggleButtonGroup
                size="small"
                arial-label="Days of the week"
                value={selectedWeekdays}
                onChange={(event, value) => setSelectedWeekdays(value)}
              >
                {weekDaysArr.map((day, index) => (
                  <ToggleButton key={day.key} value={day.key} aria-label={day.key}>
                    {day.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Col>

            <Col xs={6}>
              <FormGroup>
                <Label>
                  Ideal Start Date
                </Label>
                <Input
                  placeholder='Enter Date'
                  value={daycareData.ideal_start_date}
                  onChange={(e) => {setDaycareData({ ...daycareData, ideal_start_date: e.target.value })}}
                  type='date'
                  className='form-control'
                />
              </FormGroup>
            </Col></>}

            {selectedDaycareAction == 1 && <Col xs={12}>
              <Col xs={12}>
                <FormGroup fullWidth variant="standard">
                  <Typography shrink="true">
                  Select Dates <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                  </Typography>
                  <DatePicker
                    ref={ref}
                    className="date-picker-layout"
                    multiple
                    onChange={handledDayCareMultipleDates}
                    value={dayCareSelectDates}
                    dateFormat="DD-MM-YYYY" 
                  >
                    <Button className='py-1' style={{ position: 'relative', bottom: '5px' }}
                      color='primary' onClick={() => ref.current.closeCalendar()}>
                      close
                    </Button>
                  </DatePicker>
                </FormGroup>
              </Col>
              <Col xs={12}>
                {modifiedDaycareData.map((item, index) => {
                  return(
                    <div style={{ display: 'flex', width: '100%' ,gap: '14px', alignItems: 'center', marginTop:'8px' }}
                      key={index}>
                      <span onClick={() => {
                        const calenderItems = [...dayCareSelectDates]
                        const modifyItems = [...modifiedDaycareData]
                        calenderItems.splice(index, 1)
                        modifyItems.splice(index, 1)
                        setModifiedDaycareData(modifyItems)
                        setDayCareSelectDates(calenderItems)
                      }}><i className="ri-delete-bin-3-fill text-danger h4 cursor-pointer me-2"></i></span>
                      <Col xs={2}>
                        <FormControl fullWidth variant="standard">
                          <Typography shrink="true">
                        Selected Dates
                          </Typography>
                          <TextField
                            type="text"
                            size="small"
                            disabled
                            value={item.date}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={2}>
                        <FormControl fullWidth variant="standard">
                          <Typography shrink="true">
                          Start Time  <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                              sx={{ marginTop: '5px' }}
                              defaultValue={dayjs('2022-04-17T18:30')} 
                              value={item.startTime}
                              ampm={false}
                              closeOnSelect={true}
                              onChange={(time) => {
                                const updatedData = { ...item, startTime: time.format('HH:mm'), totalHours:
                              calculateTimeDifference(time.format('HH:mm'), item.endTime) }
                                setModifiedDaycareData((prevData) => {
                                  const newData = [...prevData]
                                  newData[index] = updatedData
                                  return newData
                                })
                              }}
                            />
                          </LocalizationProvider>            
                        </FormControl>
                      </Col>
                      <Col xs={2}>
                        <FormControl fullWidth variant="standard">
                          <Typography shrink="true">
                        End Time  <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                              sx={{ marginTop: '5px' }}
                              defaultValue={dayjs()}
                              value={item.endTime}
                              ampm={false}
                              onChange={(time) => {
                                const updatedData = { ...item, endTime: time.format('HH:mm'), totalHours:
                              calculateTimeDifference(item.startTime, time.format('HH:mm')),
                                day_type: calculateHoursToMins(calculateTimeDifference(item.startTime,
                                  time.format('HH:mm'))) == 0 ? '' : 
                                  calculateHoursToMins(calculateTimeDifference(item.startTime,
                                    time.format('HH:mm'))) > 601 ? 'One Half' :
                                    calculateHoursToMins(calculateTimeDifference(item.startTime,
                                      time.format('HH:mm'))) > 301 ? 'Full Day' : 'Half Day' }
                                setModifiedDaycareData((prevData) => {
                                  const newData = [...prevData]
                                  newData[index] = updatedData
                                  return newData
                                })
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Col>
                      <Col xs={2} >
                        <FormControl fullWidth variant="standard">
                          <Typography shrink="true">
                          No of Hours
                          </Typography>
                          <TextField
                            type="text"
                            size="small"
                            placeholder="Hours"
                            disabled
                            value={item.totalHours}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={2}>
                        <FormControl fullWidth variant="standard">
                          <Typography shrink="true">
                          No of Days
                          </Typography>
                          <TextField
                            type="text"
                            size="small"
                            placeholder="Days"
                            disabled
                            value={item.day_type}
                          />
                        </FormControl>
                      </Col>
                    </div>)
                })}
                {modifiedDaycareData.length > 0 && 
                  <Grid sx={{ display: 'flex', justifyContent:'end', gap:'15px', marginRight:'10px' }}>
                    <Typography component="h4" color="text.secondary" fontWeight='bold' fontFamily='brandon'>
                      {`Total days: ${daycareDataDaysTime}`}</Typography>
                  </Grid>
                }
              </Col>
            </Col>}
            <Col xs={12}>
              <FormGroup>
                <Label>Are your dates a little flexible?
                <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                </Label>
                <Input
                  value={daycareData.daycare_dates_flexible}
                  onChange={(e) => {setDaycareData({ ...daycareData, daycare_dates_flexible: e.target.value })}}
                  type="select"
                  className="form-control"
                >
                  <option hidden>Select</option>
                  <option value='true'>No, my dates cannot change</option>
                  <option value='false'>Yes, my dates can be adjusted for the right dog sitter</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>}

          
          <AddressModule incomingAddressData={incomingAddressData} data={(data) => setAddressData(data)}/>
          <Col xs={6}>
            <FormGroup>
              <Label>Select Dogs          
                           <span style={{ color: 'red', fontSize: '20px' }}>*</span>
              </Label><br/>
              {dogsData && dogsData.length > 0 && dogsData.map((item, index) => {
                return <FormControlLabel label={item.name} key={index}
                  control={
                    <Checkbox
                      value={item.dog_id}
                      checked={dogs.includes(item.dog_id) ? true : false}
                      onChange={() => {dogs.includes(item.dog_id) ? 
                        setBasicDetails({ ...basicDetails, dogs: dogs.filter((value) => value != item.dog_id) })
                        : setBasicDetails({ ...basicDetails, dogs: [...dogs, item.dog_id] })}}
                      sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />
              })}
            </FormGroup>   
          </Col>
          <Col xs={6}></Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Would you be comfortable with a video call instead of a meeting?</Label>         
              <span style={{ color: 'red', fontSize: '20px' }}>*</span>

              <Input
                value={available_for_video_call}
                onChange={(e) => setBasicDetails({
                  ...basicDetails,
                  available_for_video_call: e.target.value
                })}
                type="select"
                className="form-control"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Would you be comfortable splitting your booking across two different dog sitters?
              <span style={{ color: 'red', fontSize: '20px' }}>*</span>
              </Label>

              <Input
                value={basicDetails.comfortable_splitting_booking}
                onChange={(e) => {setBasicDetails({ ...basicDetails, comfortable_splitting_booking: e.target.value })}}
                type="select"
                className="form-control"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Is it OK if the dog sitter is caring for another dog on some of your dates?</Label>
              <Input
                value={basicDetails.sitter_caring_for_another_dog}
                onChange={(e) => {setBasicDetails({ ...basicDetails, sitter_caring_for_another_dog: e.target.value })}}
                type="select"
                className="form-control"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Is there anything else you would like us or the dog sitter to know?</Label>
              <Input
                type="textarea"
                value={basicDetails.other_details}
                onChange={(e) => {setBasicDetails({ ...basicDetails, other_details: e.target.value })}}
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
      <p style={{ color:'red' , position:'absolute' ,bottom:'1rem ',left:'1.2rem' , fontSize:'15px' }}>{alertMsg}</p>

        <Button
          type="button"
          onClick={createNewBookingReq}
          color="primary"
          className="waves-effect"
        >
          Create
        </Button>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateNewBooking
