import ParentBookingConfirmation from 'Components/ParentBookingConfirmationFLow'
import { getMeetingLocationByBookingId, sharedToSitters, updateBookingStatusById } from 'Views/Dashboard/Views/Parents/Api'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const BookingStatusActions = ({ isOpen, handleClose, statusType, renderTable }) => {

  const [meetingPlanningData, setMeetingPlanningData] = useState([])
  const [meetingLocationData, setMeetingLocationData] = useState([])
  const [meetingPlanning, setMeetingPlanning] = useState('')
  const [meetingConfirmed, setMeetingConfirmed] = useState({
    date: '',
    time: '',
    location: ''
  })
  const [meetingCancelled, setMeetingCancelled] = useState({
    type: '',
    message: ''
  })
  const [meetingRefused, setMeetingRefused] = useState({
    type: '',
    message: ''
  })
  const [alertMsg, setAlertMsg] = useState('')

  useEffect(() => {
    (statusType.status == 'meeting_planning') &&
      sharedToSitters(statusType.bookingId).then((res) => {
        setMeetingPlanningData(res.data.data)
      })

    statusType.status == 'meeting_confirmed' && 
    getMeetingLocationByBookingId(statusType.bookingId).then((res) => {
      setMeetingLocationData(res.data.data)
    })
    
  }, [])


  const handleUpdateStatusInBooking = () => {
    try{
      {(statusType.status == 'open' || 
        statusType.status == 'info_missing' ||statusType.status ==  'info_updated' || 
          statusType.status ==  'checking_with_sitters' || statusType.status == 'refunded' || 
          statusType.status == 'post_meeting_update_pending' || statusType.status == 'match_new_sitter' || 
          statusType.status == 'confirmed_by_sitter' || statusType.status == 'payment_pending' 
        || statusType.status == 'booking_confirmed' 
      )
         &&
      updateBookingStatusById(statusType.bookingId, { 'status': statusType.status }).then(() => {
        handleClose()
        renderTable()
      })}
      {statusType.status == 'meeting_planning' && meetingPlanning &&
        updateBookingStatusById(statusType.bookingId, { 'status': statusType.status, 
          'final_sitter_id':  meetingPlanning }).then(() => {
          handleClose()
          renderTable()
        })
      }
      if (statusType.status === 'meeting_confirmed') {
        if (!meetingConfirmed.date && !meetingConfirmed.time && !meetingConfirmed.location) {
          setAlertMsg('Please fill the data');
          return;
        }
      
        updateBookingStatusById(statusType.bookingId, {
          status: statusType.status,
          ...(meetingConfirmed.date && meetingConfirmed.time && 
            { meeting_time: `${meetingConfirmed.date}T${meetingConfirmed.time}` }),
          ...(meetingConfirmed.location && { meeting_location: meetingConfirmed.location })
        })
          .then(() => {
            handleClose();
            renderTable();
          })
          .catch((error) => {
            console.error("Error updating booking status:", error);
          });
      }
      
      
      if (statusType.status == 'cancelled') {
        if (meetingCancelled.type === '') {
          setAlertMsg('Please select the type')
          return
        }
        updateBookingStatusById(statusType.bookingId, {
          'cancelled_reason': meetingCancelled.message,
          'status': statusType.status,
          'cancelled_reason_type': meetingCancelled.type
        }).then(() => {
          setAlertMsg('')
          handleClose()
          renderTable()
        }).catch((err) => {
          console.log(err)
        })
      }
      
      {statusType.status == 'refused' &&
    updateBookingStatusById(statusType.bookingId, { 'status': statusType.status, 
      'refused_reason':  meetingRefused.message, 'refused_reason_type': meetingRefused.type }).then(() => {
      handleClose()
      renderTable()
    })
      }
    }catch(err){
      console.log(err)
    }
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      {statusType.status == 'meeting_planning' && 
      <ModalHeader>Confirm Meeting</ModalHeader>}
      <ModalBody>
        {((statusType.status == 'open' && <p className='font-bold'>Change status to Open?</p> ) ||
          (statusType.status == 'info_missing' && <p className='font-bold'>Change Status to Info Missing?</p>) ||
          (statusType.status == 'info_updated' && <p className='font-bold'>Change Status to Info Update?</p> ) ||
          (statusType.status == 'checking_with_sitters' && <p className='font-bold'>Change Status to Checking with Sitter?</p> ||
          (statusType.status == 'post_meeting_update_pending' && <p className='font-bold'>Change status to Update Pending?</p> )||
          (statusType.status == 'confirmed_by_sitter' && <p className='font-bold'>Change status to confirmed by sitter?</p> ) ||
          (statusType.status == 'payment_pending' && <p className='font-bold'>Change status to payment pending?</p> ) ||
          (statusType.status == 'booking_confirmed' && <p className='font-bold'>Change status to Confirmed?</p> ) ||
          (statusType.status == 'match_new_sitter' && <p className='font-bold'>Change status to Open - match new sitter?</p> )))}
          
        {statusType.status == 'meeting_planning' && <>
          <p className='font-bold'>Change Status to Meeting Planning?</p>
          <FormGroup><Label>Select Sitter</Label>
            <Input type='select' onChange={(e) => {setMeetingPlanning(e.target.value)}}> 
              <option selected disabled>Choose Sitter</option>
              {meetingPlanningData.map((item) => {
                return <option value={item.user_id} 
                  key={item.user_id}>{`${item.name} - ${item.response}`}</option> })}
            </Input></FormGroup></>}

        {statusType.status == 'cancelled' && <><p className='font-bold'>Change Status to cancelled?</p>
          <FormGroup>
            <Label>Type <span style={{ color: 'red' }} >*</span></Label>
            <Input
              value={meetingCancelled.status}
              onChange={(e) => {
                setMeetingCancelled({ ...meetingCancelled, type: e.target.value, message: e.target.value })
              }}
              type="select"
              className="form-control"
            >
              <option hidden>Select</option>
              <option value='No response by dog parent'>No response by dog parent</option>
              <option value='Found another dog sitter'>Found another dog sitter</option>
              <option value='Found friends or family'>Found friends or family</option>
              <option value='Plans changed/cancelled'>Plans changed/cancelled</option>
              <option value='Didn&apos;t like sitter match'>Didn&apos;t like sitter match</option>
              <option value='Didn&apos;t like pricing'>Didn&apos;t like pricing</option>
              <option value='Didn&apos;t like T&Cs'>Didn&apos;t like T&Cs</option>
              <option value='Other'>Other</option>
              <option value='Unknown'>Unknown</option>
            </Input>
          </FormGroup>
<p style={{ color:'red' , position:'absolute' ,bottom:'6.8rem ',left:'1.2rem' , fontSize:'12px' }}>{alertMsg}</p>
          <FormGroup><Label>Reason of cancellation</Label>
            <Input type='textarea' onChange={(e) => {setMeetingCancelled({ ...meetingCancelled, message: e.target.value })}}
              value={meetingCancelled.message} placeholder='Enter Reason'/></FormGroup></>}

        {statusType.status == 'refused' && <><p className='font-bold'>Change Status to refused?</p>
          <FormGroup>
            <Label>Type</Label>
            <Input
              value={meetingCancelled.status}
              onChange={(e) => {
                setMeetingRefused({ ...meetingRefused, type: e.target.value, message: e.target.value  })
              }}
              type="select"
              className="form-control"
            >
              <option hidden>Select</option>
              <option value='Request too last minute'>Request too last minute</option>
              <option value='No suitable availability'>No suitable availability</option>
              <option value='Other'>Other</option>
            </Input>
          </FormGroup>
          <FormGroup><Label>Reason of refusal</Label>
            <Input type='textarea' onChange={(e) => {setMeetingRefused({ ...meetingRefused, message: e.target.value })}}
              value={meetingRefused.message} placeholder='Enter Reason'/></FormGroup></>}

        {statusType.status == 'meeting_confirmed' && 
        <>
          <p className='font-bold'>Change Status to Meeting Confirmed?</p>
          <div className='d-flex gap-2'>
            <FormGroup>
              <Label>Meeting Date</Label><span style={{ color: 'red' }} >*</span>
              <Input type='date' placeholder='start Date' min={new Date().toISOString().split('T')[0]} onChange={(e) => 
                setMeetingConfirmed({ ...meetingConfirmed, date: e.target.value })}></Input>
            </FormGroup>
            <FormGroup>
              <Label>Meeting Time</Label><span style={{ color: 'red' }} >*</span>
              <br/>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  ampm={false}
                  onChange={(e) => {setMeetingConfirmed({ ...meetingConfirmed, time: e.format('HH:mm') })}}
                />
              </LocalizationProvider>
            </FormGroup>
            {/* <FormGroup>
              <Label>Meeting Time</Label>
              <Input type='time' placeholder='start Date' onChange={(e) => 
                setMeetingConfirmed({ ...meetingConfirmed, time: e.target.value })}></Input>
            </FormGroup> */}
            <FormGroup>
              <Label>Location</Label><span style={{ color: 'red' }} >*</span>
              <Input type='select' onChange={(e) => {setMeetingConfirmed({ ...meetingConfirmed, location: e.target.value })}}> 
                <option selected disabled>Choose Location</option>
                {meetingLocationData.map((item, index) => {
                  return <option value={item} 
                    key={index+1}>{item}</option> })}
              </Input>
            </FormGroup>    
          </div>
          <p style={{ color:'red' , position:'absolute' ,bottom:'0rem ',left:'1.2rem' , fontSize:'12px' }}>{alertMsg}</p>
        </>
        }

        {statusType.status == 'confirmed_by_parent' && 
          <ParentBookingConfirmation 
            id={statusType.bookingId}
            handleClose={() => handleClose()}
            renderTable={() => renderTable()}
          />
        }
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleUpdateStatusInBooking}
          color="primary"
          className="waves-effect"
        >
          Save
        </Button>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default BookingStatusActions
