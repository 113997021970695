import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'

const RefundList = ({
  tableData = [],
  tableHeading = [],  // Ensure tableHeading is received as a prop
  handleTablePageChange,
  handleSearch,
  searchData,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleApporoveRefundReq,
  handleRejectRefundReq
}) => {
  const [localTableHeading, setLocalTableHeading] = useState([...tableHeading]) // Initialize state
  const [rowData, setRowData] = useState([])

  const viewUsersFormatter = (cell, row) => {
    return (
      <>
        {['super_admin'].includes(userRole()) && (
          <div className='d-flex justify-content-evenly'>
            <div style={{ textAlign: 'center' }} key={row.id}>
              <i
                className="dripicons-checkmark text-success h4 cursor-pointer mr-2"
                onClick={() => handleApporoveRefundReq(row.id, row.amount)}
                title='Approve Refund'
              />
            </div>
            <div style={{ textAlign: 'center' }} key={row.id}>
              <i
                className="dripicons-cross text-danger h4 cursor-pointer mr-2"
                onClick={() => handleRejectRefundReq(row.id)}
                title='Reject Refund'
              />
            </div>
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    if (['super_admin'].includes(userRole())) {
      setLocalTableHeading(prevHeadings => [
        ...prevHeadings,
        {
          text: 'Action',
          dataField: 'view',
          isDummyField: true,
          headerStyle: { textAlign: 'center', width: '10%' },
          formatter: viewUsersFormatter,
        },
      ])
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      const result = tableData.map((el, i) => ({
        ...el,
        sNo: i + 1 + page * 10 - 10,
      }))
      setRowData(result)
    }
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={6}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                <h3 className="m-0">Refund Pending</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="searchSitter">Search: </Label>
                <Input
                  style={{ height: '35px', marginLeft: '5px' }}
                  name="searchSitter"
                  value={searchData}
                  onChange={handleSearch}
                  type="text"
                  className="form-control"
                  id="searchSitter"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            {isLoading ? (
                              <div className="text-center py-4">
      <h6 className='text-muted' >Loading...</h6>
      </div>
    ) : rowData.length > 0 ? (  
            <Table
              rows={rowData}
              columns={localTableHeading} // Use localTableHeading
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            /> ) 
            : (
             <div className="text-center py-4">
               <h6 className="text-muted">No Pending Refunds Available</h6>
             </div>
           )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default RefundList
