import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const ViewSitterData = ({ isOpen, sitterData = [], handleClose }) => {

  const dataArray = sitterData

  const style ={
    borderRight: '1px solid #ced4da'
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>View sitter application</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label htmlFor="hear_from_source">
                How did you hear about DogStays?
              </Label>
              <p className='border rounded p-2'>{dataArray.hear_from_source}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="first_name">Name</Label>
              <p className='border rounded p-2'>{dataArray.first_name}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="last_name">Family Name</Label>
              <p className='border rounded p-2'>{dataArray.last_name}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="gender">Gender</Label>
              <p className='border rounded p-2'>{dataArray.gender}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="date_of_birth">Date of birth</Label>
              <p className='border rounded p-2'>{dataArray.date_of_birth}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="mobile">Phone</Label>
              <p className='border rounded p-2'>{dataArray.mobile}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="preferred_languages">Which languages do you speak?</Label>
              <p className='border rounded p-2'>{dataArray.preferred_languages}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="using_whatsapp_messenger">
                Do you use WhatsApp Messenger?
              </Label>
              <p className='border rounded p-2'>{dataArray.using_whatsapp_messenger}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="another_whatsapp_mobile">
                If the number you use on WhatsApp is different please share that
                with us here
              </Label>
              <p className='border rounded p-2'>{dataArray.another_whatsapp_mobile}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email address</Label>
              <p className='border rounded p-2'>{dataArray.email}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="street_name">Street Name and Number</Label>
              <p className='border rounded p-2'>{dataArray.street_name}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="city">City</Label>
              <p className='border rounded p-2'>{dataArray.city}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="country">Country</Label>
              <p className='border rounded p-2'>{dataArray.country}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="postal_code">Postal Code</Label>
              <p className='border rounded p-2'>{dataArray.postal_code}</p>
            </FormGroup>
          </Col>

          <Col lg='6'>
            <FormGroup>
              <Label htmlFor="reason_of_joining">
                Why do you want to join our network?
              </Label>
              <p className='border rounded p-2'>{dataArray.reason_of_joining}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="have_your_own_dog">
                Do you have your own dog here in Luxembourg?
              </Label>
              <p className='border rounded p-2'>{dataArray.have_your_own_dog}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="sitter_dog_info">
                If YES then please tell us about your dog (Name, girl or boy?
                Neutered or not? what breed? how old he/she is? Is your dog good
                with other dogs? )
              </Label>
              <p className='border rounded p-2'>{dataArray.sitter_dog_info}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="have_your_own_cat">
                Do you have a cat at home here in Luxembourg?
              </Label>
              <p className='border rounded p-2'>{dataArray.have_your_own_cat}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="has_previous_experience_for_own_dog">
                Do you have previous experience caring for your own dog(s)?
              </Label>
              <p className='border rounded p-2'>{dataArray.has_previous_experience_for_own_dog}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="own_dog_experience_details">
                If YES, please tell us about this experience in detail
              </Label>
              <p className='border rounded p-2'>{dataArray.own_dog_experience_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="has_previous_experience_as_dog_sitter">
                Do you have previous experience as a dog sitter for someone
                else?
              </Label>
              <p className='border rounded p-2'>{dataArray.has_previous_experience_as_dog_sitter}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="as_sitter_experience_details">
                If YES, please tell us about this experience in detail
              </Label>
              <p className='border rounded p-2'>{dataArray.as_sitter_experience_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="has_formal_training">
                Do you have any formal training related to dogs or other
                animals?
              </Label>
              <p className='border rounded p-2'>{dataArray.has_formal_training}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="formal_training_details">
                If YES, please tell us about this training in detail
              </Label>
              <p className='border rounded p-2'>{dataArray.formal_training_details}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="cared_for_other_than_dog">
                Have you cared for other types of animals (besides dogs)?
              </Label>
              <p className='border rounded p-2'>{dataArray.cared_for_other_than_dog}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="caring_details_for_other_than_dog">
                If YES, please tell us about this experience in detail
              </Label>
              <p className='border rounded p-2'>{dataArray.caring_details_for_other_than_dog}</p>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewSitterData
