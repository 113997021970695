import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { updateBookingDates } from 'Views/Dashboard/Views/Parents/Api'
import { useEffect, useState } from 'react'
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'

const Overnight = ({ bookingData, setStep }) => {
  const [overnightData, setOvernightData] = useState({
    overnight_start_date_time: {
      date: '',
      time: '',
    },
    overnight_end_date_time: {
      date: '',
      time: '',
    },
  })
  const [overnightTimeDiff, setOvernightTimeDiff] = useState('')

  const handleUpdateDates = () => {
    setStep(1)
    updateBookingDates({
      overnight_end_date_time:
      `${overnightData.overnight_end_date_time.date}T${overnightData.overnight_end_date_time.time}`,
      overnight_start_date_time:
      `${overnightData.overnight_start_date_time.date}T${overnightData.overnight_start_date_time.time}`
    }, bookingData.booking_id).then((res) => {
      res.data.response_code == 80 && setStep(1)
    })
  }

  
  useEffect(() => {
    if (!overnightData?.overnight_start_date_time || !overnightData?.overnight_end_date_time) {
        setOvernightTimeDiff("0 Days");
        return;
    }

    const { overnight_start_date_time, overnight_end_date_time } = overnightData;

    if (!overnight_start_date_time.date || !overnight_start_date_time.time ||
        !overnight_end_date_time.date || !overnight_end_date_time.time) {
        setOvernightTimeDiff("0 Days");
        return;
    }

    const dateA = new Date(`${overnight_start_date_time.date}T${overnight_start_date_time.time}`);
    const dateB = new Date(`${overnight_end_date_time.date}T${overnight_end_date_time.time}`);

    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        setOvernightTimeDiff("0 Days");
        return;
    }

    const timeDifferenceInMilliseconds = dateB.getTime() - dateA.getTime();
    let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convert to hours

    let time = "0 Days";

    if (timeDifferenceInHours <= 24) {
        time = "1 Day";
    } else {
        let fullDays = Math.floor(timeDifferenceInHours / 24); // Count full 24-hour periods
        let remainingHours = timeDifferenceInHours % 24; // Get the leftover hours

        if (remainingHours > 1) { // Ignore the first hour after a full day
            if (remainingHours <= 6) {
                time = `${fullDays + 0.5} Days`; // If remaining hours are 2-6, count as half a day
            } else {
                time = `${fullDays + 1} Days`; // If more than 6 hours, count as a full day
            }
        } else {
            time = `${fullDays} Days`; // If only 1 hour remains after full days, ignore it
        }
    }

    setOvernightTimeDiff(time);
}, [overnightData]);

  return (
    <>
      <Row className='mt-4'>
        <Col xs={6}>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Label>Start Date  </Label>
                <Input
                  type="date"
                  className="form-control"
                  onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    const nextDate = new Date(selectedDate);
                    nextDate.setDate(selectedDate.getDate() + 1); // Set next day as the end date
                
                    setOvernightData({
                      ...overnightData,
                      overnight_start_date_time: {
                        ...overnightData.overnight_start_date_time,
                        date: e.target.value,
                      },
                      overnight_end_date_time: {
                        ...overnightData.overnight_end_date_time,
                        date: nextDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
                      },
                    })}}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label>Start Time</Label>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    ampm={false}
                    value={overnightData.overnight_start_date_time.time}
                    onChange={(e) =>
                      setOvernightData({
                        ...overnightData,
                        overnight_start_date_time: {
                          ...overnightData.overnight_start_date_time,
                          time: e.format('HH:mm'),
                        },
                      })
                    }
                  />
                </LocalizationProvider>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Label>End Date </Label>
                <Input
                  type="date"
                  className="form-control"
                  value={overnightData.overnight_end_date_time.date}
                  onChange={(e) =>
                    setOvernightData({
                      ...overnightData,
                      overnight_end_date_time: {
                        ...overnightData.overnight_end_date_time,
                        date: e.target.value,
                      },
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label>End Time</Label>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    ampm={false}
                    value={overnightData.overnight_end_date_time.time}
                    onChange={(e) =>
                      setOvernightData({
                        ...overnightData,
                        overnight_end_date_time: {
                          ...overnightData.overnight_end_date_time,
                          time: e.format('HH:mm'),
                        },
                      })
                    }
                  />
                </LocalizationProvider>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <h6 className="text-end">No of days: {overnightTimeDiff}</h6>
          </Row>
        </Col>
        <Row className="justify-content-center">
          <Button
            type="button"
            color="primary w-25"
            onClick={handleUpdateDates}
            className="waves-effect"
          >
            Continue
          </Button>
        </Row>
      </Row>
    </>
  )
}

export default Overnight
