import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import PhoneInput from 'react-phone-input-2'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import {
  age_of_dog_options, languageOptions, nearby_places_options, offered_services_options,
  outdoor_spaces_options, size_of_dog_options
} from './helper'
import { updateSitterInforeq } from 'Views/Dashboard/Views/Users/Users.Apis'

const EditSitterProfileData = ({ isOpen, sitterData = [], handleClose, userId }) => {

  const dataArray = sitterData
  const countryOptions = useMemo(() => countryList().getData(), [])
  const sitterUserId = userId

  const [editUserData, setEditUserData] = useState({
    hear_from_source: '',
    other_hear_from_source: '',
    first_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    mobile: '',
    using_whatsapp_messenger: '',
    another_whatsapp_mobile: '',
    preferred_languages: [],
    street_name: '',
    city: '',
    country: '',
    postal_code: '',
    email: '',
    reason_of_joining: '',
    have_your_own_dog: '',
    sitter_dog_info: '',
    have_your_own_cat: '',
    has_previous_experience_for_own_dog: '',
    own_dog_experience_details: '',
    has_previous_experience_as_dog_sitter: '',
    as_sitter_experience_details: '',
    has_formal_training: '',
    formal_training_details: '',
    cared_for_other_than_dog: '',
    caring_details_for_other_than_dog: '',
    daily_schedule: '',
    daily_schedule_details: '',
    home_type: '',
    home_size: '',
    living_situation: '',
    floor: '',
    home_has_elevator: '',
    outdoor_spaces: '',
    nearby_places: [],
    family_details: '',
    have_children: '',
    children_details: '',
    has_driving_license: '',
    has_car: '',
    dog_emergency_details: '',
    offered_services: [],
    size_of_dog: [],
    age_of_dog: [],
    comfortable_with_non_toilet_trained: '',
    comfortable_with_two_dogs: '',
    comfortable_with_separation_anxiety: '',
    comfortable_with_cat_or_other: '',
  })

  const { hear_from_source,
    other_hear_from_source,
    first_name,
    last_name,
    gender,
    date_of_birth,
    mobile,
    using_whatsapp_messenger,
    another_whatsapp_mobile,
    preferred_languages,
    street_name,
    city,
    country,
    postal_code,
    email,
    reason_of_joining,
    have_your_own_dog,
    sitter_dog_info,
    have_your_own_cat,
    has_previous_experience_for_own_dog,
    own_dog_experience_details,
    has_previous_experience_as_dog_sitter,
    as_sitter_experience_details,
    has_formal_training,
    formal_training_details,
    cared_for_other_than_dog,
    caring_details_for_other_than_dog,
    daily_schedule,
    daily_schedule_details,
    home_type,
    home_size,
    living_situation,
    floor,
    home_has_elevator,
    outdoor_spaces,
    nearby_places,
    family_details,
    have_children,
    children_details,
    has_driving_license,
    has_car,
    dog_emergency_details,
    offered_services,
    size_of_dog,
    age_of_dog,
    comfortable_with_non_toilet_trained,
    comfortable_with_two_dogs,
    comfortable_with_separation_anxiety,
    comfortable_with_cat_or_other, } = editUserData

  const modifyArray = (arr) => {
    let updatedArray = []
    for (let i in arr) {
      updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] })
    }
    return updatedArray
  }

  const formatArrayForCountry = (arr) => {
    let updatedArray = []
    for (let i in arr) {
      updatedArray.push({ key: arr, label: arr, value: arr })
    }
    return updatedArray
  }

  useEffect(() => {
    setEditUserData({
      ...editUserData,
      hear_from_source: dataArray.hear_from_source,
      other_hear_from_source: dataArray.other_hear_from_source,
      first_name: dataArray.first_name,
      last_name: dataArray.last_name,
      gender: dataArray.gender,
      date_of_birth: dataArray.date_of_birth,
      mobile: dataArray.mobile,
      using_whatsapp_messenger: dataArray.using_whatsapp_messenger,
      another_whatsapp_mobile: dataArray.another_whatsapp_mobile,
      preferred_languages: modifyArray(dataArray.preferred_languages),
      street_name: dataArray.street_name,
      city: dataArray.city,
      country: formatArrayForCountry(dataArray.country),
      postal_code: dataArray.postal_code,
      email: dataArray.email,
      reason_of_joining: dataArray.reason_of_joining,
      have_your_own_dog: dataArray.have_your_own_dog,
      sitter_dog_info: dataArray.sitter_dog_info,
      have_your_own_cat: dataArray.have_your_own_cat,
      has_previous_experience_for_own_dog: dataArray.has_previous_experience_for_own_dog,
      own_dog_experience_details: dataArray.own_dog_experience_details,
      has_previous_experience_as_dog_sitter: dataArray.has_previous_experience_as_dog_sitter,
      as_sitter_experience_details: dataArray.as_sitter_experience_details,
      has_formal_training: dataArray.has_formal_training,
      formal_training_details: dataArray.formal_training_details,
      cared_for_other_than_dog: dataArray.cared_for_other_than_dog,
      caring_details_for_other_than_dog: dataArray.caring_details_for_other_than_dog,
      daily_schedule: dataArray.daily_schedule,
      daily_schedule_details: dataArray.daily_schedule_details,
      home_type: dataArray.home_type,
      home_size: dataArray.home_size,
      living_situation: dataArray.living_situation,
      floor: dataArray.floor,
      home_has_elevator: dataArray.home_has_elevator,
      outdoor_spaces: modifyArray(dataArray.outdoor_spaces),
      nearby_places: modifyArray(dataArray.nearby_places),
      family_details: dataArray.family_details,
      have_children: dataArray.have_children,
      children_details: dataArray.children_details,
      has_driving_license: dataArray.has_driving_license,
      has_car: dataArray.has_car,
      dog_emergency_details: dataArray.dog_emergency_details,
      offered_services: modifyArray(dataArray.offered_services),
      size_of_dog: modifyArray(dataArray.size_of_dog),
      age_of_dog: modifyArray(dataArray.age_of_dog),
      comfortable_with_non_toilet_trained: dataArray.comfortable_with_non_toilet_trained,
      comfortable_with_two_dogs: dataArray.comfortable_with_two_dogs,
      comfortable_with_separation_anxiety: dataArray.comfortable_with_separation_anxiety,
      comfortable_with_cat_or_other: dataArray.comfortable_with_cat_or_other,
    })
  }, [dataArray])

  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value)
    return formatedData
  }

  const formatCountrySelectData = (data) => {
    const formatedData = data.label
    return formatedData
  }

  const updateSitterDetails = async() => {
    try {
      await updateSitterInforeq(
        {
          hear_from_source: hear_from_source,
          other_hear_from_source: other_hear_from_source,
          first_name: first_name,
          last_name: last_name,
          gender: gender,
          date_of_birth: date_of_birth,
          mobile: mobile,
          using_whatsapp_messenger: using_whatsapp_messenger,
          another_whatsapp_mobile: another_whatsapp_mobile,
          preferred_languages: formatMultiSelectData(preferred_languages),
          street_name: street_name,
          city: city,
          country: formatCountrySelectData(country),
          postal_code: postal_code,
          email: email,
          reason_of_joining: reason_of_joining,
          have_your_own_dog: have_your_own_dog,
          sitter_dog_info: sitter_dog_info,
          have_your_own_cat: have_your_own_cat,
          has_previous_experience_for_own_dog: has_previous_experience_for_own_dog,
          own_dog_experience_details: own_dog_experience_details,
          has_previous_experience_as_dog_sitter: has_previous_experience_as_dog_sitter,
          as_sitter_experience_details: as_sitter_experience_details,
          has_formal_training: has_formal_training,
          formal_training_details: formal_training_details,
          cared_for_other_than_dog: cared_for_other_than_dog,
          caring_details_for_other_than_dog: caring_details_for_other_than_dog,
          daily_schedule: daily_schedule,
          daily_schedule_details: daily_schedule_details,
          home_type: home_type,
          home_size: home_size,
          living_situation: living_situation,
          floor: floor,
          home_has_elevator: home_has_elevator,
          outdoor_spaces: formatMultiSelectData(outdoor_spaces),
          nearby_places: formatMultiSelectData(nearby_places),
          family_details: family_details,
          have_children: have_children,
          children_details: children_details,
          has_driving_license: has_driving_license,
          has_car: has_car,
          dog_emergency_details: dog_emergency_details,
          offered_services: formatMultiSelectData(offered_services),
          size_of_dog: formatMultiSelectData(size_of_dog),
          age_of_dog: formatMultiSelectData(age_of_dog),
          comfortable_with_non_toilet_trained: comfortable_with_non_toilet_trained,
          comfortable_with_two_dogs: comfortable_with_two_dogs,
          comfortable_with_separation_anxiety: comfortable_with_separation_anxiety,
          comfortable_with_cat_or_other: comfortable_with_cat_or_other,
        },
        sitterUserId
      )
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  const style = {
    borderRight: '1px solid #ced4da'
  }

  return (
    <Modal size='xl' isOpen={isOpen} toggle={handleClose} backdrop={true}>
      <ModalHeader>Edit sitter application</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label htmlFor='hear_from_source'>
                How did you hear about DogStays?
              </Label>
              <Input
                name='hear_from_source'
                placeholder='Enter Hear From Source'
                value={hear_from_source}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  hear_from_source: e.target.value
                })}
                type='select'
                errorMessage='hear_from_source is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='hear_from_source'
              >
                <option hidden>Select</option>
                <option value='Google Search'>
                  Google Search
                </option>
                <option value='Facebook group'>
                  Facebook group
                </option>
                <option value=' Through a friend'>
                  {' '}
                  Through a friend
                </option>
                <option value='Booked With DogStays before'>
                  Booked With DogStays before
                </option>
                <option value='Facebook Ad'>
                  Facebook Ad
                </option>
                <option value='Google Ad'>Google Ad</option>
                <option value='Other'>Other</option>
              </Input>
            </FormGroup>

            {hear_from_source === 'Other' && (
              <FormGroup>
                <Label htmlFor='other_hear_from_source'>
                  {' '}
                  Please tell us how you heard of DogStays
                </Label>
                <Input
                  name='other_hear_from_source'
                  placeholder='Please Explain'
                  value={other_hear_from_source}
                  onChange={(e) => setEditUserData({
                    ...editUserData,
                    other_hear_from_source: e.target.value
                  })}
                  type='textarea'
                  errorMessage='other_hear_from_source is required'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  id='other_hear_from_source'
                  required
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label htmlFor='first_name'>Name</Label>
              <Input
                name='first_name'
                placeholder='Enter Name'
                value={first_name}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  first_name: e.target.value
                })}
                type='text'
                errorMessage='first_name is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='first_name'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='last_name'>Family Name</Label>
              <Input
                name='last_name'
                placeholder='Enter Family Name'
                value={last_name}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  last_name: e.target.value
                })}
                type='text'
                errorMessage='last_name is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='last_name'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='gender'>Gender</Label>
              <Input
                name='gender'
                placeholder='Enter gender'
                value={gender}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  gender: e.target.value
                })}
                type='select'
                errorMessage='gender is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='gender'
              >
                <option hidden>Select Gender</option>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
                <option value='Other'>Other</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label htmlFor='date_of_birth'>
                Date of birth
              </Label>
              <Input
                name='date_of_birth'
                placeholder='Enter DOB'
                value={date_of_birth}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  date_of_birth: e.target.value
                })}
                max={new Date().toISOString().split('T')[0]}
                type='date'
                errorMessage='date_of_birth is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='date_of_birth'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='mobile'>Phone</Label>
              <PhoneInput
                country={'lu'}
                specialLabel={''}
                value={mobile}
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  mobile: value
                })}
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='preferred_languages'>
                Which languages do you speak?
              </Label>
              <Select
                className='select-font'
                options={languageOptions}
                value={preferred_languages}
                isMulti
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  preferred_languages: value
                })}
                name='preferred_languages'
                placeholder='Please select multiple languages'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='using_whatsapp_messenger'>
                Do you use WhatsApp Messenger?
              </Label>
              <Input
                name='using_whatsapp_messenger'
                placeholder='Enter '
                value={using_whatsapp_messenger}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  using_whatsapp_messenger: e.target.value
                })}
                type='select'
                errorMessage='using_whatsapp_messenger is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='using_whatsapp_messenger'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='another_whatsapp_mobile'>
                If the number you use on WhatsApp is
                different please share that with us here
              </Label>
              <PhoneInput
                country={'lu'}
                specialLabel={''}
                value={another_whatsapp_mobile}
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  another_whatsapp_mobile: value
                })}
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='email'>Email address</Label>
              <Input
                name='email'
                placeholder='Enter Email'
                value={email}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  email: e.target.value
                })}
                type='email'
                errorMessage='email is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='email'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='street_name'>
                Street Name and Number
              </Label>
              <Input
                name='street_name'
                placeholder='Enter Street Name'
                value={street_name}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  street_name: e.target.value
                })}
                type='text'
                errorMessage='street_name is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='street_name'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='city'>
                Town/Village/City
              </Label>
              <Input
                name='city'
                placeholder='Enter City'
                value={city}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  city: e.target.value
                })}
                type='text'
                errorMessage='city is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='city'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='country'>Country</Label>
              <Select
                className='select-font'
                options={countryOptions}
                value={country}
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  country: value
                })}
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='postal_code'>Post Code</Label>
              <Input
                name='postal_code'
                placeholder='Enter Postal Code'
                value={postal_code}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  postal_code: e.target.value
                })}
                type='text'
                errorMessage='postal_code is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='postal_code'
              />
            </FormGroup>

          </Col>
          <Col md='6'>
            <FormGroup>
              <Label htmlFor='reason_of_joining'>
                Why do you want to join our network?
              </Label>
              <Input
                name='reason_of_joining'
                placeholder='Enter Reason'
                value={reason_of_joining}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  reason_of_joining: e.target.value
                })}
                type='textarea'
                errorMessage='reason_of_joining is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='reason_of_joining'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='have_your_own_dog'>
                Do you have your own dog here in Luxembourg?
              </Label>
              <Input
                name='have_your_own_dog'
                placeholder='Enter '
                value={have_your_own_dog}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  have_your_own_dog: e.target.value
                })}
                type='select'
                errorMessage='have_your_own_dog is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='have_your_own_dog'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='sitter_dog_info'>
                If YES then please tell us about your dog
                (Name, girl or boy? Neutered or not? what
                breed? how old he/she is? Is your dog good
                with other dogs? )
              </Label>
              <Input
                name='sitter_dog_info'
                placeholder='Enter Sitter Dog info'
                value={sitter_dog_info}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  sitter_dog_info: e.target.value
                })}
                type='textarea'
                errorMessage='sitter_dog_info is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='sitter_dog_info'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='have_your_own_cat'>
                Do you have a cat at home here in
                Luxembourg?
              </Label>
              <Input
                name='have_your_own_cat'
                placeholder='Enter have_your_own_cat'
                value={have_your_own_cat}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  have_your_own_cat: e.target.value
                })}
                type='select'
                errorMessage='have_your_own_cat is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='have_your_own_cat'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label htmlFor='has_previous_experience_for_own_dog'>
                Do you have previous experience caring for
                your own dog(s)?
              </Label>
              <Input
                name='has_previous_experience_for_own_dog'
                placeholder='Enter has_previous_experience_for_own_dog'
                value={has_previous_experience_for_own_dog}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  has_previous_experience_for_own_dog: e.target.value
                })}
                type='select'
                errorMessage='has_previous_experience_for_own_dog is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='has_previous_experience_for_own_dog'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='own_dog_experience_details'>
                If YES, please tell us about this
                experience in detail
              </Label>
              <Input
                name='own_dog_experience_details'
                placeholder='Enter Experience Detail'
                value={own_dog_experience_details}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  own_dog_experience_details: e.target.value
                })}
                type='textarea'
                errorMessage='own_dog_experience_details is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='own_dog_experience_details'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='has_previous_experience_as_dog_sitter'>
                Do you have previous experience as a dog
                sitter for someone else?
              </Label>
              <Input
                name='has_previous_experience_as_dog_sitter'
                placeholder='Enter Previous Experience'
                value={
                  has_previous_experience_as_dog_sitter
                }
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  has_previous_experience_as_dog_sitter: e.target.value
                })}
                type='select'
                errorMessage='has_previous_experience_as_dog_sitter is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='has_previous_experience_as_dog_sitter'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label htmlFor='as_sitter_experience_details'>
                If YES, please tell us about this
                experience in detail
              </Label>
              <Input
                name='as_sitter_experience_details'
                placeholder='Enter Sitter Experience'
                value={as_sitter_experience_details}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  as_sitter_experience_details: e.target.value
                })}
                type='textarea'
                errorMessage='as_sitter_experience_details is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='as_sitter_experience_details'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='has_formal_training'>
                Do you have any formal training related to
                dogs or other animals?
              </Label>
              <Input
                name='has_formal_training'
                placeholder='Enter has_formal_training'
                value={has_formal_training}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  has_formal_training: e.target.value
                })}
                type='select'
                errorMessage='has_formal_training is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='has_formal_training'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label htmlFor='formal_training_details'>
                If YES, please tell us about this training
                in detail
              </Label>
              <Input
                name='formal_training_details'
                placeholder='Enter Formal Training'
                value={formal_training_details}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  formal_training_details: e.target.value
                })}
                type='textarea'
                errorMessage='formal_training_details is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='formal_training_details'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='cared_for_other_than_dog'>
                Have you cared for other types of animals
                (besides dogs)?
              </Label>
              <Input
                name='cared_for_other_than_dog'
                placeholder='Enter cared_for_other_than_dog'
                value={cared_for_other_than_dog}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  cared_for_other_than_dog: e.target.value
                })}
                type='select'
                errorMessage='cared_for_other_than_dog is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='cared_for_other_than_dog'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='caring_details_for_other_than_dog'>
                If YES, please tell us about this
                experience in detail
              </Label>
              <Input
                name='caring_details_for_other_than_dog'
                placeholder='Enter Caring Details'
                value={caring_details_for_other_than_dog}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  caring_details_for_other_than_dog: e.target.value
                })}
                type='textarea'
                errorMessage='caring_details_for_other_than_dog is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='caring_details_for_other_than_dog'
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className='text-center my-3'>
          <h3>Booking Details Data</h3>
        </Row>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label htmlFor='daily_schedule'>
                What is your work/daily schedule?
              </Label>
              <Input
                name='daily_schedule'
                placeholder='Enter '
                value={daily_schedule}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  daily_schedule: e.target.value
                })}
                type='select'
                errorMessage='daily_schedule is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='daily_schedule'
              >
                <option hidden>Select</option>
                <option value='My work is flexible'>
                  My work is flexible
                </option>
                <option value='I work from office'>
                  I work from office
                </option>
                <option value='I work from home'>
                  I work from home
                </option>
                <option value='I work only part time'>
                  I work only part time
                </option>
                <option value='I work full time in office'>
                  I work full time in office
                </option>
                <option value='I dont work at the moment'>
                  I don`t work at the moment
                </option>
                <option value='I am a student'>
                  I am a student
                </option>
                <option value='Other'>Other</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='daily_schedule_details'>
                Daily Schedule Details
              </Label>
              <Input
                name='daily_schedule_details'
                placeholder='Enter Details'
                value={daily_schedule_details}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  daily_schedule_details: e.target.value
                })}
                type='textarea'
                errorMessage='daily_schedule_details is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='daily_schedule_details'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='home_type'>
                Do you live in a House or Apartment?
              </Label>
              <Input
                name='home_type'
                placeholder='Enter '
                value={home_type}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  home_type: e.target.value
                })}
                type='select'
                errorMessage='Home Type is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='home_type'
              >
                <option hidden>Select</option>
                <option value='House'>House</option>
                <option value='Apartment'>Apartment</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='home_size'>
                Please tell us the approximate size of your
                home in m2
              </Label>
              <Input
                name='home_size'
                placeholder='Enter Home Size'
                value={home_size}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  home_size: e.target.value
                })}
                type='text'
                errorMessage='home_size is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='home_size'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='living_situation'>
                Please choose the one that matches your
                living situation:
              </Label>
              <Input
                name='living_situation'
                placeholder='Enter Living Situation'
                value={living_situation}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  living_situation: e.target.value
                })}
                type='select'
                errorMessage='living_situation is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='living_situation'
              >
                <option hidden>Select</option>
                <option value='You/Your family is the home owner'>
                  You/Your family is the home owner
                </option>
                <option value='You are a tenant'>
                  You are a tenant
                </option>
                <option value='You are sharing with multiple tenants'>
                  You are sharing with multiple tenants
                </option>
                <option value='Other'>Other</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='floor'>
                Which floor do you live on?
              </Label>
              <Input
                name='floor'
                placeholder='Enter '
                value={floor}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  floor: e.target.value
                })}
                type='select'
                errorMessage='floor is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='floor'
              >
                <option hidden>Select</option>
                <option value='Ground floor'>
                  Ground floor
                </option>
                <option value='First floor'>
                  First floor
                </option>
                <option value='Second floor'>
                  Second floor
                </option>
                <option value='Third floor'>
                  Third floor
                </option>
                <option value='Higher than third floor'>
                  Higher than third floor
                </option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='home_has_elevator'>
                Does your building have an elevator?
              </Label>
              <Input
                name='home_has_elevator'
                placeholder='Enter Details'
                value={home_has_elevator}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  home_has_elevator: e.target.value
                })}
                type='select'
                errorMessage='home_has_elevator is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='home_has_elevator'
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='not_relevant'>
                  Live on ground floor so not relevant
                </option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='outdoor_spaces'>
                What outdoor spaces do you have in your
                home? (choose all that apply)
              </Label>
              <Select
                className='select-font'
                options={outdoor_spaces_options}
                isMulti
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  outdoor_spaces: value
                })}
                value={outdoor_spaces}
                name='outdoor_spaces'
                placeholder='Select'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='nearby_places'>
                Which of these do you have near your home?
              </Label>
              <Select
                className='select-font'
                options={nearby_places_options}
                isMulti
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  nearby_places: value
                })}
                value={nearby_places}
                name='nearby_places'
                placeholder='Select'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='family_details'>
                Please tell who lives in your home with you.
                Who else would interact with the dogs you
                look after and how do they feel about dogs?
              </Label>
              <Input
                name='family_details'
                placeholder='Enter Details'
                value={family_details}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  family_details: e.target.value
                })}
                type='textarea'
                errorMessage='family_details is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='family_details'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='have_children'>
                Do you have children at home and would they
                be interacting with the dog as well?
              </Label>
              <Input
                name='have_children'
                placeholder='Enter Details'
                value={have_children}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  have_children: e.target.value
                })}
                type='select'
                errorMessage='have_children is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='have_children'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label htmlFor='children_details'>
                If YES, please tell us about the children
                (how old are they? are they comfortable
                around dogs? Do they have experience with
                dogs? Will they interact with the dogs
                only under supervision?)
              </Label>
              <Input
                name='children_details'
                placeholder='Enter Details'
                value={children_details}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  children_details: e.target.value
                })}
                type='textarea'
                errorMessage='children_details is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='children_details'
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='has_driving_license'>
                Do you have a driving license?
              </Label>
              <Input
                name='has_driving_license'
                placeholder='Enter '
                value={has_driving_license}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  has_driving_license: e.target.value
                })}
                type='select'
                errorMessage='has_driving_license is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='has_driving_license'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>
          </Col>

          <Col lg='6'>
            <FormGroup>
              <Label htmlFor='has_car'>
                Do you have access to your own or a family
                car?
              </Label>
              <Input
                name='has_car'
                placeholder='Enter Details'
                value={has_car}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  has_car: e.target.value
                })}
                type='select'
                errorMessage='has_car is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='has_car'
              >
                <option hidden>Select</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label htmlFor='dog_emergency_details'>
                In the case of an emergency situation would
                you be able to take a dog to an emergency
                vet? Please explain how you would manage the
                situation.
              </Label>
              <Input
                name='dog_emergency_details'
                placeholder='Enter Details'
                value={dog_emergency_details}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  dog_emergency_details: e.target.value
                })}
                type='textarea'
                errorMessage='dog_emergency_details is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='dog_emergency_details'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='offered_services'>
                What services would you like to offer with
                DogStays?
              </Label>
              <Select
                className='select-font'
                options={offered_services_options}
                value={offered_services}
                isMulti
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  offered_services: value
                })}
                name='offered_services'
                placeholder='Select'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='size_of_dog'>
                What all sizes of dogs are you comfortable
                caring for? (don`t worry, you will always meet
                the dog before accepting a booking!)
              </Label>
              <Select
                className='select-font'
                options={size_of_dog_options}
                value={size_of_dog}
                isMulti
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  size_of_dog: value
                })}
                name='size_of_dog'
                placeholder='Select'
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='age_of_dog'>
                Your are comfortable caring for dogs of what
                ages?
              </Label>
              <Select
                className='select-font'
                options={age_of_dog_options}
                isMulti
                value={age_of_dog}
                onChange={(value) => setEditUserData({
                  ...editUserData,
                  age_of_dog: value
                })}
                name='age_of_dog'
                placeholder='Select'
                maxMenuHeight={120}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='comfortable_with_non_toilet_trained'>
                Are you comfortable caring for young dogs who
                are still being toilet trained?
              </Label>
              <Input
                name='comfortable_with_non_toilet_trained'
                placeholder='Enter Detail'
                value={comfortable_with_non_toilet_trained}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  comfortable_with_non_toilet_trained: e.target.value
                })}
                type='select'
                errorMessage='comfortable_with_non_toilet_trained is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='comfortable_with_non_toilet_trained'
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='comfortable_with_two_dogs'>
                Are you comfortable caring for 2 dogs from the
                same house?
              </Label>
              <Input
                name='comfortable_with_two_dogs'
                placeholder='Enter Detail'
                value={comfortable_with_two_dogs}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  comfortable_with_two_dogs: e.target.value
                })}
                type='select'
                errorMessage='comfortable_with_two_dogs is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='comfortable_with_two_dogs'
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='comfortable_with_separation_anxiety'>
                Would you be open to caring for dogs with
                separation anxiety or other problems? (don`t
                worry, you will always meet the dog before
                deciding to accept a particular booking)
              </Label>
              <Input
                name='comfortable_with_separation_anxiety'
                placeholder='Enter Detail'
                value={comfortable_with_separation_anxiety}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  comfortable_with_separation_anxiety: e.target.value
                })}
                type='select'
                errorMessage='comfortable_with_separation_anxiety is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='comfortable_with_separation_anxiety'
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='comfortable_with_cat_or_other'>
                Are you comfortable caring for cats or other
                small animals?
              </Label>
              <Input
                name='comfortable_with_cat_or_other'
                placeholder='Enter Detail'
                value={comfortable_with_cat_or_other}
                onChange={(e) => setEditUserData({
                  ...editUserData,
                  comfortable_with_cat_or_other: e.target.value
                })}
                type='select'
                errorMessage='comfortable_with_cat_or_other is required'
                className='form-control'
                validate={{ required: { value: true } }}
                id='comfortable_with_cat_or_other'
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          onClick={updateSitterDetails}
          color='primary'
          className='waves-effect'
        >
          Update
        </Button>
        <Button
          type='button'
          onClick={handleClose}
          color='light'
          className='waves-effect'
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditSitterProfileData
