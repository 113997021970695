import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'  
import dayjs from 'dayjs'

const EditOvernightData = ({ data, updatedData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  const [overnightTimeDiff, setOvernightTimeDiff] = useState('')

  const [overnightData, setOvernightData] = useState({
    overnight_start_date_time: {
      date: '',
      time: ''
    },
    overnight_end_date_time: {
      date: '',
      time: ''
    },
    other_details: data.other_details
  })
  const { overnight_start_date_time, overnight_end_date_time, other_details } = overnightData

  const handleRenderDates = () => {
    if(!data?.overnight_start_date_time?.date){
      const startDate = data?.overnight_start_date_time && data?.overnight_start_date_time?.slice(0, 16).split('T')
      const endDate = data?.overnight_end_date_time && data?.overnight_end_date_time?.slice(0, 16).split('T')
      setOvernightData({ ...overnightData,
        overnight_start_date_time: { ...overnightData.overnight_start_date_time, date: startDate?.[0], time: startDate?.[1] },
        overnight_end_date_time: { ...overnightData.overnight_end_date_time, date: endDate?.[0], time: endDate?.[1] } })}
  }

  useEffect(() => {
    data && data?.overnight_start_date_time && handleRenderDates()
  },[data])


    useEffect(() => {
      if (!overnightData?.overnight_start_date_time || !overnightData?.overnight_end_date_time) {
          setOvernightTimeDiff("0 Days");
          return;
      }
  
      const { overnight_start_date_time, overnight_end_date_time } = overnightData;
  
      if (!overnight_start_date_time.date || !overnight_start_date_time.time ||
          !overnight_end_date_time.date || !overnight_end_date_time.time) {
          setOvernightTimeDiff("0 Days");
          return;
      }
  
      const dateA = new Date(`${overnight_start_date_time.date}T${overnight_start_date_time.time}`);
      const dateB = new Date(`${overnight_end_date_time.date}T${overnight_end_date_time.time}`);
  
      if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          setOvernightTimeDiff("0 Days");
          return;
      }
  
      const timeDifferenceInMilliseconds = dateB.getTime() - dateA.getTime();
      let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convert to hours
  
      let time = "0 Days";
  
      if (timeDifferenceInHours <= 24) {
          time = "1 Day";
      } else {
          let fullDays = Math.floor(timeDifferenceInHours / 24); // Count full 24-hour periods
          let remainingHours = timeDifferenceInHours % 24; // Get the leftover hours
  
          if (remainingHours > 1) { // Ignore the first hour after a full day
              if (remainingHours <= 6) {
                  time = `${fullDays + 0.5} Days`; // If remaining hours are 2-6, count as half a day
              } else {
                  time = `${fullDays + 1} Days`; // If more than 6 hours, count as a full day
              }
          } else {
              time = `${fullDays} Days`; // If only 1 hour remains after full days, ignore it
          }
      }
  
      setOvernightTimeDiff(time);
      updatedData(overnightData)

  }, [overnightData]);
  const getNextDay = (date) => {
    if (!date) return "";
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split("T")[0]; 
  };
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    const newEndDate = getNextDay(newStartDate); // Calculate next day automatically
    
    setOvernightData({
      ...overnightData,
      overnight_start_date_time: {
        ...overnightData.overnight_start_date_time, 
        date: newStartDate
      },
      overnight_end_date_time: {
        ...overnightData.overnight_end_date_time,
        date: newEndDate
      }
    });
  };
  return (
    <>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label>Start Date</Label>
            <Input
             type='date' 
            placeholder='start Date' 
              value={overnightData.overnight_start_date_time.date}
              min={new Date().toISOString().split('T')[0]}              onChange={handleStartDateChange}>  
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Start Time</Label>
            <br/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                sx={{ marginTop: '5px' }}
                ampm={false}
                defaultValue={dayjs()}
                value={dayjs(`2014-08-18T${overnightData.overnight_start_date_time.time}`)}
                onChange={(e) => setOvernightData({ ...overnightData, overnight_start_date_time:
                  { ...overnightData.overnight_start_date_time, time: e.format('HH:mm') } })}
              />
            </LocalizationProvider>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>End Date</Label>
            <Input
             type='date' 
             placeholder='start Date'
             min={getNextDay(overnightData.overnight_start_date_time.date)} 
              value={overnightData.overnight_end_date_time.date}
              onChange={(e) => setOvernightData({ ...overnightData, overnight_end_date_time:
               { ...overnightData.overnight_end_date_time, date: e.target.value } })}>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>End Time</Label>
            <br/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                sx={{ marginTop: '5px' }}
                ampm={false}
                defaultValue={dayjs()}
                value={dayjs(`2014-08-18T${overnightData.overnight_end_date_time.time}`)}
                onChange={(e) => setOvernightData({ ...overnightData, overnight_end_date_time: 
                  { ...overnightData.overnight_end_date_time, time: e.format('HH:mm') } })}
              />
            </LocalizationProvider>
          </FormGroup>
        </Col>
        <p className='font-bold text-end'>Total days: {overnightTimeDiff}</p>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Other Details</Label>
              <Input value={other_details} type="textarea" className="form-control"
                onChange={(e) => setOvernightData({ ...overnightData, other_details: e.target.value })} />
            </FormGroup>
          </Col>
        </Row>
      </Row>
    </>
  )
}

export default EditOvernightData